import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form', 'status', 'loading', 'saved', 'error', 'focusMe']
  static values = { 
    statusOnLoad: String
  }

  connect() {
    this.timeout = null
    this.duration = this.data.get('duration') || 1000

    if (this.hasStatusOnLoadValue && this.hasStatusTarget) {
      this.statusTarget.classList.remove("hidden");
      if (this.statusOnLoadValue == "success") {
        this.savedTarget.classList.remove("hidden");
        this.timeout = setTimeout(() => {
          this.savedTarget.classList.add("hidden");
        }, 2000)
      } else if (this.statusOnLoadValue == "error") {
        this.errorTarget.classList.remove("hidden");
      }
    }
  }

  submit(event) {
    if(this.timeout) {
      clearTimeout(this.timeout);
    }

    if (event.key !== "Tab") { // don't trigger if the event was a key press of the 'Tab' key

      // If multiple elements within the same form are to be autosaved, then each autosaving field must set 'id' on it's associated '.autosave-icon-container'
      var container = this.element.querySelector(".autosave-icon-container#"+event.target.dataset.autosaveid);
      if (!container) {
        // If an .autosave-icon-container isn't defined with an 'id', then find the first '.autosave-icon-container'
        var container = this.element.querySelector(".autosave-icon-container");
      }

      if (container) {
        var loading_icon = container.querySelector(".loading-icon");
        var saved_icon = container.querySelector(".saved-icon");
        var error_icon = container.querySelector(".error-icon");

        // Show this icon container and mark it as the one that's changing
        container.classList.remove("hidden");
        container.classList.add("changed");

        // Show the loading state
        loading_icon.classList.remove("hidden");
        saved_icon.classList.add("hidden");
        error_icon.classList.add("hidden");
      }
      this.timeout = setTimeout(() => {
        Rails.fire(this.formTarget, 'submit')
      }, this.duration)
    }
  }

  success() {
    container = this.element.querySelector(".autosave-icon-container.changed");
    if (!container) {
      // If an .autosave-icon-container.changed isn't found, then find the first '.autosave-icon-container'
      var container = this.element.querySelector(".autosave-icon-container");
    }
    
    if (container) {
      var loading_icon = container.querySelector(".loading-icon");
      var saved_icon = container.querySelector(".saved-icon");
      var error_icon = container.querySelector(".error-icon");
      container.classList.remove("changed");

      // Show the 'saved' checkmark icon
      loading_icon.classList.add("hidden");
      saved_icon.classList.remove("hidden");

      // Hide the checkmark icon after 2 seconds
      this.timeout = setTimeout(() => {
        saved_icon.classList.add("hidden");
      }, 2000)
    }
  }

  error(event) {
    console.log('error');
    console.log(event);
    if (this.hasStatusTarget && this.data.get('for') === 'contact') {
      console.log(this.element.querySelectorAll(".autosave-icon-container"))
      container = this.element.querySelector(".autosave-icon-container.changed");
      if (!container) {
        // If an .autosave-icon-container.changed isn't found, then find the first '.autosave-icon-container'
        var container = this.element.querySelector(".autosave-icon-container");
      }
      var loading_icon = container.querySelector(".loading-icon");
      var saved_icon = container.querySelector(".saved-icon");
      var error_icon = container.querySelector(".error-icon");

      container.classList.remove("changed");

      // Show the 'error' icon
      loading_icon.classList.add("hidden");
      saved_icon.classList.add("hidden");
      error_icon.classList.remove("hidden");
    } else if (this.hasStatusTarget) {
      this.loadingTarget.classList.add("hidden");
      this.errorTarget.classList.remove("hidden");
      this.savedTarget.classList.add("hidden");
    }
  }

  listenBackspace(event) {
    const key = event.key;
    if (key === "Backspace" && this.timeout) {
      clearTimeout(this.timeout);
    }
  }
}