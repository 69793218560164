import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["draftInput",
    "editRecordingInput",
    "messageForm",
  "confirmDraftOrInternal"]

  static values = {
    formType: String
  }

  saveDraft(event) {
    // If user clicked the "edit recording" button, this should save as a draft AND enter editing mode
    var editBtn = event.target.closest(".save-and-enter-edit-mode-btn");
    let targetBtn;

    if(editBtn)
      targetBtn = editBtn;
    else
      targetBtn = event.target.closest(".save-draft-btn");

    if(targetBtn.dataset.preventFormSubmit !== undefined) // avoid multiple form submission
      return

    // Set to true so that when the form is submitted, the message will be created as a draft
    if(this.hasDraftInputTarget)
      this.draftInputTarget.value = true

    if (editBtn) {
      this.editRecordingInputTarget.value = true
    }

    this.submitForm();
  }

  setConfirmAndSubmit(){
    this.confirmDraftOrInternalTarget.value = true
    this.submitForm();
  }

  saveEmbedAsDraft(event) {
    if(event.target.dataset.preventFormSubmit !== undefined) // avoid multiple form submission
      return

    // Set to true so that when the form is submitted, the message will be created as a draft
    if (this.hasDraftInputTarget) this.draftInputTarget.value = true

    this.submitForm();
  }

  submitForm(event) {
    var form = this.messageFormTarget;
    if (form) {
      form.removeAttribute('data-message--navigation-changed')
      form.setAttribute('data-message--navigation-changed', 'false')
      Rails.fire(form, 'submit')
    }

    // Hide the modal when the form is being submitted
    if(document.querySelector("#create-message-modal")){
      document.querySelector("#create-message-modal").classList.add("hidden")
    }
  }

}
