import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = [

    ]

    connect() {

    }

    reset_availability_form(event){
        var form = document.querySelector('.new-availability-block-form')
        var addTimeBlockButton = document.querySelector('.add-time-block-button')
        var availabilityEditBlock = document.querySelector('.availability-block-edit-block-' + event.target.dataset.availabilityBlockId)
        var availabilityBlock = document.querySelector('.availability-block-content-' + event.target.dataset.availabilityBlockId)
        if (availabilityEditBlock) availabilityEditBlock.innerHTML = ''
        if (availabilityBlock) availabilityBlock.classList.remove('hidden')
        form.innerHTML = ''
        addTimeBlockButton.classList.remove('hidden')
    }
}