import { Controller } from "stimulus";

export default class extends Controller {


    connect() {
    }

    updateTagIds(event) {
        var intakePageId = event.target.dataset.intakePageId;
        if (event.target.value) {
            Rails.ajax({
                type: "patch",
                url: '/setup_tags?id=' + intakePageId + '&tag_id=' + event.target.value
            })
        }
    }
}