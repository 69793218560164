import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['input', 'slackChannel', 'channelOption']

  toggle(event) {
    var slack_channel_div = event.target.closest(".slack-username");
    var channel = slack_channel_div.dataset.channel;

    this.slackChannelTarget.value = "";

    if(this.hasChannelOptionTarget) {
      for (let channelOption of this.channelOptionTargets) {
        channelOption.classList.remove("mention-enabled");
      }
    }

    slack_channel_div.classList.add("mention-enabled");
    this.slackChannelTarget.value = channel;

    this.element.querySelector(".slack-mentions-submit-button").classList.remove("hidden");

  }

}