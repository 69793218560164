import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = [
    "priceInput",
    "pricesList",
    "newPriceButton",
    "noPrices",
    "newPriceForm",
  ];

  static values = {}

  connect() {
  }

  formatPrice() {
    // Clear any previous timeout to prevent immediate execution
    if (this.formatTimeout) {
      clearTimeout(this.formatTimeout);
    }

    // Set a new timeout to execute the formatting after a delay (e.g., 1000 milliseconds)
    this.formatTimeout = setTimeout(() => {
      var inputField = this.priceInputTarget;
      var inputValue = inputField.value;

      // Check if the input value is empty or contains only a decimal point
      if (inputValue === "" || inputValue === ".") {
        // Handle empty or invalid input as needed (e.g., reset the input)
        return;
      }

      // Remove non-numeric and non-decimal characters
      var numericValue = inputValue.replace(/[^0-9.]/g, "");

      // Split the value into dollars and cents parts
      var parts = numericValue.split(".");
      var centpart1 = parts[0] || "0";
      var centpart2 = parts[1] || "00";

      // Limit the number of decimal places to 2
      if (parts.length > 1) {
        centpart2 = parts[1].substring(0, 2); // Only keep the first 2 decimal places
      }

      // Add a trailing "0" if there's only one decimal place
      if (centpart2.length === 1) {
        centpart2 = centpart2 + "0";
      } else if (centpart2 === "") {
        centpart2 = "00"; // Ensure there are always 2 digits for cents
      }

      // Join dollars and cents
      var formattedValue = centpart2 === "00" ? centpart1 : `${centpart1}.${centpart2}`;

      // Update the input field with the formatted value
      inputField.value = formattedValue;
    }, 1500); // Adjust the delay as needed
  }

  cancelNewPrice() {
    this.newPriceFormTarget.remove();

    if (this.hasPriceListTarget) {
      this.newPriceButtonTarget.classList.remove("hidden");
    } else {
      this.noPricesTarget.classList.remove("hidden");
    }
  }

  cancelEditPrice() {
    this.element.querySelector("form#edit-price").remove();
  }

  resetTrial(){
    var toggle = document.querySelector('#subscription-trial-toggle')
    var toggleValue = toggle.dataset.toggleSwitchActiveValue
    var trialField = document.querySelector('#product_price_trial_days')
    if (toggleValue == 'false'){
      trialField.value = null
    } else if (toggleValue == 'true'){
      trialField.value = 7
    }
  }

  resetSubscriptionEndInterval(){
    var toggle = document.querySelector('#subscription-end-toggle')
    var toggleValue = toggle.dataset.toggleSwitchActiveValue
    var intervalCountField = document.querySelector('#product_price_subscription_end_interval_count')
    var intervalField = document.querySelector('#product_price_subscription_end_interval')
    if (toggleValue == 'false'){
      intervalCountField.value = null
      intervalField.value = null
      intervalField.required = false
    }else if (toggleValue == 'true'){
      intervalCountField.value = 1
      intervalField.value = 'day'
      intervalField.required = true
    }
  }

  resetRecurringOptions(){
    var toggle = document.querySelector('#recurring-toggle')
    var toggleValue = toggle.dataset.toggleSwitchActiveValue
    var intervalCountField = document.querySelector('#product_price_recurring_interval_count')
    var intervalField = document.querySelector('#product_price_recurring_interval')
    if (toggleValue == 'false'){
      intervalCountField.value = null
      intervalField.value = null
      intervalField.required = false
    }else if (toggleValue == 'true'){
      intervalCountField.value = 1
      intervalField.value = 'month'
      intervalField.required = true
    }
  }
}