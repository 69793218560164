// Used when viewing a message or message template that has already been posted.

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["transcript", "toggleTranscript", "oembedContainer", "messageThreadContainer"];

  connect() {
    for (let oembed_container of this.oembedContainerTargets) {
      var message_template_id = oembed_container.dataset.messageTemplateId;
      if (message_template_id) {
        // we're viewing a message_template
        var account_id = oembed_container.dataset.accountId;
        var fetch_url = `/message_templates/${message_template_id}/get_iframe?account_id=${account_id}`;
      } else {
        // we're viewing a message
        var fetch_url = `/messages/${oembed_container.dataset.messageId}/get_iframe`;
      }

      fetch(fetch_url).then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            this.oembedContainerTarget.innerHTML = data.data
          }
          else if (data.status === 'error') {
            console.log(data.data)
            this.oembedContainerTarget.innerHTML = '<div class="flex flex-col items-center justify-center h-56\n' + 'md:h-30rem"><span class=\'text-gray-400 font-medium\'>Embedded media failed to load</span></div>'
          }
        })
    }
  }

  toggleTranscript(event) {
    if (event.target.closest(".message")) {
      var message = event.target.closest(".message");
    } else if (event.target.closest(".message-template")) {
      var message = event.target.closest(".message-template");
    } else if (event.target.closest(".message-template-container")) {
      var message = event.target.closest(".message-template-container");
    }

    var transcript_block = message.querySelector(".transcript-block");
    if (transcript_block.classList.contains("hidden")) {
      transcript_block.classList.remove("hidden");
      transcript_block.scrollIntoView({ behavior: "smooth", block: "start" });
      if (message.classList.contains("top-message") || message.classList.contains("top-message-template")) {
        var message_thread_container = message.closest(".message-thread-container");
        message_thread_container.classList.add("showing-transcript");
      } else {
        message.classList.add("showing-transcript");
      }
    } else {
      transcript_block.classList.add("hidden");
      if (message) {
        message.scrollIntoView({ behavior: "smooth", block: "start" });
      }
      if (message.classList.contains("top-message")) {
        this.messageThreadContainerTarget.classList.remove("showing-transcript");
      } else {
        message.classList.remove("showing-transcript");
      }
    }
  }

}
