import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['truncateToggle',
                    'truncatedMenu',
                    'truncatedMenuContent',
                    'truncatedLink'
                    ];

  static values = {
    truncateAtSize: String,
    truncate: Boolean
  }

  connect() {
    if (this.hasTruncatedMenuTarget) {
      // Elements
      var truncateMenu = this.truncatedMenuTarget;
      var truncateMenuContent = this.truncatedMenuContentTarget;
      var truncateMenuLinks = this.truncatedLinkTargets;
      var truncateToggle = this.truncateToggleTarget;

      // Classes as arrays
      var truncatedMenuClasses = this.element.dataset.truncatedMenuClasses.split(' ');
      var nonTruncatedMenuClasses = this.element.dataset.nonTruncatedMenuClasses.split(' '); 
      var truncatedMenuContentClasses = this.element.dataset.truncatedMenuContentClasses.split(' ');
      var nonTruncatedMenuContentClasses = this.element.dataset.nonTruncatedMenuContentClasses.split(' ');
      var truncatedLinkClasses = this.element.dataset.truncatedLinkClasses.split(' ');
      var nonTruncatedLinkClasses = this.element.dataset.nonTruncatedLinkClasses.split(' ');

      // Set the menu to truncated state by default
      truncateMenu.classList.add(...truncatedMenuClasses);
      truncateMenuContent.classList.add(...truncatedMenuContentClasses);
      
      for (let link of truncateMenuLinks) {
        link.classList.add(...truncatedLinkClasses);
      }

      var truncateAtWidth = this.truncateAtSizeValue == 'xl' ? 1280 : 1536;

      if (window.innerWidth > truncateAtWidth) {
        // Change menu to not truncated state
        this.truncateValue = false;
        truncateToggle.classList.add("hidden");
        truncateToggle.classList.remove("flex");
        truncateMenu.classList.remove(...truncatedMenuClasses);
        truncateMenu.classList.add(...nonTruncatedMenuClasses);
        truncateMenuContent.classList.remove(...truncatedMenuContentClasses);
        truncateMenuContent.classList.add(...nonTruncatedMenuContentClasses);
        for (let link of truncateMenuLinks ) {
          link.classList.remove(...truncatedLinkClasses);
          link.classList.add(...nonTruncatedLinkClasses);
        }
      } else {
        // Change menu to truncated state
        this.truncateValue = true;
        truncateToggle.classList.add("flex");
        truncateToggle.classList.remove("hidden");
        truncateMenu.classList.add(...truncatedMenuClasses);
        truncateMenu.classList.remove(...nonTruncatedMenuClasses);
        truncateMenuContent.classList.add(...truncatedMenuContentClasses);
        truncateMenuContent.classList.remove(...nonTruncatedMenuContentClasses);
        for (let link of truncateMenuLinks ) {
          link.classList.add(...truncatedLinkClasses);
          link.classList.remove(...nonTruncatedLinkClasses);
        }
      }
    }
  }

  open(event) {
    this.truncatedMenuTarget.classList.remove('hidden');
  }

  close(event) {
    if (this.truncateValue == true) {
      this.truncatedMenuTarget.classList.add('hidden');
    }
  }

}