import { Controller } from 'stimulus'
import { getCookieValue } from "../../helpers";

export default class extends Controller {

  static targets = ['messageForm', 'messageFormToggle', 'intakePageFormContainer', 'messageTemplateNameField']

  static values = { 
    hideForm: Boolean
  }

  connect() {
    if (this.hideFormValue == true) {
      this.intakePageFormContainerTarget.classList.add("hidden");
    }
  }

  open_top_level_message_form() {
    // This is used for opening message form to create a top-level message.
    // Opening form to create a threaded message is located in message/threads_controller.js#newReplyInThread

    if (this.hasIntakePageFormContainerTarget) { // This is an intake page
      var message_form = this.intakePageFormContainerTarget;
    } else { // this is a conversation page
      var message_form = this.messageFormTarget.closest('#create-message');
    }
    
    var message_form_toggles = this.messageFormToggleTargets;

    if(!(getCookieValue('X-App-Identifier'))) {
      message_form.classList.remove("hidden");
      message_form.scrollIntoView({behavior: "smooth", block: "start"});
      for (const message_form_toggle of message_form_toggles) {
        message_form_toggle.classList.add("hidden");
      }
    }

    // If we're creating a message_template... 
    if (this.hasMessageTemplateNameFieldTarget) {
      // focus the message template title field
      this.messageTemplateNameFieldTarget.focus();

      // Hide the .empty-thread-template if present
      var empty_thread_template = document.querySelector(".empty-thread-template");
      if (empty_thread_template) {
        empty_thread_template.classList.add("hidden");
      }
    }
  }

  close_message_form(event) {
    // This is used for closing the message form
    // This is used on both top-level message forms and threaded message forms

    var message_form = event.target.closest(".create-message-form");
    var message_form_toggles = this.messageFormToggleTargets;

    message_form.classList.add("hidden");

    // Show all the "reply" buttons
    for (const message_form_toggle of message_form_toggles) {
      message_form_toggle.classList.remove("hidden");
    }
  }

}