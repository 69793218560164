// Manages the edit message UI

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "clipsList",
    "editClipsTimeline",
    "clipListStorage",
    "mediaDuration"
  ];

  connect() {

    // if user was logged-in and created this message via an intake page and entered edit mode, load the edit ui for this newly created draft message on page load
    var url_string = window.location.href; 
    var url = new URL(url_string);
    var message_id_to_edit = url.searchParams.get("edit_message_id");
    if (message_id_to_edit) {
      var message = document.querySelector("#message-"+message_id_to_edit);
      if (message) {
        message.querySelector(".enter-edit-mode-btn").click();
      }
    }

  }

  isClipIntersected(editCut1, editCut2) {
    let arr = this.clipListStorageTarget.dataset.storage.split(',')
    let i;
    var currentDate = new Date().toISOString().slice(0, 10);

    for (i = 0; i < arr.length; i += 2) {

      var openTime = Date.parse(this.convertDateTimeFormat(arr[i], currentDate));
      var closeTime = Date.parse(this.convertDateTimeFormat(arr[i + 1], currentDate));
      var checkVal1 = Date.parse(this.convertDateTimeFormat(editCut1, currentDate));
      var checkVal2 = Date.parse(this.convertDateTimeFormat(editCut2, currentDate));
      if ((checkVal1 >= openTime && checkVal1 <= closeTime) || (checkVal2 >= openTime && checkVal2 <= closeTime) || (checkVal1 <= openTime && closeTime <= checkVal2)) {
        return true
      }
    }
    return false
  }

  convertDateTimeFormat(time, currentDate){
    return time.split(':').length === 2 ? currentDate + 'T00:' + time.padStart(5, '0') : currentDate + 'T' + time.padStart(8, '0')
  }

  addClipSection() {
    this.populatingInputCut();
  }

  // invoked when discard edits button clicked
  discardEditForm() {
    this.element.querySelector(".edit-recording-container").innerHTML = '';

    if (this.element.querySelector("a[data-message--creation-target='editRecordBtn']") !== null) {
      this.element.querySelector("a[data-message--creation-target='editRecordBtn']").classList.remove("hidden");
    } else if (this.element.querySelector("button[data-message--creation-target='editRecordBtn']") !== null) {
      this.element.querySelector("button[data-message--creation-target='editRecordBtn']").classList.remove("hidden");
    }

    if (this.element.querySelector("a.schedule-message-btn") !== null) {
      this.element.querySelector("a.schedule-message-btn").classList.remove("hidden");
    }

    if (!this.element.classList.contains(".threaded-message")) {
      var message_thread_container = this.element.closest(".message-thread-container");
      if(message_thread_container) {
        var dummy_media_editor = message_thread_container.querySelector(".dummy-media-editor");
        message_thread_container.classList.remove("editing-media");
        if (dummy_media_editor) {
          dummy_media_editor.classList.add("hidden");
        }
      }
    }

    this.element.classList.remove("editing-media");
  }

  // modify edits form submit text and its invoked when automatically publish message checkbox state is changed
  modifySubmitText(event) {
    var link_target = this.element.querySelector(".initiate-edits-btn");
    if (event.target.value === 'true') {
      link_target.innerHTML = "Process edits, then publish message"
      link_target.setAttribute('data-old-delete-text', 'Process edits, then publish message...')
    } else {
      link_target.innerHTML = "Process edits..."
      link_target.setAttribute('data-old-delete-text', "Process edits...")
    }
  }

  // invoked when checkbox in edit processing UI is clicked and will update the clip_specs publish key value
  updateClipSpec(event) {
    setTimeout(() => {
      var messageId = this.element.id.split('-')[1];
      fetch(`/${messageId}/update_clip_spec?publish=${(event.target.closest('.publish').classList.contains('active'))}`, {
        method: "PATCH",
        headers: {
          'Content-Type': 'application/js'
        }
      }).then((result) => {
        result.text().then( (val) => eval(val) );
      })
    }, 100);
  }

  // invoked when x-cross button is clicked
  removeClip(event) {
    if (this.clipsListTarget.querySelectorAll("input[name='clip_parts[]']").length === 1) {
      this.discardEditForm();
    } else {
      let elementId = event.target.closest('.clip-container').id
      this.clipsListTarget.querySelector("#" + elementId).remove();
      this.editClipsTimelineTarget.querySelector("#" + elementId).remove();

      if (this.hasClipsListTarget) {
        let mediaDurationInSecond = timeToSecond(this.mediaDurationTarget.value);

        let sectionBtn = this.element.querySelector('.add-clip-section-btn');
        let cutExist = this.checkCutFieldAndCreateDefaults(mediaDurationInSecond);

        if (cutExist[0] === undefined || cutExist[1] === undefined)
          sectionBtn.classList.add("hidden");
        else
          sectionBtn.classList.remove("hidden");
      }
    }
  }

  insertDataInStore(){
    let totalInSecond = 0;
    let tempArr = [];
    this.clipsListTarget.querySelectorAll("input.clip-parts-input").forEach(i => {
      let a = i.value.split(' - ')[0].split('.')[0]
      let b = i.value.split(' - ')[1].split('.')[0]
      a = a.split(':').length === 2 ? ('00:' + a) : a
      b = b.split(':').length === 2 ? ('00:' + b) : b
      tempArr.push(a, b)
      tempArr.sort();
      totalInSecond += (timeToSecond(b) - timeToSecond(a) + 1);
      this.clipListStorageTarget.dataset.storage = tempArr;
    })

    return totalInSecond
  }

  checkCutFieldAndCreateDefaults(mediaDurationInSecond){
    let totalInSecond = this.insertDataInStore();
    let startCutInput, endCutInput, arr1=[], clipDuration = '00:00:05';
    const clipToStart = '00:00:00';
    const clipSpacing = '00:00:02';
    let arr = this.clipListStorageTarget.dataset.storage.split(',')

    calculateDifferenceBtwClips(arr, arr1, this.mediaDurationTarget.value);

    if (mediaDurationInSecond > 60 && arr1.length !== 0 && arr1.some(el => el > 60)) {
      clipDuration = '00:01:00'
    }

    /*
     - If no existing clip covers first 5 secs of the timeline, then insert this new clip at 0:00-0:05.
     - If a clip already covers 0:00, and no clip covers the final 5 secs of the timeline, then insert this new clip at the very end of the timeline, starting from 5 secs before the end.
     - If there are clips in the middle of the timeline, start the new clip in the first 5-second section that's available.
    */
    if (this.isClipIntersected(clipToStart, clipDuration) === false) {
      startCutInput = clipToStart;
      endCutInput = clipDuration;
    } else if (this.isClipIntersected(estimateTime([this.mediaDurationTarget.value, clipDuration], "subtraction"), this.mediaDurationTarget.value) === false) {
      startCutInput = estimateTime([this.mediaDurationTarget.value, clipDuration], "subtraction");
      endCutInput = this.mediaDurationTarget.value;
    } else {
      var index = arr1.indexOf(Math.max.apply(null, arr1))
      var startCut = estimateTime([arr[2 * index + 1], clipSpacing], "addition")

      if ((estimateTime([startCut, clipDuration], "addition") < arr[2 * index + 2]) && this.isClipIntersected(startCut, estimateTime([startCut, clipDuration], "addition")) === false) {
        startCutInput = startCut
        endCutInput = estimateTime([startCut, clipDuration], "addition")
      }
    }

    return [startCutInput, endCutInput]
  }

  populatingInputCut() {
    var arr= [], arr1 = [];
    let mediaDurationInSecond = timeToSecond(this.mediaDurationTarget.value);

    var cutFields = this.checkCutFieldAndCreateDefaults(mediaDurationInSecond);
    var startCutInput = cutFields[0];
    var endCutInput = cutFields[1];

    if(startCutInput !== undefined || endCutInput !== undefined) {
      let startCutInputValue, endCutInputValue
      var elementToClone = this.clipsListTarget.querySelector(".clip-container")
      var clonedNode = elementToClone.cloneNode(true);
      var noOfClips = parseInt(elementToClone.parentNode.lastElementChild.id.split("clip-part-")[1]) + 1
      clonedNode.setAttribute("id", `clip-part-${noOfClips}`);

      if (mediaDurationInSecond < 3600) {
        startCutInputValue = startCutInput.split(/:(.*)/s)[1];
        endCutInputValue = endCutInput.split(/:(.*)/s)[1];
      } else {
        startCutInputValue = startCutInput
        endCutInputValue = endCutInput
      }

      var input = clonedNode.querySelector(".clip-parts-input");
      input.value = `${startCutInputValue}.00 - ${endCutInputValue}.00`
      elementToClone.parentNode.appendChild(clonedNode);

      elementToClone = this.editClipsTimelineTarget.querySelector(".clip-container");
      clonedNode = elementToClone.cloneNode(true);

      var videoDuration = timeToSecond(this.mediaDurationTarget.value)
      var endCutInseconds = timeToSecond(endCutInput)
      var startCutInSeconds = timeToSecond(startCutInput)
      let totalTimelineWidth = document.querySelector(".edit-clips-timeline").clientWidth;

      var leftSideSpacing = Math.ceil((totalTimelineWidth * ((startCutInSeconds * 100) / videoDuration)) / 100);
      var widthSpacing = Math.ceil((totalTimelineWidth * ((endCutInseconds * 100) / videoDuration)) / 100) - leftSideSpacing;

      clonedNode.setAttribute("style", `width: ${widthSpacing}px; left: ${leftSideSpacing}px`);
      clonedNode.setAttribute("id", `clip-part-${noOfClips}`);
      clonedNode.querySelector('.clip-edge-left').title = startCutInputValue;
      clonedNode.querySelector('.clip-edge-right').title = endCutInputValue;
      elementToClone.parentNode.appendChild(clonedNode);
    }

    arr1 = [] // empty comparison data;
    this.insertDataInStore();
    arr = this.clipListStorageTarget.dataset.storage.split(',')

    arr1 = calculateDifferenceBtwClips(arr, arr1, this.mediaDurationTarget.value)

    // Hide the "Add another section" button when there is no clips with at least 7s spacing between each other.
    if (arr1.length !== 0 && arr1.every(el => el <= 7))
      this.element.querySelector('.add-clip-section-btn').classList.add('hidden');
  }
}

function calculateDifferenceBtwClips(arr, arr1, mediaDuration){
  for (let i = 1; i < arr.length - 1; i += 2) {
    arr1.push(estimateTime([arr[i + 1], arr[i]], "subtraction").split(':').reduce((acc, time) => (60 * acc) + +time))
  }

  if(arr[0] > '00:00:00')
    arr1.push(estimateTime([arr[0], '00:00:00'], "subtraction").split(':').reduce((acc, time) => (60 * acc) + +time))

  if(arr[arr.length - 1] < mediaDuration)
    arr1.push(estimateTime([mediaDuration, arr[arr.length - 1]], "subtraction").split(':').reduce((acc, time) => (60 * acc) + +time))

  return arr1;
}

function estimateTime(times, action) {
  let totalHours = 0
  let totalMinutes = 0
  let totalSeconds = 0
  let i = 0

  for (const time of times) {
    const splitTime = (time || '').split(':')
    if (action === 'addition') {
      totalHours += isNaN(parseInt(splitTime[0])) ? 0 : parseInt(splitTime[0])
      totalMinutes += isNaN(parseInt(splitTime[1])) ? 0 : parseInt(splitTime[1])
      totalSeconds += isNaN(parseInt(splitTime[2])) ? 0 : parseInt(splitTime[2])
    } else if (action === 'subtraction'){
      if(i === 0) {
        totalHours = isNaN(parseInt(splitTime[0])) ? 0 : parseInt(splitTime[0])
        totalMinutes = isNaN(parseInt(splitTime[1])) ? 0 : parseInt(splitTime[1])
        totalSeconds = isNaN(parseInt(splitTime[2])) ? 0 : parseInt(splitTime[2])
        i += 1
      }else{
        totalHours -= isNaN(parseInt(splitTime[0])) ? 0 : parseInt(splitTime[0])
        totalMinutes -= isNaN(parseInt(splitTime[1])) ? 0 : parseInt(splitTime[1])
        totalSeconds -= isNaN(parseInt(splitTime[2])) ? 0 : parseInt(splitTime[2])
      }
    }
  }

  if (totalSeconds >= 60) {
    const minutesFromSeconds = (totalSeconds / 60) << 0
    totalMinutes += minutesFromSeconds
    totalSeconds -= 60 * minutesFromSeconds
  }

  if (totalMinutes >= 60) {
    const hoursFromMinutes = (totalMinutes / 60) << 0
    totalHours += hoursFromMinutes
    totalMinutes -= 60 * hoursFromMinutes
  }

  const totalTimeInSeconds = totalSeconds + totalMinutes * 60 + totalHours * 60 * 60;
  return new Date(totalTimeInSeconds * 1000).toISOString().substr(11, 8)
  const timeToString = time => time.toString().padStart(2, '0')
  return `${timeToString(totalHours)}:${timeToString(totalMinutes)}:${timeToString(totalSeconds)}`
}

function timeToSecond(hms){
  var a = hms.split(':'); // split it at the colons
  return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
}