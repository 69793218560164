const Emojis = {
  "emojis": [
  {
    "emoji": "👍",
    "description": "thumbs up",
    "aliases": ["+1", "thumbsup"],
    "tags": ["approve", "ok"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "👎",
    "description": "thumbs down",
    "aliases": ["-1", "thumbsdown"],
    "tags": ["disapprove", "bury"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "👏",
    "description": "clapping hands",
    "aliases": ["clap"],
    "tags": ["praise", "applause"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "👋",
    "description": "waving hand",
    "aliases": ["wave"],
    "tags": ["goodbye"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🙌",
    "description": "raising hands",
    "aliases": ["raised_hands"],
    "tags": ["hooray"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🙏",
    "description": "folded hands",
    "aliases": ["pray"],
    "tags": ["please", "hope", "wish"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "☝️",
    "description": "index pointing up",
    "aliases": ["point_up"],
    "tags": [],
    "unicode_version": "",
    "ios_version": "6.0"
  },
  {
    "emoji": "🤝",
    "description": "handshake",
    "aliases": ["handshake"],
    "tags": ["deal"],
    "unicode_version": "9.0",
    "ios_version": "10.2"
  },
  {
    "emoji": "✌️",
    "description": "victory hand",
    "aliases": ["v"],
    "tags": ["victory", "peace"],
    "unicode_version": "",
    "ios_version": "6.0"
  },
  {
    "emoji": "👌",
    "description": "OK hand",
    "aliases": ["ok_hand"],
    "tags": [],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🤘",
    "description": "sign of the horns",
    "aliases": ["metal"],
    "tags": [],
    "unicode_version": "8.0",
    "ios_version": "9.1"
  },
  {
    "emoji": "🤙",
    "description": "call me hand",
    "aliases": ["call_me_hand"],
    "tags": [],
    "unicode_version": "9.0",
    "ios_version": "10.2"
  },
  {
    "emoji": "💪",
    "description": "flexed biceps",
    "aliases": ["muscle"],
    "tags": ["flex", "bicep", "strong", "workout"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🖖",
    "description": "vulcan salute",
    "aliases": ["vulcan_salute"],
    "tags": ["prosper", "spock"],
    "unicode_version": "7.0",
    "ios_version": "8.3"
  },
  {
    "emoji": "🤏",
    "description": "pinching hand",
    "aliases": ["pinching_hand"],
    "tags": [],
    "unicode_version": "12.0",
    "ios_version": "13.0"
  },
  {
    "emoji": "🤛",
    "description": "left-facing fist",
    "aliases": ["fist_left"],
    "tags": [],
    "unicode_version": "9.0",
    "ios_version": "10.2"
  },
  {
    "emoji": "🫶",
    "description": "heart hands",
    "aliases": ["heart_hands"],
    "tags": [],
    "unicode_version": "14.0",
    "ios_version": "14.0"
  },
  {
    "emoji": "👀",
    "description": "eyes",
    "aliases": ["eyes"],
    "tags": ["look", "see"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🙂",
    "description": "slightly smiling face",
    "aliases": ["slightly_smiling_face"],
    "tags": [],
    "unicode_version": "7.0",
    "ios_version": "9.1"
  },
  {
    "emoji": "😊",
    "description": "smiling face with smiling eyes",
    "aliases": ["blush"],
    "tags": ["proud"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "😃",
    "description": "grinning face with big eyes",
    "aliases": ["smiley"],
    "tags": ["happy", "joy", "haha"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🤣",
    "description": "rolling on the floor laughing",
    "aliases": ["rofl"],
    "tags": ["lol", "laughing"],
    "unicode_version": "9.0",
    "ios_version": "10.2"
  },
  {
    "emoji": "😜",
    "description": "winking face with tongue",
    "aliases": ["stuck_out_tongue_winking_eye"],
    "tags": ["prank", "silly"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🙃",
    "description": "upside-down face",
    "aliases": ["upside_down_face"],
    "tags": [],
    "unicode_version": "8.0",
    "ios_version": "9.1"
  },
  {
    "emoji": "🤔",
    "description": "thinking face",
    "aliases": ["thinking"],
    "tags": [],
    "unicode_version": "8.0",
    "ios_version": "9.1"
  },
  {
    "emoji": "😇",
    "description": "smiling face with halo",
    "aliases": ["innocent"],
    "tags": ["angel"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🤯",
    "description": "exploding head",
    "aliases": ["exploding_head"],
    "tags": ["mind", "blown"],
    "unicode_version": "11.0",
    "ios_version": "12.1"
  },
  {
    "emoji": "😬",
    "description": "grimacing face",
    "aliases": ["grimacing"],
    "tags": [],
    "unicode_version": "6.1",
    "ios_version": "6.0"
  },
  {
    "emoji": "😐",
    "description": "neutral face",
    "aliases": ["neutral_face"],
    "tags": ["meh"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "😢",
    "description": "crying face",
    "aliases": ["cry"],
    "tags": ["sad", "tear"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "☹️",
    "description": "frowning face",
    "aliases": ["frowning_face"],
    "tags": ["sad", "upset"],
    "unicode_version": "7.0",
    "ios_version": "9.1"
  },
  {
    "emoji": "😞",
    "description": "disappointed face",
    "aliases": ["disappointed"],
    "tags": ["sad"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "😡",
    "description": "pouting face",
    "aliases": ["rage", "pout"],
    "tags": ["angry"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "😳",
    "description": "flushed face",
    "aliases": ["flushed"],
    "tags": [],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "😲",
    "description": "astonished face",
    "aliases": ["astonished"],
    "tags": ["amazed", "gasp"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "😉",
    "description": "winking face",
    "aliases": ["wink"],
    "tags": ["flirt"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "😍",
    "description": "smiling face with heart-eyes",
    "aliases": ["heart_eyes"],
    "tags": ["love", "crush"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🤩",
    "description": "star-struck",
    "aliases": ["star_struck"],
    "tags": ["eyes"],
    "unicode_version": "11.0",
    "ios_version": "12.1"
  },
  {
    "emoji": "🤑",
    "description": "money-mouth face",
    "aliases": ["money_mouth_face"],
    "tags": ["rich"],
    "unicode_version": "8.0",
    "ios_version": "9.1"
  },
  {
    "emoji": "🤓",
    "description": "nerd face",
    "aliases": ["nerd_face"],
    "tags": ["geek", "glasses"],
    "unicode_version": "8.0",
    "ios_version": "9.1"
  },
  {
    "emoji": "😎",
    "description": "smiling face with sunglasses",
    "aliases": ["sunglasses"],
    "tags": ["cool"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🥳",
    "description": "partying face",
    "aliases": ["partying_face"],
    "tags": ["celebration", "birthday"],
    "unicode_version": "11.0",
    "ios_version": "12.1"
  },
  {
    "emoji": "❤️",
    "description": "red heart",
    "aliases": ["heart"],
    "tags": ["love"],
    "unicode_version": "",
    "ios_version": "6.0"
  },
  {
    "emoji": "🖤",
    "description": "black heart",
    "aliases": ["black_heart"],
    "tags": [],
    "unicode_version": "9.0",
    "ios_version": "10.2"
  },
  {
    "emoji": "💜",
    "description": "purple heart",
    "aliases": ["purple_heart"],
    "tags": [],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "💚",
    "description": "green heart",
    "aliases": ["green_heart"],
    "tags": [],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "💛",
    "description": "yellow heart",
    "aliases": ["yellow_heart"],
    "tags": [],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🧡",
    "description": "orange heart",
    "aliases": ["orange_heart"],
    "tags": [],
    "unicode_version": "11.0",
    "ios_version": "12.1"
  },
  {
    "emoji": "🔥",
    "description": "fire",
    "aliases": ["fire"],
    "tags": ["lit"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🚀",
    "description": "rocket",
    "aliases": ["rocket"],
    "tags": ["launch"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "💥",
    "description": "collision",
    "aliases": ["boom", "collision"],
    "tags": ["explode"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🎉",
    "description": "party popper",
    "aliases": ["tada"],
    "tags": ["hooray", "party"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🎂",
    "description": "birthday cake",
    "aliases": ["birthday"],
    "tags": ["party"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🌻",
    "description": "sunflower",
    "aliases": ["sunflower"],
    "tags": [],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "💰",
    "description": "money bag",
    "aliases": ["moneybag"],
    "tags": ["dollar"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "📈",
    "description": "chart increasing",
    "aliases": ["chart_with_upwards_trend"],
    "tags": ["graph", "metrics"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🤖",
    "description": "robot",
    "aliases": ["robot_face"],
    "tags": [],
    "unicode_version": "8.0",
    "ios_version": "9.1"
  },
  {
    "emoji": "💯",
    "description": "hundred points",
    "aliases": ["100"],
    "tags": ["score", "perfect"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🚨",
    "description": "police car light",
    "aliases": ["rotating_light"],
    "tags": ["911", "emergency"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🚩",
    "description": "triangular flag",
    "aliases": ["triangular_flag_on_post"],
    "tags": [],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "✅",
    "description": "check mark button",
    "aliases": ["white_check_mark"],
    "tags": [],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "✔️",
    "description": "check mark",
    "aliases": ["heavy_check_mark"],
    "tags": [],
    "unicode_version": "",
    "ios_version": "6.0"
  },
  {
    "emoji": "❎",
    "description": "cross mark button",
    "aliases": ["negative_squared_cross_mark"],
    "tags": [],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "✖️",
    "description": "cross mark",
    "aliases": ["x"],
    "tags": [],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "❓",
    "description": "red question mark",
    "aliases": ["question"],
    "tags": ["confused"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "📌",
    "description": "pushpin",
    "aliases": ["pushpin"],
    "tags": ["location"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🐾",
    "description": "paw prints",
    "aliases": ["feet"],
    "tags": ["pet"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🐶",
    "description": "dog face",
    "aliases": ["dog"],
    "tags": ["pet"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🦮",
    "description": "guide dog",
    "aliases": ["guide_dog"],
    "tags": [],
    "unicode_version": "12.0",
    "ios_version": "13.0"
  },
  {
    "emoji": "😸",
    "description": "grinning cat with smiling eyes",
    "aliases": ["smile_cat"],
    "tags": [],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🐈",
    "description": "cat",
    "aliases": ["cat"],
    "tags": ["pet"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "😻",
    "description": "smiling cat with heart-eyes",
    "aliases": ["heart_eyes_cat"],
    "tags": ["love"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "⚡️",
    "description": "high voltage",
    "aliases": ["zap"],
    "tags": ["electric"],
    "unicode_version": "5.2",
    "ios_version": "6.0"
  },
  {
    "emoji": "🌿",
    "description": "herb",
    "aliases": ["herb"],
    "tags": ["plant"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🌈",
    "description": "rainbow",
    "aliases": ["rainbow"],
    "tags": ["pride"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "☀️",
    "description": "sun",
    "aliases": ["sunny"],
    "tags": ["weather"],
    "unicode_version": "1.1",
    "ios_version": "6.0"
  },
  {
    "emoji": "⛷️",
    "description": "skier",
    "aliases": ["skier"],
    "tags": ["sports"],
    "unicode_version": "5.2",
    "ios_version": "6.0"
  },
  {
    "emoji": "🏂",
    "description": "snowboarder",
    "aliases": ["snowboarder"],
    "tags": ["sports"],
    "unicode_version": "5.2",
    "ios_version": "6.0"
  },
  {
    "emoji": "🎶",
    "description": "musical notes",
    "aliases": ["notes"],
    "tags": ["music"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🕺",
    "description": "man dancing",
    "aliases": ["man_dancing"],
    "tags": [],
    "unicode_version": "9.0",
    "ios_version": "10.2"
  },
  {
    "emoji": "💃",
    "description": "woman dancing",
    "aliases": ["dancer"],
    "tags": ["party"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🎸",
    "description": "guitar",
    "aliases": ["guitar"],
    "tags": ["rock"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🎹",
    "description": "musical keyboard",
    "aliases": ["musical_keyboard"],
    "tags": ["piano"],
    "unicode_version": "6.0",
    "ios_version": "6.0"
  },
  {
    "emoji": "🥁",
    "description": "drum",
    "aliases": ["drum"],
    "tags": [],
    "unicode_version": "9.0",
    "ios_version": "10.2"
  }
]

};
export { Emojis };
