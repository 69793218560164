import { Controller } from "stimulus"

export default class extends Controller {

  static targets = []

  static values = {
    draft: Boolean,
    internal: Boolean,
    messageThreadId: Number
  }

  updateState() {
    var message_thread_container = document.querySelector("#message-thread-" + this.messageThreadIdValue);
    if (this.draftValue == true) {
      message_thread_container.classList.add("draft-thread");
    }
    if (this.internalValue == true) {
      message_thread_container.classList.add("internal-thread");
    }
  }

}