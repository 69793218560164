import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['conversationItem', 'conversationThumbnailContainer']

  static values = {}

  connect() {
    var conversation_ids = []
    for (let conversationItem of this.conversationItemTargets) {
      conversation_ids.push(conversationItem.id.split('-')[1]);
    }

    var queryParams = window.location.href.split('?')[1]?.split('&');
    var params = {};
    queryParams?.forEach(function(param) {
      var parts = param.split('=');
      var key = decodeURIComponent(parts[0]);
      var value = decodeURIComponent(parts[1]);
      params[key] = value;
    });

    Rails.ajax({
      type: "get",
      url: '/load_conversation_thumbnail/?conversation_ids=' + conversation_ids+ '&mode=' + params['mode'],
    })
  }
}
