// HOW TO USE:

// TOGGLERS:
// - Togglers (fields with target 'toggler' applied) are field elements (typically selections, sometimes checkboxes) that, when changed, can show/hide other elements.
// - Togglers get the data-action=change->workflows--config#toggle
// - Togglers get a unique data-field-id (it should typically match the field label)

// TOGGLEABLES:
// - Toggleables (elements with target 'toggleable' applied) are divs that can be shown/hidden as a result of a toggler's value changing. 
// - On the toggleable div, define data-toggle-by-field with value(s) that list all of the toggler field IDs that should control this toggleable's visibility. 
//    - Multiple togglers can control one toggleable.  List multiple toggler field IDs in the data-toggle-by-field value separated by a single space " ".
// - On the toggleable div, define data-show-when-selected-option with the value that must match the toggler's value in order for this toggleable to be shown.
//    - Multiple possible values can cause a toggleable to be shown.  List multiple values in the data-show-when-selected-option value separated by a single space " ".

// TOGGLEABLE CONTENT:
// - ToggleableContent are addtional pieces of content that use a simple show/hide but aren't triggered via the main toggler/toggleables system above

import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['submitButton', 
                    'toggler', 
                    'toggleable',
                    'navItem',
                    'formPanel',
                    'toggleableContent'
                    ]

  static values = {
    objectType: String
  }

  connect() {
  }

  navToggle(event) {
    var nav_tab = event.target.closest(".workflow-form-nav-item");
    var group_id = nav_tab.dataset.workflowFormGroupId;
    var selectedPanel = this.element.querySelector(".workflow-form-panel[data-workflow-form-group-id="+group_id+"]");

    // Activate the nav tab
    for(let tab of this.navItemTargets) {
      tab.classList.remove("active");
    }
    nav_tab.classList.add("active");

    // Activate the group panel
    for(let panel of this.formPanelTargets) {
      panel.classList.add("hidden");
    }
    selectedPanel.classList.remove("hidden");
  }

  navAfterStepTypeSelection(event) {
    var value = event.target.value;

    // Make the current tab inactive
    for(let tab of this.navItemTargets) {
      tab.classList.remove("active");
    }
    // Make the current panel inactive
    for(let panel of this.formPanelTargets) {
      panel.classList.add("hidden");
    }

    // Activate the tab & panel based on the selected step_type
    if (value == "email") {
      
      var email_tab = this.element.querySelector(".workflow-form-nav-item[data-workflow-form-group-id='compose_email']");
      var email_panel = this.element.querySelector(".workflow-form-panel[data-workflow-form-group-id='compose_email']");
      var first_field = email_panel.querySelector("input#workflow_step_step_options_mailer_options_to");
      email_tab.classList.add("active");
      email_panel.classList.remove("hidden");
      first_field.focus();

    } else if (value == "create_message") {
      
      var create_message_tab = this.element.querySelector(".workflow-form-nav-item[data-workflow-form-group-id='message']");
      var create_message_panel = this.element.querySelector(".workflow-form-panel[data-workflow-form-group-id='message']");
      var first_field = create_message_panel.querySelector("select#workflow_step_step_options_message_options_message_type");
      create_message_tab.classList.add("active");
      create_message_panel.classList.remove("hidden");
      first_field.focus();

    } else {

      var create_message_tab = this.element.querySelector(".workflow-form-nav-item[data-workflow-form-group-id='step_type']");
      var create_message_panel = this.element.querySelector(".workflow-form-panel[data-workflow-form-group-id='step_type']");
      var first_field = create_message_panel.querySelector("select#workflow_step_step_type");
      create_message_tab.classList.add("active");
      create_message_panel.classList.remove("hidden");
      first_field.focus();

    }
  }

  toggle(event) {
    var field_id = event.target.dataset.fieldId
    var selected_option = event.target.value
    var toggleables = this.toggleableTargets
    for (let toggleable of toggleables) {

      // If the toggled selection field is to determine this field's visibility...
      if (toggleable.dataset.toggleByField.includes(field_id)) {

        // If the selected option is the one that reveals this field, show it.  Otherwise hide it.
        if (toggleable.dataset.showWhenSelectedOption.includes(selected_option)) {
          toggleable.classList.remove("hidden", "sm:hidden", "md:hidden", "lg:hidden");
        } else {
          // Check to see if this toggleable can be toggled by multiple toggler fields, and if so, check to see if those other togglers have values that should keep this field visible.
          var toggler_field_ids = toggleable.dataset.toggleByField.split(" ");

          if (toggler_field_ids.length > 1) { // If there are multiple toggler fields that control this toggleable...

            for (let toggler of this.togglerTargets) {

              // Loop through all of the togglers and for each...
              // Check to see if:
              //  1. This toggler isn't the same toggler that the user just changed
              //  2. This toggler's value isn't null nor empty (as might be the case when a selection option has no value)
              //  3. This toggler's field ID is one of the togglers that should control this toggleable's visibility
              //  4. This toggler's value does match the value that should keep this toggleable visible
              if ((toggler.dataset.fieldId !== field_id) && ((toggler.value !== null && toggler.value !== "") && toggler_field_ids.includes(toggler.dataset.fieldId) && toggleable.dataset.showWhenSelectedOption.includes(toggler.value))) {
                var keep_visible = true;
              }
            }
          }

          // Hide the field, unless it should be kept visible
          if (!keep_visible) {
            toggleable.classList.add("hidden", "sm:hidden", "md:hidden", "lg:hidden");

            // If this input (likely a checkbox) needs to be turned to false when it is hidden, make it false
            if (toggleable.dataset.falsifyInputWhenHidden == "true") {
              // initiate the toggle_switch_controller.js for the toggleable element and then use setValue to set it to false (toggle-switch controller will handle several moving parts for that)
              let linkedToggleInstance = this.application.getControllerForElementAndIdentifier(toggleable, 'toggle-switch');
              linkedToggleInstance.setValue(false);
            }
          }

        }
      }
    }
  }

  changeTriggerToMessagePosted(event) {
    // When trigger_event is changed to message_posted, there are several fields we must hide, and these hidings must override toggles that happened via the toggle action.

    var selected_option = event.target.value;
    var cancel_event_by = this.element.querySelector(".cancel-event-by");
    var cancel_events_nav_item = this.element.querySelector(".workflow-form-nav-item[data-workflow-form-group-id='cancel_events']");
    var cancel_event_by_emails = this.element.querySelector(".cancel-event-by-emails");
    var toggle_messages_in_this_thread = this.element.querySelector(".toggle-messages-in-this-thread");

    // If we've changed trigger_event to message_posted...
    if (selected_option == "message_posted") {

      // Hide .toggle-messages-in-this-thread checkbox
      if (toggle_messages_in_this_thread && !toggle_messages_in_this_thread.classList.contains("hidden")) {
        toggle_messages_in_this_thread.classList.add("hidden", "sm:hidden", "md:hidden", "lg:hidden");
      }


      // Hide the cancel-events nav item
      if (this.objectTypeValue == "conversation") {
        // Hide the cancel-event-by and cancel-event-by-emails fields
        if (!cancel_event_by.classList.contains("hidden")) {
          cancel_event_by.classList.add("hidden", "sm:hidden", "md:hidden", "lg:hidden");
          cancel_event_by_emails.classList.add("hidden", "sm:hidden", "md:hidden", "lg:hidden");
        }

        // Hide the cancel events nav item
        cancel_events_nav_item.classList.add("hidden");
      }
    }
  }

  toggleContent(event) {
    var toggle_content_id = event.target.dataset.contentToToggle;
    var toggleable_content = this.element.querySelector("#"+toggle_content_id);
    if (toggleable_content) {
      if (toggleable_content.classList.contains("hidden")) {
        toggleable_content.classList.remove("hidden", "sm:hidden", "md:hidden", "lg:hidden");
      } else {
        toggleable_content.classList.add("hidden", "sm:hidden", "md:hidden", "lg:hidden");
      }
    }
  }

  toggleTriggerOnThreadedMessages(event) {
    // Used to toggle the checkboxes for "Which message(s) should trigger this workflow?".  When "Only messages within this message's thread could trigger this workflow" is true, the other checkboxes must be false.
    
    var field_id = event.target.dataset.fieldId;
    var toggled_to_value = event.target.value;
    var trigger_on_this_message_thread = this.element.querySelector("#trigger_on_this_message_thread");
    var trigger_on_top_messages = this.element.querySelector("#trigger_on_top_messages");
    var trigger_on_threaded_messages = this.element.querySelector("#trigger_on_threaded_messages");

    if (field_id == "trigger_on_this_message_thread" && toggled_to_value == "true") {
      // trigger_on_this_message_thread was changed to true, so turn the other 2 fields to false.
      trigger_on_top_messages.querySelector("input").value = false;
      trigger_on_top_messages.dataset.toggleSwitchActiveValue = false;
      trigger_on_top_messages.classList.remove("active");
      trigger_on_threaded_messages.querySelector("input").value = false;
      trigger_on_threaded_messages.dataset.toggleSwitchActiveValue = false;
      trigger_on_threaded_messages.classList.remove("active");
      trigger_on_top_messages.querySelector("button").classList.remove('bg-primary-600')
      trigger_on_top_messages.querySelector("button").classList.add('bg-gray-200')
      trigger_on_threaded_messages.querySelector("button").classList.remove('bg-primary-600')
      trigger_on_threaded_messages.querySelector("button").classList.add('bg-gray-200')
    } else if (field_id != "trigger_on_this_message_thread" && toggled_to_value == "true") {
      // Either of the other fields (not trigger_on_this_message_thread) were changed to true, so change trigger_on_this_message_thread to false.
      trigger_on_this_message_thread.querySelector("input").value = false;
      trigger_on_this_message_thread.dataset.toggleSwitchActiveValue = false;
      trigger_on_this_message_thread.classList.remove("active");
      trigger_on_this_message_thread.querySelector("button").classList.remove('bg-primary-600')
      trigger_on_this_message_thread.querySelector("button").classList.add('bg-gray-200')
    }

  }

  setTriggerEventValue(event) {
    this.triggerEventValue = event.target.value;
  }

  revealSubmitBtn(event) {
    var changed_field = event.target;
    if (changed_field.dataset.fieldId === "trigger_event" && changed_field.value === "unselected") {
      this.submitButtonTarget.classList.add("hidden");
      this.submitButtonTarget.disabled = true;
    } else {
      this.submitButtonTarget.classList.remove("hidden");
      this.submitButtonTarget.disabled = false;
    }
  }

  cancelWorkflowForm(event) {
    var workflow = event.target.closest(".workflow-container").querySelector(".workflow");
    var workflow_trigger = workflow.querySelector(".workflow-trigger");
    var add_step_button = workflow.querySelector(".add-workflow-step-button");
    var last_step_dashed_line = workflow_container.querySelector(".last-workflow-step-dashed-line");
    var main_workflow_line = workflow_container.querySelector(".main-workflow-line");

    // Show the workflow trigger info
    workflow_trigger.classList.remove("hidden");
    
    // Remove the edit workflow form
    this.element.remove();

    // Show the add step button
    add_step_button.classList.remove("hidden");

    // Show the main workflow line (it would be hidden if there are no steps yet)
    main_workflow_line.classList.remove("hidden");

    if (last_step_dashed_line) {
      // Show the dashed line on the last step
      last_step_dashed_line.classList.remove("hidden");
    }
  }

  cancelEditWorkflowStepForm(event) {
    var workflow_step_container = event.target.closest(".workflow-step-container");
    var workflow_container = workflow_step_container.closest(".workflow");
    var add_step_button = workflow_container.querySelector(".add-workflow-step-button");
    var last_step_dashed_line = workflow_container.querySelector(".last-workflow-step-dashed-line");
    var workflow_step_meta = workflow_step_container.querySelector(".workflow-step-meta");
    var workflow_step_content = workflow_step_container.querySelector(".workflow-step-content");

    // Show the workflow step content
    workflow_step_content.classList.remove("hidden");

    // Show the workflow step meta (delay desc, settings, etc)
    workflow_step_meta.classList.remove("hidden");

    // Show the add step button
    add_step_button.classList.remove("hidden");

    // Show the dashed line on the last step
    last_step_dashed_line.classList.remove("hidden");

    // Remove the form element from DOM
    this.element.remove();
  }

  cancelNewWorkflowStepForm(event) {
    var workflow_container = event.target.closest(".workflow");
    var new_step = workflow_container.querySelector(".new-workflow-step");
    var add_step_button = workflow_container.querySelector(".add-workflow-step-button");

    new_step.remove();
    add_step_button.classList.remove("hidden");
  }

}