// This controls the global search functionality, where user can click "/" to open a modal to search all conversations and messages (and probably more things in the future)

import search_methods from './lib/search_methods'
import {Controller} from 'stimulus'

export default class extends Controller {

    static targets = ['searchModal', 'searchInput', 'searchResultsContainer', 'searchResults', 'clearSearchIcon', 'librarySearchResults', 'librarySearchInput', 'selectedResult']

    connect() {
        window.globalSearchInitializer = new search_methods(this.searchInputTarget, this.searchResultsContainerTarget, this.searchResultsTarget);
    }

    open(event) {
        var other_modal = document.querySelector(".modal.shown");
        var creating_text_message = document.querySelector(".message-block-toggle[data-message-type-id='text'].active");
        var editing_text_message = document.querySelector(".editing-message");
        var disable_search = document.querySelector(".disable-search");
        if ((event.key == "/" && !other_modal && !creating_text_message && !editing_text_message && !disable_search) || event.type == "click") { // forward slash keycode is 191
            var modal = this.searchModalTarget;
            modal.classList.remove("hidden");
            modal.classList.add("shown");
            this.searchInputTarget.value = "";
            this.searchResultsTarget.innerHTML = "";
            this.searchResultsTarget.classList.add("hidden");
            this.searchInputTarget.focus();
        }
    }

    modifySetting(event) {
        var currentlyActive = this.searchModalTarget.querySelector('.search-type.active');
        var settingPicked = event.target;
        var search_type = settingPicked.dataset.searchType;
        var search_input = this.searchInputTarget;

        currentlyActive.classList.remove('active');
        currentlyActive.classList.add('inactive');
        settingPicked.classList.remove('inactive');
        settingPicked.classList.add('active');

        search_input.dataset.searchObjectType = search_type;

        if (search_type == "conversations") {
            search_input.placeholder = "Search conversation titles";
        } else if (search_type == "messages") {
            search_input.placeholder = "Search message titles, text, and transcripts";
        } else if (search_type == "contacts") {
            search_input.placeholder = "Search contacts";
        } else if (search_type == "library") {
            search_input.placeholder = "Search Library templates";
        }

        search_input.focus();
        var inputValue = this.searchInputTarget.value;
        if (typeof (inputValue) != 'undefined' && inputValue != null && inputValue.trim() !== '') {
            this.search(event);
        }
    }

    search(event) {
        var inputValue = this.searchInputTarget.value;
        var params = new URLSearchParams({
            q: inputValue,
            object_type: this.searchInputTarget.dataset.searchObjectType,
            inside_conversation: this.searchInputTarget.dataset.searchInsideConversation,
            url: location.href
        });
        var path = `/${accountId}/global_search?${params}`;

        if (this.searchInputTarget && event.keyCode != 40 && event.keyCode != 38) {
            if (typeof (inputValue) != 'undefined' && inputValue != null) {
                window.globalSearchInitializer.get_search_results(path).then(response => {
                    if (response === undefined) {
                        this.searchResultsContainerTarget.classList.add('hidden');
                    } else {
                        this.searchResultsContainerTarget.classList.remove('hidden');
                        this.searchResultsTarget.classList.remove('hidden');
                        this.searchResultsTarget.innerHTML = response
                    }
                });
            }
        }
    }

    navigate(event) {
        if (this.searchModalTarget.classList.contains("shown")) {
            var search_results = Array.from(this.searchResultsTarget.querySelectorAll(".search-result"));

            var focused_result = this.searchResultsTarget.querySelector(".search-result:focus");
            var i = 0;

            if (event.keyCode == 38) { // UP key is pressed
                event.preventDefault();
                if (focused_result) {
                    i = search_results.indexOf(focused_result);
                    if (i == 0) {
                        this.searchInputTarget.focus();
                    } else {
                        i = i - 1;
                        search_results[i].focus();
                    }
                } else {
                    search_results[i].focus();
                }
            }
            if (event.keyCode == 40) { // DOWN key is pressed
                event.preventDefault();
                if (focused_result) {
                    i = search_results.indexOf(focused_result);
                    if (i == search_results.length - 1) {
                        this.searchInputTarget.focus();
                    } else {
                        i = i + 1;
                        search_results[i].focus();
                    }
                } else {
                    search_results[i].focus();
                }
            }
        }
    }

    // this search_library action is used when searching for a template to post a message in a conversation
    search_library(event) {
        this.selectedResultTarget.classList.add('hidden')
        this.clearSearchIconTarget.classList.remove("hidden");
        var params = new URLSearchParams({
            q: event.target.value,
            object_type: 'library',
            inside_conversation: true,
            url: location.href,
            inside_thread: event.target.dataset.insideThread
        });

        var path = `/${accountId}/global_search?${params}`;
        window.globalSearchInitializer.get_search_results(path).then(response => {
            if (response !== undefined) {
                if (this.hasLibrarySearchResultsTarget) {
                    this.librarySearchResultsTarget.innerHTML = response;
                }
            }
        });
    }

    // this is used when keyboard navigating the search results when choosing a template to post as a message in a conversat.
    navigate_library(event) {
        var search_results_container = this.element.querySelector(".search-library-in-message-creator .library-search-results-group .search-results-group");
        if (search_results_container) {
            var search_results = Array.from(this.librarySearchResultsTarget.querySelectorAll(".search-result"));
            var focused_result = this.librarySearchResultsTarget.querySelector(".search-result.focused");

            var i = 0;

            if (event.keyCode == 38) { // UP key is pressed
                event.preventDefault();
                if (focused_result) {
                    i = search_results.indexOf(focused_result);
                    if (i == 0) {
                        this.librarySearchInputTarget.focus();
                        focused_result.classList.remove("focused");
                    } else {
                        focused_result.classList.remove("focused");
                        i = i - 1;
                        search_results[i].classList.add("focused");
                    }
                } else {
                    search_results[i].classList.add("focused");
                }
            }
            if (event.keyCode == 40) { // DOWN key is pressed
                event.preventDefault();
                if (focused_result) {
                    i = search_results.indexOf(focused_result);
                    if (i == search_results.length - 1) {
                        this.librarySearchInputTarget.focus();
                        focused_result.classList.remove("focused");
                    } else {
                        focused_result.classList.remove("focused");
                        i = i + 1;
                        search_results[i].classList.add("focused");
                    }
                } else {
                    search_results[i].classList.add("focused");
                }
            }

            if(event.keyCode == 13){
                event.preventDefault();
                if (focused_result) {
                    focused_result.click();
                }
            }
        }
    }
}