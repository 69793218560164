import {Controller} from 'stimulus'

export default class extends Controller {
    static targets = [
        "googleCalendarIdField",
        "calendarNameField"
    ]

    setCalendarName() {
        console.log('set_calendar_name')
        var selectedOption = this.googleCalendarIdFieldTarget.options[this.googleCalendarIdFieldTarget.selectedIndex];
        this.calendarNameFieldTarget.value = selectedOption.text;
    }
}