// `audio_media_stream` function will get the mic stream by requesting the users for access
//
// IMPORTANT FUNCTIONS
// getDevices()
// gotDevices()
// getStream()
// gotStream()
// Above mentioned functions are the main functions used to get the audio stream

import DetectAudioLevel from "../concerns/detect_audio_level";

export default function audio_media_stream() {
  var previewElement = this.previewTarget;
  var audioSelect = this.selectAudioSourceTarget.querySelector("select");
  var startMicRecordBtn = this.startMicRecordBtnTarget;
  var startCameraRecordBtn = this.startCameraRecordBtnTarget;
  var startScreenRecordBtn = this.startScreenRecordBtnTarget;
  var loadingIcon = this.loadingIconTarget;
  var camMicSelectorsTarget = this.camMicSelectorsTarget
  var messageUITarget = this.messageUITarget

  audioSelect.onchange = getStream;

  if(localStorage.getItem("zipmessage-mic")) {
    getDevices().then(gotDevices).then(() => getStream.call(this)).then(getDevices).then(gotDevices);
  } else {
    getStream.call(this).then(getDevices).then(gotDevices);
  }

  // ---MAIN FUNCTION STARTS---

  function getDevices() {
    return navigator.mediaDevices.enumerateDevices();
  }

  function gotDevices(deviceInfos) {
    audioSelect.innerHTML = "";
    window.deviceInfos = deviceInfos; // make available to console

    let layoutMic = localStorage.getItem("zipmessage-mic");

    console.log('Available input and output devices:', deviceInfos);
    for (const deviceInfo of deviceInfos) {
      const option = document.createElement('option');
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === 'audioinput') {
        option.text = deviceInfo.label || `Microphone ${audioSelect.length + 1}`;

        if(layoutMic === JSON.stringify(option.value)) {
          option.selected = true;
        }

        audioSelect.appendChild(option);
      }
    }
  }

  function getStream() {
    stopStreams();

    const audioSource = audioSelect.value;
    const constraints = {
      audio: {deviceId: audioSource ? {exact: audioSource} : undefined},
      echoCancellation: true,
      noiseSuppression: true,
      sampleRate: 44100
    };
    return navigator.mediaDevices.getUserMedia(constraints).then(gotStream).catch(error => handleError.call(this, error));
  }

  function gotStream(stream) {
    window.stream = stream; // make stream available to console

    // Set currently active media device ids to local storage
    if(audioSelect.value !== null && audioSelect.value.length > 0) localStorage.setItem("zipmessage-mic", JSON.stringify(audioSelect.value));

    audioSelect.selectedIndex = Array.from(audioSelect.options).
    findIndex(option => getIndexOfOption(option, stream.getAudioTracks()[0].label));

    previewElement.querySelector("video").srcObject = stream;

    if(startMicRecordBtn || startCameraRecordBtn || startScreenRecordBtn) {
      loadingIcon.classList.add("hidden"); // Hide the loading spinner icon
      startMicRecordBtn.classList.remove("hidden"); // show the "start recording mic" button
      startCameraRecordBtn.classList.add("hidden"); // hide the "start recording camera" button
      startScreenRecordBtn.classList.add("hidden"); // hide the "start recording screen" button
    }

    // show audio level of mic
    var detectAudio = new DetectAudioLevel();
    detectAudio.detectAudioLevel(stream, 'audio-meter', camMicSelectorsTarget, messageUITarget);
  }

  // ---MAIN FUNCTION ENDS---


  // ---HELPER FUNCTION STARTS---

  function stopStreams() {
    // Stop already active media streams
    if (window.stream) {
      window.stream.getTracks().forEach(track => {
        track.stop();
      });
    }

    if(window.streamArr) {
      window.streamArr.forEach(stream => {
        stream.getTracks().forEach(track => {
          track.stop();
        });
      })

      window.streamArr = [];
    }

    window.streamArr = [];
  }

  function getIndexOfOption(option, label) {
    return option.text === label;
  }

  // ---HELPER FUNCTION ENDS---


  function handleError(error) {
    console.error('Error: ', error);
    console.error('Error: ', error.name);

    this.mediaError(error)
    if(window.appsignal !== undefined) { var span = window.appsignal.createSpan();
      span.setParams({ account_id: accountId })
      span.setTags({
        tag: "Media error"
      }).setError({name: "Mic permission denied", message: error});
      window.appsignal.send(span);};
  }
}