// Used for each individual resizeable clip on the edit message timeline

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [];

  connect() {
    makeResizableClip(this.element);
  }

}

function makeResizableClip(div) {
  let finalCheck = div.parentElement.clientWidth;
  let prevCheck = 0;
  var timelineElement;
  let element = div;
  const resizers = div.querySelectorAll('.clip-edge');
  const timeWidth = clipTimelineWidth * ((0.1 * 100)/recordedDuration)/100;
  const oneSecond = clipTimelineWidth * ((0.3 * 100)/recordedDuration)/100;
  const minimum_size = timeWidth;
  let original_width = 0;
  let original_left = 0;
  let original_height = 0;
  let original_x = 0;
  let original_y = 0;
  let original_mouse_x = 0;
  let original_mouse_y = 0;
  for (let i = 0;i < resizers.length; i++) {
    const currentResizer = resizers[i];
    currentResizer.addEventListener('mousedown', function(e) {
      if(prevCheck > 0) {
        prevCheck = 0;
      }
      finalCheck = div.parentElement.clientWidth;
      timelineElement = div.closest('.edit-clips-timeline').getBoundingClientRect();
      e.preventDefault()
      const currentParent = e.srcElement.offsetParent;
      let parent  = e.target.closest('.edit-clips-timeline');
      if(parent) {
        let clips = parent.getElementsByClassName('timeline-clip');
        for(let i = 0; i<clips.length;i++) {
          if (currentParent.getAttribute("id") !== clips[i].getAttribute("id")) {
            if (clips[i].offsetLeft > currentParent.offsetLeft+currentParent.clientWidth && clips[i].offsetLeft < finalCheck) {
                finalCheck = clips[i].offsetLeft;
            }
            if(clips[i].offsetLeft+clips[i].clientWidth <  currentParent.offsetLeft && clips[i].offsetLeft+clips[i].clientWidth > prevCheck) {
              prevCheck = clips[i].offsetLeft+clips[i].clientWidth;
            }
          }
        }
      }
      if(prevCheck != 0) {
        prevCheck = prevCheck+oneSecond;
      }
      if(finalCheck == div.parentElement.clientWidth) {
        finalCheck = finalCheck+2;
      }
      else {
        finalCheck = finalCheck-oneSecond;
      }
      original_width = parseFloat(getComputedStyle(element, null).getPropertyValue('width').replace('px', ''));
      original_left = parseFloat(getComputedStyle(element, null).getPropertyValue('left').replace('px', ''));
      original_height = parseFloat(getComputedStyle(element, null).getPropertyValue('height').replace('px', ''));
      original_x = element.getBoundingClientRect().left;
      original_y = element.getBoundingClientRect().top;
      original_mouse_x = e.pageX;
      original_mouse_y = e.pageY;
      window.addEventListener('mousemove', resize)
      window.addEventListener('mouseup', stopResize)
    })

    function resize(e) {
      if (currentResizer.classList.contains('clip-edge-right')) {
        let width = original_width + (e.pageX - original_mouse_x);
        const height = original_height + (e.pageY - original_mouse_y);
        if (width > minimum_size && width+element.offsetLeft < finalCheck) {
          element.style.width = width + 'px'
        }
      }
      else if (currentResizer.classList.contains('clip-edge-left')) {
        var leftOffset = e.clientX - timelineElement.left; //x position within the element.
        var width;
        if(leftOffset < original_left) {
          width = original_width + (original_left-leftOffset);
        } else {
            let tempOff = leftOffset-original_left;
            if(tempOff > original_width) {
              width = tempOff-original_width;
            }
            else {
              width = original_width-tempOff;
            }
        }
        if (width > minimum_size && leftOffset > prevCheck && leftOffset < original_width+original_left) {
          element.style.left = leftOffset + 'px'
          element.style.width = width + 'px'
        }
      }

      // Update cut field value when slider is dragged
      if (element !== undefined && element !== null && element.parentNode !== null && element.parentNode.parentNode.nextElementSibling !== null) {
        updateCutFields(element);
      }
    }

    function stopResize() {
      window.removeEventListener('mousemove', resize)


      // setting section hide/show after slider positions allocated
      if (element !== undefined && element !== null && element.parentNode !== null && element.parentNode.parentNode.nextElementSibling !== null) {
        setSectionVisibility(element);
      }
    }
  }
}

function updateCutFields(element) {
  var calVal1 = parseInt(element.style.width.split('px')[0])
  var calVal2 = parseInt(element.style.left.split('px')[0])
  var videoDuration = timeToSecond(element.parentNode.parentNode.nextElementSibling.querySelector("form #media_duration").value)
  var totalTimelineWidth = element.closest(".edit-clips-timeline").clientWidth;


  let startCutValueWithoutHour, endCutValueWithoutHour;
  let startCutValue = secondsToTime((((100 * calVal2)/totalTimelineWidth) * videoDuration)/100)
  let endCutValue = secondsToTime(((((100 * calVal1)/totalTimelineWidth) * videoDuration)/100)
    + ((((100 * calVal2)/totalTimelineWidth) * videoDuration)/100))

  if(videoDuration < 3600) {
    startCutValueWithoutHour = startCutValue.split(/:(.*)/s)[1];
    endCutValueWithoutHour = endCutValue.split(/:(.*)/s)[1];
  } else {
    startCutValueWithoutHour = startCutValue;
    endCutValueWithoutHour = endCutValue;
  }

  element.querySelector('.clip-edge-left').title = startCutValueWithoutHour;
  element.querySelector('.clip-edge-right').title = endCutValueWithoutHour;

  var clipPart = element.parentNode.parentNode.nextElementSibling.querySelector("form .media-cut-parts")
  let clipInput = clipPart.querySelector('#' + element.id + ' input.clip-parts-input');

  if(clipInput !== null)
    clipInput.value= `${startCutValueWithoutHour} - ${endCutValueWithoutHour}`;
}

function setSectionVisibility(element) {
  var arr = []
  let tempArr = [], spaceExist = false;
  element.parentNode.parentNode.nextElementSibling.querySelectorAll("form input.clip-parts-input").forEach(input => {
    let a = input.value.split(' - ')[0].split('.')[0];
    let b = input.value.split(' - ')[1].split('.')[0];
    a = a.split(':').length === 2 ? ('00:' + a) : a
    b = b.split(':').length === 2 ? ('00:' + b) : b
    tempArr.push(a, b);
    tempArr.sort();
  });

  if(document.querySelector(`#message-${element.closest('.edit-clips-timeline').dataset.objectId}`)){
  var message_container = document.querySelector(`#message-${element.closest('.edit-clips-timeline').dataset.objectId}`)
  }
  else{
    var message_container = document.querySelector(`#message_template-${element.closest('.edit-clips-timeline').dataset.objectId}`)
  }

  message_container.querySelector("div[data-message--edit-target='clipListStorage']").dataset.storage = tempArr;
  tempArr.every(time => arr.push(timeToSecond(time)));

  let sectionBtn = element.parentNode.parentNode.nextElementSibling.querySelector(".add-clip-section-btn")
  let mediaDurationInSecond = timeToSecond(element.parentNode.parentNode.nextElementSibling.querySelector("form #media_duration").value);

  /*
     - If no existing clip covers first 5 secs of the timeline, then insert this new clip at 0:00-0:05.
     - If a clip already covers 0:00, and no clip covers the final 5 secs of the timeline, then insert this new clip at the very end of the timeline, starting from 5 secs before the end.
     - If there are clips in the middle of the timeline, start the new clip in the first 5-second section that's available.

    TODO: add comments
    */
  if (arr.includes(0) === false && arr[0] >= 7)
    spaceExist = true

  if (arr.includes(mediaDurationInSecond) === false && mediaDurationInSecond - arr[arr.length - 1] > 7)
    spaceExist = true

  for (let i = 1; i < arr.length - 1; i += 2) {
    if ((arr[i + 1] - arr[i]) > 7) {
      spaceExist = true;
      break;
    }
  }

  if (spaceExist === false)
    sectionBtn.classList.add("hidden");
  else
    sectionBtn.classList.remove("hidden");
}

function timeToSecond(hms){
  var a = hms.split(':'); // split it at the colons
  return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
}

function secondsToTime(seconds){
  return new Date(seconds * 1000).toISOString().substr(11, 11);
}
