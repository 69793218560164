import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'toggleNewProgramItemForm',
    'programItemForm',
    'emptyProgramMessage'
  ];

  connect() {
  }

  toggleNewProgramItemForm() {
    var toggleProgramItemForm = this.toggleNewProgramItemFormTarget;
    var programItemForm = this.programItemFormTarget;
    if (this.hasEmptyProgramMessageTarget) {
      var emptyProgramMessage = this.emptyProgramMessageTarget;
    }
    var libraryPanelToggle = programItemForm.querySelector(".media-panel-toggle[data-message-type-id='library']");

    if (programItemForm.classList.contains("hidden")) { // we're revealing the programItemForm

      toggleProgramItemForm.classList.add("hidden");
      libraryPanelToggle.click(); // ensure the Library panel is active by default when we're adding a program item
      programItemForm.classList.remove("hidden");
      if (emptyProgramMessage) {
        emptyProgramMessage.classList.add("hidden")
      }
      programItemForm.scrollIntoView({behavior: "smooth", block: "start"});

    } 
    
  }

}