import {Controller} from "stimulus";
import camera_media_stream from "../message/media_streams/camera_media_stream";
import audio_media_stream from "../message/media_streams/audio_media_stream";

export default class extends Controller {
  static targets = [
    "emailField",
    "passwordField",
    "turnOnCameraBtn",
    "turnOnMicBtn",
    "cameraCheck",
    "micCheck",
    "camChecked",
    "camNotChecked",
    "micChecked",
    "micNotChecked",
    "cameraInstruction",
    "micInstruction",
    "camSelectors",
    "selectAudioSource",
    "selectVideoSource",
    "preview",
    "camMicSelectors",
    "micSelectorsContainer",
    "troubleshootModal",
    "welcomeMessageTypeField",
  ];

  static values = {};

  connect() {
    function mobile_screen() {
      var mq = window.matchMedia("(max-width: 640px)");
      if (mq.matches) {
        return true;
      } else {
        return false;
      }
    }

    if (mobile_screen() && window.location.href.includes("devices")) {
      window.location.href = this.element.dataset.mobileRedirectTo;
    }

    if (this.hasEmailFieldTarget) {
      var email_field = this.emailFieldTarget;
      if (email_field.value == "") {
        var urlParams = new URLSearchParams(window.location.search);
        var viewer = urlParams.get("viewer");
        if (viewer) {
          email_field.value = viewer;
          this.passwordFieldTarget.focus();
        } else {
          email_field.focus();
        }
      }
    }
  }

  initiateDeviceSelection() {
    if (event.target.dataset.device === "camera") {
      this.turnOnCameraBtnTarget.classList.add("hidden");
      this.cameraInstructionTarget
          .querySelector(".authorization-instruction")
          .classList.add("hidden");
      this.cameraInstructionTarget
          .querySelector(".selection-instruction")
          .classList.remove("hidden");
      this.selectVideoSourceTarget.classList.remove("hidden");
      this.previewStream("camera");
    } else if (event.target.dataset.device === "mic") {
      this.turnOnMicBtnTarget.classList.add("hidden");
      this.selectAudioSourceTarget.classList.remove("hidden");
      this.micSelectorsContainerTarget.classList.add("active");
      this.micInstructionTarget
          .querySelector(".authorization-instruction")
          .classList.add("hidden");
      this.micInstructionTarget
          .querySelector("#mic-check-message")
          .classList.remove("hidden");
      this.previewStream("mic");
    }
  }

  previewStream(device) {
    var cameraButton = document.querySelector(
        'div[data-message--kickoff-recording-target="cameraButton"]'
    );
    var micButton = document.querySelector(
        'div[data-message--kickoff-recording-target="micButton"]'
    );

    if (device === "camera") {
      this.stop();
      this.selectAudioSourceTarget.classList.add("hidden");
      camera_media_stream.call(this);
      this.previewTarget.classList.remove("hidden");
      this.camNotCheckedTarget.classList.add("hidden");
      this.camCheckedTarget.classList.remove("hidden");
      this.micNotCheckedTarget.classList.add("hidden");
      this.micCheckedTarget.classList.add("hidden");
    } else if (device === "mic") {
      this.stop();
      this.selectVideoSourceTarget.classList.add("hidden");
      audio_media_stream.call(this);
      this.previewTarget.classList.add("hidden");
      this.camNotCheckedTarget.classList.add("hidden");
      this.camCheckedTarget.classList.add("hidden");
      this.micNotCheckedTarget.classList.add("hidden");
      this.micCheckedTarget.classList.remove("hidden");
    }
  }

  stop() {
    // stop stream
    if (window.stream) {
      window.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }

    if (window.streamArr) {
      window.streamArr.forEach((stream) => {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      });

      window.streamArr = [];
    }
  }

  continueToMicCheck() {
    this.stop();
    this.cameraCheckTarget.classList.add("hidden");
    this.micCheckTarget.classList.remove("hidden");
    this.camNotCheckedTarget.classList.add("hidden");
    this.camCheckedTarget.classList.add("hidden");
    this.micNotCheckedTarget.classList.remove("hidden");
    this.micCheckedTarget.classList.add("hidden");
  }

  mediaError(error) {
    var troubleshootModal = this.troubleshootModalTarget;
    var jsError = document.querySelector("#troubleshootJsError");

    if (troubleshootModal) troubleshootModal.click(); // open modal

    if (jsError) jsError.innerText = error.name;
  }

  toggleOnboardingStep(event) {
    console.log("toggleOnboardingStep");
    var onboardingStepContainer = event.target.closest(".onboarding-step");
    console.log(onboardingStepContainer);
    var toggleSubContent = onboardingStepContainer.querySelector(
        ".toggle-step-content"
    );
    console.log(toggleSubContent);

    if (onboardingStepContainer.classList.contains("disabled")) {
      console.log(
          "currently disabled.  We will remove .disabled class from container."
      );
      onboardingStepContainer.classList.remove("disabled");
      onboardingStepContainer.classList.remove("hide-sub-content");
      if (toggleSubContent) {
        console.log("unhide toggleSubContent");
        toggleSubContent.classList.remove("hidden");
      }
    } else {
      console.log(
          "currently enabled.  We will add .disabled class to container."
      );
      onboardingStepContainer.classList.add("disabled");
      onboardingStepContainer.classList.add("hide-sub-content");
      if (toggleSubContent) {
        console.log("hide toggleSubContent");
        toggleSubContent.classList.add("hidden");
      }
    }
  }

  toggleOnboardingStepContent(event) {
    var onboardingStepContainer = event.target.closest(".onboarding-step");

    if (onboardingStepContainer.classList.contains("hide-sub-content")) {
      onboardingStepContainer.classList.remove("hide-sub-content");
    } else {
      onboardingStepContainer.classList.add("hide-sub-content");
    }
  }

  toggleWelcomeMessageType(event) {
    var radiosGroup = event.target.closest(".radios-group");
    var radioContainer = event.target.closest(".radio-field");
    var label = event.target.closest("label");

    // Update the WelcomeMessageType field
    if (!radioContainer.classList.contains("active")) {
      // Deactivate the currently active radio
      var currentActiveRadio = radiosGroup.querySelector(".radio-field.active");
      currentActiveRadio.classList.remove("active");

      // Activate this radio
      radioContainer.classList.add("active");

      // Update the field value
      this.welcomeMessageTypeFieldTarget.value = radioContainer.dataset.value;
    }

    // Reveal the fields for the selected message type
    var genericWelcomeMessage = document.querySelector(
        "#generic-welcome-message"
    );
    var messageTemplateWelcomeMessage = document.querySelector(
        "#message-template-welcome-message"
    );
    var customWelcomeMessage = document.querySelector(
        "#custom-welcome-message"
    );
    if (radioContainer.dataset.value == "generic") {
      genericWelcomeMessage.classList.remove("hidden");
      messageTemplateWelcomeMessage.classList.add("hidden");
      customWelcomeMessage?.classList.add("hidden");
    } else if (radioContainer.dataset.value == "message_template") {
      genericWelcomeMessage.classList.add("hidden");
      messageTemplateWelcomeMessage.classList.remove("hidden");
      customWelcomeMessage?.classList.add("hidden");
    } else {
      // 'custom'
      genericWelcomeMessage.classList.add("hidden");
      messageTemplateWelcomeMessage.classList.add("hidden");
      customWelcomeMessage?.classList.remove("hidden");
    }
  }

  openRecordWelcomeMessageModal() {
    document
        .querySelector("#record-welcome-message-modal")
        .classList.remove("hidden");
  }

  ShowSpaceNote(event) {
    var params = new URLSearchParams({
      conversation_id: event.target.value,
      invitation_id: event.target.dataset.invitationId,
      multi_use_invitation_id: event.target.dataset.multiUseInvitationId,
    });

    fetch(`/showing_space_details?${params}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/js",
      },
    }).then(function (result) {
      result.text().then(function (val) {
        return eval(val);
      });
    });
  }

  updateProceedsTo(event) {
    var step_content = event.target.closest(".onboarding-step-content");
    var step_title = step_content.querySelector(".onboarding-step-title");
    var select_field = event.target.closest('select');
    var selected_option = event.target.value;
    var selected_text = select_field.querySelector(`option[value='${selected_option}']`).innerHTML;

    // make the first letter of selected_text lowercase
    var updated_destination_text = selected_text.charAt(0).toLowerCase() + selected_text.slice(1);

    step_title.innerHTML = "Proceeds to " + updated_destination_text;
  }
}

function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}
