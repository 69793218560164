import {Controller} from 'stimulus'

export default class extends Controller {

    static targets = ["checkbox", "input", "cancelButton"]

    connect() {}

    toggleSubscriptionSubmit() {
      let values = [];
      for (let v of this.checkboxTargets) {
        values.push(v.value);
      }
      if (values.every(value => value === "true") && this.inputTarget.value !== "" && this.inputTarget.value !== undefined && this.inputTarget.value !== null && this.inputTarget.value !== ' ') {
        this.cancelButtonTarget.classList.remove("btn-disabled");
        this.cancelButtonTarget.removeAttribute('disabled');
      } else {
        this.cancelButtonTarget.classList.add("btn-disabled");
        this.cancelButtonTarget.setAttribute('disabled', 'disabled');
      }
    }
}
