import { Controller } from "stimulus";

const LEAVING_PAGE_MESSAGE = "Your message hasn't been saved yet! \n\nTo save your message, cancel this dialog then click the create button to post your message to the conversation. If you navigate away from this page without posting or saving the message, your recording will be discarded."

export default class extends Controller {

  formIsChanged(event) {
    this.setChanged("true")
    var toggleEditTitle = document.querySelector("#conversation-title #toggle-edit-title");
    var uneditableTitle = document.querySelector("#conversation-title #uneditable-title");
    var conversationShare = document.querySelector("#conversation-share button");
    var conversationPeople = document.querySelector("#conversation-people .open-share-modal");
    var conversationSettingsDropdown = document.querySelector(".conversation-settings");
    var sidebar = document.querySelector(".sidebar");
    var topbar_links = document.querySelectorAll("#topbar a");

    if(toggleEditTitle) {
      toggleEditTitle.classList.add("hidden");
      uneditableTitle.classList.remove("hidden");
    } 
    if(conversationShare) {
      conversationShare.setAttribute("disabled", true);
      conversationShare.classList.add("opacity-40");
    } 
    if (conversationPeople) {
      conversationPeople.dataset.action = "";
    }
    if (conversationSettingsDropdown) {
      // remove 'dropdown' from data-controller attribute
      conversationSettingsDropdown.setAttribute('data-controller', '');
      conversationSettingsDropdown.classList.add("opacity-40");
    }

    var topbar = document.querySelector("#topbar")
    if(topbar) topbar.classList.add("opacity-50");
    if (topbar_links) {
      for (let topbar_link of topbar_links) {
        topbar_link.setAttribute("onclick", "event.preventDefault();");
      }
    }

    if (sidebar) {
      // fade it out
      sidebar.querySelector(".sidebar-inner-container").classList.add("opacity-40");
      
      // disable all sidebar links
      var sidebar_links = sidebar.querySelectorAll("a");
      for (let sidebar_link of sidebar_links) {
        sidebar_link.setAttribute("onclick", "event.preventDefault();");
      }

      // disable the 'new conversation' button in sidebar by removing the post method
      var sidebar_new_conv_link = sidebar.querySelector("a.sidebar-new-conversation-link");

      if (sidebar_new_conv_link) {
        sidebar_new_conv_link.setAttribute("data-method", "");
      }

      // disable the user-menu dropdown by removing 'dropdown' from data-controller 
      var user_menu = sidebar.querySelector(".dropdown.user-menu");

      if (user_menu) {
        user_menu.setAttribute("data-controller", "");
      }

      // disable the user-events-menu dropdown by removing 'dropdown' from data-controller 
      var user_events_menu = sidebar.querySelector(".user-events-menu.context-sidebar .dropdown");

      if (user_events_menu) {
        user_events_menu.setAttribute("data-controller", "");
      }
    }
  }

  leavingPage(event) {
    if (this.isFormChanged()) {
      if (event.type == "turbolinks:before-visit") {
        if (!window.confirm(LEAVING_PAGE_MESSAGE)) {
          event.preventDefault()
        }
      } else {
        event.returnValue = LEAVING_PAGE_MESSAGE;
        return event.returnValue;
      }
    }
  }

  allowFormSubmission(event) {
    this.setChanged("false")
    var toggleEditTitle = document.querySelector("#conversation-title #toggle-edit-title");
    var uneditableTitle = document.querySelector("#conversation-title #uneditable-title");
    var conversationShare = document.querySelector("#conversation-share button");
    var conversationPeople = document.querySelector("#conversation-people .open-share-modal");
    var conversationSettingsDropdown = document.querySelector(".conversation-settings");
    var sidebar = document.querySelector(".sidebar");
    var topbar_links = document.querySelectorAll("#topbar a");

    if(toggleEditTitle) {
      toggleEditTitle.classList.remove("hidden");
      uneditableTitle.classList.add("hidden");
    } 
    if(conversationShare) {
      conversationShare.removeAttribute("disabled");
      conversationShare.classList.remove("opacity-40");
    } 
    if (conversationPeople) {
      conversationPeople.dataset.action = "click->conversation--share-modal#open";
    }
    if (conversationSettingsDropdown) {
      // add 'dropdown' back to data-controller attribute
      conversationSettingsDropdown.setAttribute('data-controller', 'dropdown');
      conversationSettingsDropdown.classList.remove("opacity-40");
    }

    document.querySelector("#topbar").classList.remove("opacity-50");
    if (topbar_links) {
      for (let topbar_link of topbar_links) {
        topbar_link.removeAttribute("onclick");
      }
    }

    if (sidebar) {
      // fade it back in
      sidebar.querySelector(".sidebar-inner-container").classList.remove("opacity-40");
      
      // enable all sidebar links
      var sidebar_links = sidebar.querySelectorAll("a");
      for (let sidebar_link of sidebar_links) {
        sidebar_link.removeAttribute("onclick"); 
      }

      // disable the 'new conversation' button in sidebar by removing the post method
      var sidebar_new_conv_link = sidebar.querySelector("a.sidebar-new-conversation-link");
      if (sidebar_new_conv_link) {
        sidebar_new_conv_link.setAttribute("data-method", "post");
      }
       
      // enable the user-menu dropdown by add 'dropdown' to data-controller 
      var user_menu = sidebar.querySelector(".dropdown.user-menu");
      if (user_menu) {
        user_menu.setAttribute("data-controller", "dropdown");
      }

      // enable the user-events-menu dropdown by add 'dropdown' to data-controller 
      var user_events_menu = sidebar.querySelector(".user-events-menu.context-sidebar .dropdown");
      if (user_events_menu) {
        user_events_menu.setAttribute("data-controller", "dropdown");
      }
    }
  }

  setChanged(changed) {
    this.data.set("changed", changed)
  }

  isFormChanged() {
    return this.data.get("changed") == "true";
  }
}