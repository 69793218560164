import {Controller} from "stimulus";

export default class extends Controller {

    static targets = ["accountUserEmailSubject", "multiSelectHiddenField"]

    connect() {
        if (this.hasMultiSelectHiddenFieldTarget) {
            this.updateHiddenField();
        }
    }

    revealEmailSubjectField() {
        var notifyAccountUsers = document.querySelectorAll(".notify-account-users");
        var canDisplayField = false;
        if (notifyAccountUsers) {
            document.querySelectorAll(".notify-account-users input:checked").forEach(checkbox => {
                canDisplayField = true;
            });
            this.accountUserEmailSubjectTarget.classList.toggle("hidden", !canDisplayField);
        }
    }

    updateHiddenField(){
        const selectedOptions = this.element.querySelectorAll('input[type="checkbox"]:checked');
        const selectedValues = Array.from(selectedOptions).map(option => option.value);
        this.multiSelectHiddenFieldTarget.value = JSON.stringify(selectedValues);
    }
}