import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = [
    "selectedTimeInput",
    "selectTimeContainer",
    "selectedTimeContainer",
    "selectedDate",
    "selectedStartTime",
    "selectedEndTime",
    "selectedTimezone",
    "bookingQuestions",
    "submitButton",
    "answerField",
    "startDatetimeField",
    "endDatetimeField",
    "messageIdField",
    "appointmentChangeReasonField"
  ]

  selectTime(event) {
    var selectable_time = event.target.closest(".selectable-time");
    var availability_block_id = selectable_time.dataset.availabilityBlockId;
    var date = selectable_time.dataset.date;
    var start_time = selectable_time.dataset.startTime;
    var end_time = selectable_time.dataset.endTime;
    var timezone = selectable_time.dataset.timezone;
    var actual_start_datetime = selectable_time.dataset.actualStartDatetime;
    var actual_end_datetime = selectable_time.dataset.actualEndDatetime;
    var message_id = selectable_time.dataset.messageId;

    this.selectedTimeInputTarget.value = availability_block_id;

    this.selectedDateTarget.innerHTML = date;
    this.selectedStartTimeTarget.innerHTML = start_time;
    this.selectedEndTimeTarget.innerHTML = end_time;
    this.selectedTimezoneTarget.innerHTML = timezone;

    this.startDatetimeFieldTarget.value = actual_start_datetime;
    this.endDatetimeFieldTarget.value = actual_end_datetime;
    this.messageIdFieldTarget.value = message_id;

    this.selectTimeContainerTarget.classList.add("hidden");
    if (this.bookingQuestionsTarget.dataset.bookingQuestion === "true") {
      this.bookingQuestionsTarget.classList.remove("hidden");
      // Find the first input field within the booking questions and focus it
      const firstInput = this.bookingQuestionsTarget.querySelector('input.booking-question');
      if (firstInput) {
        firstInput.focus();
      }
    } else if(this.bookingQuestionsTarget.dataset.updateAppointment === "true") {
      this.appointmentChangeReasonFieldTarget.classList.remove('hidden')
    } else{
      this.selectedTimeContainerTarget.classList.remove("hidden");
    }
  }


  displaySubmitButton(){
    const requiredFields = this.answerFieldTargets.filter(field => field.getAttribute('data-required') === 'true');
    const allRequiredFieldsValid = requiredFields.every(field => field.value.trim() !== '');
    this.submitButtonTarget.disabled = !allRequiredFieldsValid;
    this.submitButtonTarget.classList.toggle('btn-disabled', !allRequiredFieldsValid);
  }

  displaySelectTimeContainer(event) {
    event.preventDefault()
    const update_appointment = event.target.dataset.updateAppointment === "true";
    console.log(' update appointment: ', update_appointment)
    if (update_appointment) {
      this.appointmentChangeReasonFieldTarget.classList.remove("hidden");
      this.bookingQuestionsTarget.classList.add("hidden");
    } else {
      this.selectedTimeContainerTarget.classList.remove("hidden");
      this.bookingQuestionsTarget.classList.add("hidden");
      this.appointmentChangeReasonFieldTarget.classList.add("hidden");
    }
  }
}
