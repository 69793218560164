// This handles applying the colors (the account's or the default colors) to the page on every page load.

import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = []

  static values = { 
    accountId: Number,
    accountColorsVersion: Number,
    respondent: Boolean
  }

  connect() {
    let account_id = this.accountIdValue;
    let account_colors_version = this.accountColorsVersionValue;
    let storedColorsString;
    let storedColorsVersion;
    let defaultColors;
    let respondent = this.respondentValue;

    if (account_id) {
      // We're viewing a view that is associated with an account

      storedColorsString = localStorage.getItem(`colors-${account_id}`);
      storedColorsVersion = localStorage.getItem(`colors-${account_id}-version`);

      if (storedColorsVersion) {
        // The user has visited this account before 
        // and that account has set their colors 
        // and those colors (and version) have been stored to this user's localstorage (but we don't yet know if this is the latest version of the account's colors)

        if (account_colors_version && account_colors_version == storedColorsVersion) {
          // The account colors version matches the stored colors version

          if (storedColorsString) {
            // If colors are still in this user's localstorage... 

            // Apply the colors that are currently in localstorage
            let colors_json = JSON.parse(storedColorsString);
            this.setCss(colors_json);

          } else {
            // The colors were somehow removed from localstorage

            // Fetch and apply this account's colors
            // This will also update localstorage values to match account's current values
            this.fetchAndApplyAccountColors(account_id);
          }

        } else {
          // The account colors version doesn't match the version in localstorage

          // Fetch and apply this account's colors
          // This will also update localstorage values to match account's current values
          this.fetchAndApplyAccountColors(account_id);
        }

      } else {
        // There is no version set in this user's localstorage for this account
        // That could mean this account has never set its colors (that would mean it's still using default colors)
        // Or that could mean the account has set its colors but this user has not seen them yet.

        if (account_colors_version) {
          // This account has saved at least 1 version of its colors to the database
          // But this user has not yet seen the accounts' saved colors

          // Fetch and apply this account's colors
          // This will also update localstorage values to match account's current values
          this.fetchAndApplyAccountColors(account_id);

        } else {
          // This account has not yet saved its colors (it's still using default colors)

          if (storedColorsString) {
            // The user has colors saved in localstorage for this account (likely the default colors, which were stored on this user's last visit)

            // Apply the colors that are currently in localstorage
            let colors_json = JSON.parse(storedColorsString);
            this.setCss(colors_json);

          } else {
            // The user has no localstorage records for this account

            // Apply the Clarityflow default colors
            defaultColors = this.getDefaultColors();
            this.setCss(defaultColors);

            // Store the default colors in localStorage
            this.setAccountLocalStorage(defaultColors);
          }

        }
      }

    } else {
      // We're viewing a non-account-specific view (like a login view or user profile view etc.)
      // The user is now on app.clarityflow.com (or a custom domain)

      if (respondent) {
        // User is a respondent

        // Check if the url param 'brand_id' is present in the url
        let urlParams = new URLSearchParams(window.location.search);
        let brand_id = urlParams.get('brand_id');
        let lastViewedAccountColors = localStorage.getItem('last-viewed-account-colors');

        if (brand_id) {
          // brand_id URL param is present

          // the account_id is hidden in the middle of the brand_id string.  Parse it and get the substring that is after '4123' and before '61424'
          let account_id = brand_id.substring(4, brand_id.length - 5);
          
          // Fetch the colors from the account_id, and set the 2nd value to true to indicate it's a respondent on a non-account view
          this.fetchAndApplyAccountColors(account_id, true);

        } else {
          if (lastViewedAccountColors) {
            // Apply that account's colors
            let colors_json = JSON.parse(lastViewedAccountColors);
            this.setCss(colors_json);

          } else {
            // Apply the Clarityflow default colors
            defaultColors = this.getDefaultColors();
            this.setCss(defaultColors);
          }
        }

      } else {
        // User is not a respondent

        // Apply the Clarityflow default colors
        defaultColors = this.getDefaultColors();
        this.setCss(defaultColors);
      }
    }
  }

  fetchAndApplyAccountColors(account_id, respondent=false) {
    fetch(`/accounts/${account_id}/colors.json`) 
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(colors_json => {
        // if colors_json is returned from this account's colors column in the database...
        if (colors_json) { // This account has custom colors saved
          
          // Apply this account's colors
          this.setCss(colors_json);

          if (respondent) {
            // user is a respondent viewing a non-account view, but was sent here after viewing an account view
            // so we need to store that account's colors into localstorage so that they can be applied when viewing home screen and other non-account views
            localStorage.setItem('last-viewed-account-colors', JSON.stringify(colors_json));
          } else {
            // Store the fetched colors for this account in localStorage
            // Also store this account's colors version
            this.setAccountLocalStorage(colors_json);
          }

        } else { // this account has no custom colors saved
          // Set the CSS variables using Clarityflow's default colors
          let defaultColors = this.getDefaultColors();
          this.setCss(defaultColors);

          if (!respondent) {
            // Store the default colors in localStorage
            this.setAccountLocalStorage(defaultColors);
          }
        }
      })
      .catch(error => {

        // Set the CSS variables using Clarityflow's default colors, then log the error
        let defaultColors = this.getDefaultColors();
        this.setCss(defaultColors);

        if (!respondent) {
          // Store the default colors in localStorage
          this.setAccountLocalStorage(defaultColors);
        }

        console.error('There was a problem fetching the colors:', error);
      });
  }

  setCss(colors_json) {

    // Create a style element, which will be filled...
    let style = document.createElement('style');
    let root = document.documentElement;

    // Loop through colors_json for each color. We match the color_id key value to the CSS variable name like this:  --${color_id}
    for (let color of colors_json) {
      let color_id = color.color_id;
      let rgb = color.rgb
      
      root.style.setProperty(`--${color_id}`, rgb);
      this.setStyle(style, 'background', color_id, rgb);
      this.setStyle(style, 'border', color_id, rgb);
      this.setStyle(style, 'text', color_id, rgb);
    }

    // Insert the style element at the bottom of the head
    document.head.appendChild(style);
  }

  setStyle(style_tag, style_type, color_id, rgb) {
    let style;

    if (style_type == "background") {
      style = `.bg-${color_id}, .hover-bg-${color_id}:hover, .group:hover .group-hover-bg-${color_id} { background-color: rgb(${rgb} / var(--tw-bg-opacity)); }`;
    } else if (style_type == "border") {
      style = `.border-${color_id}, .hover-border-${color_id}:hover, .group:hover .group-hover-border-${color_id} { border-color: rgb(${rgb} / var(--tw-border-opacity)); }`;
    } else if (style_type == "text") {
      style = `.text-${color_id}, .hover-text-${color_id}:hover, .group:hover .group-hover-text-${color_id} { color: rgb(${rgb} / var(--tw-text-opacity)); }`;
    }

    style_tag.insertAdjacentHTML("beforeend", style)
  }

  setAccountLocalStorage(colors_json) {
    let account_id = this.accountIdValue;
    let colors_version = this.accountColorsVersionValue;
    localStorage.setItem(`colors-${account_id}`, JSON.stringify(colors_json));
    if (colors_version) {
      localStorage.setItem(`colors-${account_id}-version`, colors_version);
    }
  }

  getDefaultColors() {
    // Find the meta tag by its name
    let metaTag = document.querySelector('meta[name="default-colors"]');

    // Get the content of the meta tag
    let content = metaTag.getAttribute('content');

    // Parse the content as JSON
    let colorArray = JSON.parse(content);

    return colorArray;
  }

}