import {Controller} from "stimulus";

export default class extends Controller {

    connect() {
        this.refreshCloudflareToken()
    }
    refreshCloudflareToken(){
        const turnstileElements = this.element.querySelectorAll(".cf-turnstile");
        turnstile.ready(function () {
            turnstileElements.forEach(element => {
                element.innerHTML = ""
                turnstile.render(element, {
                    sitekey: element.dataset.sitekey,
                    callback: function (token) {
                        console.log(`Challenge Success ${token}`);
                    }
                });
            });
        });
    }
}