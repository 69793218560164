import UploadError from "./upload_error";

export default class MultipartUpload {
  constructor(browserName) {
    this.upload_id;
    this.key;
    this.bucket;
    this.totalParts;
    this.partArray = [];
    this.partPromiseDict = [];
    this.partBasedProgress = false;
    this.totalPartsProgress = {};
    this.uploadDiscard = false;
    this.browserName = browserName;

    this.disableBtn = document.querySelector("#discardRecordingBtn");

    // Flow of multipart upload
    // 1 - Need to get upload ID by sending a create multipart trigger
    // 2 - After getting upload ID when part is ready Get Presigned URL(We can't use one persigned URL for all the part)
    // 3 - After getting the persigned URL upload part
    // 4 - Once all the parts are uploaded send complete multipart trigger
    // 5 - If there is any interruption or multiple issue in upload discard upload
  }

  get_key_and_upload_id() {
    return { key: this.key, uploadId: this.upload_id }
  }

  async upload(part, index, mimeType) {
    if(this.connectionOnline() === false || this.uploadDiscard === true) return false; // return false only if connectionOnline function returns false

    var response;
    response = this.uploadPart(part, index, mimeType, this.upload_id, this.key, this.bucket)
        .then( data => {
          if(data === false) throw "upload failed...";

          // get etag and index from response
          this.partArray.push({
            etag: data.etag,
            part_number: data.index
          });

          return true;
        })
        .catch((error) => {
          console.error('Error:', error);
          if(this.uploadDiscard === false) this.assignAndSendError(error, "InProgressPartUploadError");
          if(window.appsignal !== undefined) { var span = window.appsignal.createSpan();
            span.setParams({ account_id: accountId, bucket: this.bucket, key: this.key, upload_id: this.upload_id, parts: JSON.stringify(this.partArray)})
            span.setTags({
              tag: "Multipart Upload error"
            }).setError({name: "InProgressPartUploadError", message: error});
            window.appsignal.send(span);}
          return false;
        });

    this.partPromiseDict[index] = response;

    return await response;
  }

  async createMultipartUpload(type) {
    var params = new URLSearchParams({
      type: type
    })

    console.log("Request backend to create multipart upload...")
    var result = fetch(`/accounts/${accountId}/create_multipart_upload?${params}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
        .then(data => {
          var response = data.response;
          console.log("Got create multipart upload response.....")

          if(response !== false) {
            var jsonData = JSON.parse(data.response);

            // Assign needed valued to instance variables
            this.upload_id = jsonData.upload_id;
            this.key = jsonData.key;
            this.bucket = jsonData.bucket;
          }

          return response;
        });

    return result;
  }

  async uploadPart(part, index, mimeType, upload_id, key, bucket) {
    var params = new URLSearchParams({
      bucket: bucket,
      key: key,
      upload_id: upload_id,
      part_number: index
    })

    // For each upload we need a presigned url
    // Using fetch Api a request will be made to Backend with all the needed parameters

    return await fetch(`/accounts/${accountId}/generate_presigned_url?${params}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
        .then(data => {
          // Response will have Signed URL and it will be used to upload part
          var jsonData = JSON.parse(data.response)

          var xhr = new XMLHttpRequest(); // XHR will be used to upload part to track the upload progress

          return new Promise((resolve, reject) => {

            xhr.onreadystatechange = (e) => {
              if (xhr.readyState !== 4) {
                return;
              }

              if (xhr.status === 200) {
                // If the upload is successful then get the etag of the uploaded part
                resolve({index: index, etag: xhr.getResponseHeader("ETag")});
              } else {
                // If upload failed reject promise
                console.warn('Multipart upload failed');
                return reject(xhr.responseText || 'Multipart upload failed.....');
              }
            };

            xhr.upload.addEventListener('progress', event => {
              // Track progess of upload
              this.partUploadProgress(event, index)
            });

            // Abort request based on listening to button click event
            if(this.disableBtn) {
              this.disableBtn.addEventListener("click", (event) => {
                setTimeout(() => {
                  if(this.disableBtn.dataset.discardRecording === "true") {
                    xhr.abort();
                  }
                }, 1000);
              })
            }

            xhr.open('PUT', jsonData.url, true);
            xhr.send(part);
          })
        })
  }

  async completeUpload() {
    var etag;

    if(this.key === undefined || this.uploadDiscard === true || this.partPromiseDict.length === 0) {
      return
    }

    let resolvedArray = await Promise.all(Object.values(this.partPromiseDict)); // Get the response of all part promise

    return Promise.all(resolvedArray).then(async data => {
      // If all part promise are successful then send complete request
      // Params must have etag and part number of each part
      var params = new URLSearchParams({
        bucket: this.bucket,
        key: this.key,
        upload_id: this.upload_id,
        parts: JSON.stringify(this.partArray)
      })

      var result = fetch(`/accounts/${accountId}/complete_multipart_upload`,
          {
            method: "POST",
            body: params
          })
          .then(response => {
            // If completed request is successfully then get the etag from the response header
            etag = response.headers.get("ETag");
            return response.json()
          })
          .then(data => {
            // Send back response and etag
            return {response: JSON.parse(data.response), etag: etag };
          }).catch(err => {
            console.log(err)
            if(this.uploadDiscard === false) this.assignAndSendError(err, "CompletePartUploadError");
            if(window.appsignal !== undefined){ var span = window.appsignal.createSpan();
              span.setParams({ account_id: accountId, bucket: this.bucket, key: this.key, upload_id: this.upload_id, parts: JSON.stringify(this.partArray)})
              span.setTags({
                tag: "Multipart Upload error"
              }).setError({name: "CompletePartUploadError", message: err});
              window.appsignal.send(span);}
            return false;
          })
      return await result;
    }).catch(err => {
      console.log(err)
      if(this.uploadDiscard === false)  this.assignAndSendError(err, "InProgressPartUploadError");
      if(window.appsignal !== undefined) { var span = window.appsignal.createSpan();
        span.setParams({ account_id: accountId, bucket: this.bucket, key: this.key, upload_id: this.upload_id, parts: JSON.stringify(this.partArray)})
        span.setTags({
          tag: "Multipart Upload error"
        }).setError({name: "InProgressPartUploadError", message: err});
        window.appsignal.send(span);};
      return false;
    })
  }

  discardUpload(skipReload) {
    if(this.uploadDiscard === true) return; // return if discard is already called

    this.uploadDiscard = true;
    var params = new URLSearchParams({
      bucket: this.bucket,
      key: this.key,
      upload_id: this.upload_id
    })

    return fetch(`/accounts/${accountId}/abort_multipart_upload?${params}`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => response.json())
        .then(() => {
          if(skipReload === true) {
            let customProgressElements = document.querySelectorAll('.progressbar');

            Array.from(customProgressElements).forEach((customProgressElement) => {
              customProgressElement.style.width = "0%";
            })

            return ;
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          this.assignAndSendError(error, "AbortPartUploadError");
          if(window.appsignal !== undefined){ var span = window.appsignal.createSpan();
            span.setParams({ account_id: accountId, bucket: this.bucket, key: this.key, upload_id: this.upload_id })
            span.setTags({
              tag: "Multipart Upload error"
            }).setError({name: "AbortPartUploadError", message: error});
            window.appsignal.send(span);};
        });
  }

  updatePartProgress(totalParts) {
    this.totalParts = totalParts;
    this.partBasedProgress = true;
  }

  partUploadProgress(event, index) {
    let percent = ((event.loaded / event.total) * 100).toFixed(1);

    this.totalPartsProgress[index] = percent;

    if(this.partBasedProgress === true) {
      var completed_percentage = Object.values(this.totalPartsProgress).reduce((a, b) => parseInt(a) + parseInt(b), 0)
      percent = (completed_percentage / this.totalParts).toFixed(1);
    }

    let customProgressElements = document.querySelectorAll('.progressbar');

    if(customProgressElements){
      Array.from(customProgressElements).forEach((customProgressElement) => {
        if(percent === "100.0") {
          this.canShowSubmit = true;
        } else {
          if(!customProgressElement.classList.contains("hidden")){
            customProgressElement.classList.remove("hidden")
          }
        }

        // Update width of the progressbar based on the upload percentage
        customProgressElement.style.width = `${percent}%`;
      })
    }
  }

  connectionOnline() {
    if(navigator.onLine === false) return false; // return false if connection is offline

    return true; // return true if connection is online
  }

  assignAndSendError(error, errorName) {
    var additionalInfoHash = {
      browserName: this.browserName,
      errorName: errorName,
      uploadId: this.upload_id,
      key: this.key
    }

    window.uploadErrorInitializer.assignError(error, additionalInfoHash);
    window.uploadErrorInitializer.sendError();
  }

  get_account_id(){
    return { account_id: accountId }
  }

}