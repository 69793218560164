// HOW TO USE:
// ==============

// Top navigation tabs (_share_modal_nav.html.erb) use this markup:

// <span class="modal-nav-link ..." 
//       data-action="click->conversation--share-modal#navigate" 
//       data-panel-to-activate="link" 
//       data-tab-id="share">
//   ...
// </span>


// Sub nav tabs (examples: _share_subnav.html.erb, _people_subnav.html.erb _settings_subnav.html.erb) use this markup:

// <div class="share-modal-subnav ..." 
//    data-parent-id="share">
//
//   <span class="modal-subnav-link ..." 
//         data-action="click->conversation--share-modal#navigate" 
//         data-panel-to-activate="link"
//         data-subtab-id="link" 
//         data-parent-tab-id="share">
//     <span class="link-content"> 
//       ...
//     </span>
//   </span>
//
//   <span class="modal-subnav-link ..." 
//         data-action="click->conversation--share-modal#navigate" 
//         data-panel-to-activate="email"
//         data-subtab-id="email" 
//         data-parent-tab-id="share">
//     <span class="link-content"> 
//       ...
//     </span>
//   </span>
//
// </div>


// To place links on the page that both open the modal and navigate straight to a panel, use this markup:

// <button class="open-share-modal ..." 
//         data-action="click->conversation--share-modal#open" 
//         data-open-to="share">
//   ...
// </button>



import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['modal',
                    'modalBox',
                    'titleContainer',
                    'title',
                    'titleForm',
                    'tabNav',
                    'panel',
                    'refreshPageLink',
                    'addSubscribersToggle',
                    'addSubscribersForm']
  
  open(event) {
    var modal = this.modalTarget;
    modal.classList.remove("hidden");
    modal.classList.add("shown");

    var focus_title_field = event.target.closest(".open-share-modal").dataset.focusTitleField;
    if (focus_title_field) {
      this.showTitleForm();
    }

    var tab_id = '';
    var panel_id = null;

    var open_to = event.target.closest(".open-share-modal").dataset.openTo;
    if (open_to) {
      if (open_to == "settings") {
        this.activateSubTab("visibility_settings");
      } else if (open_to == "share") {
        this.activateSubTab("link");
      } else if (open_to == "people") {
        this.activateSubTab("conversation_users");
      } else if (open_to == "email") {
        this.activateSubTab("email");
      } else if (open_to == "workflows") {
        this.activateTab("workflows", null, false, true)
      }
    } else { // default to opening to the People panel
      this.activateSubTab("conversation_users");
    }
  }

  close() {
    this.modalTarget.classList.add("hidden");
  }

  navigate(event){ // called from the tab navs and subnav links within the share modal.
    var clickedTab = event.target.closest(".modal-nav-link");
    var clickedSubTab = event.target.closest(".modal-subnav-link");
    var triggeredSubTab = event.target.closest(".trigger-subnav-link"); // used to trigger .modal-subnav-link without applying the css styles that .modal-subnav-link applies.
    if (clickedTab) { // Clicked a top nav link
      var tab_id_to_activate = clickedTab.dataset.tabId;
      var panel_id_to_activate = clickedTab.dataset.panelToActivate;
      this.activateTab(tab_id_to_activate, panel_id_to_activate);
    } else if (clickedSubTab || triggeredSubTab) { // Clicked a sub nav link
      if (clickedSubTab) {
        var panel_id_to_activate = clickedSubTab.dataset.panelToActivate;
      } else if (triggeredSubTab) {
        var panel_id_to_activate = triggeredSubTab.dataset.panelToActivate;
      }
      this.activateSubTab(panel_id_to_activate);
    }
  }

  activateTab(tabId, panelId=null, activatingParent=false, click=false) { // called from navigate() as well as links on that page the will open the share modal to a specific panel.
    // Deactivate the currently active tab
    var active_tab = this.tabNavTarget.querySelector(".modal-nav-link.active");
    if (active_tab) {
      active_tab.classList.remove("active");
    }

    // Activate this tab
    var tab_to_activate = this.element.querySelector(".modal-nav-link[data-tab-id="+tabId+"]");
    tab_to_activate.classList.add("active");

    // Activate the panel, unless the sub-nav link already did that and we only wanted to activate its' parent tab
    if (!activatingParent) {
      if (!panelId) {
        var panelId = tabId;
        if (tabId == "workflows") {
          panelId = "all_workflows";
        }
      }
      this.activatePanel(panelId);
    }

    // if click is true, then simuluate clicking the tab (we do this when the tab is an ajax link)
    if (click) {
      tab_to_activate.click();
    }
  }

  activateSubTab(panelId) {
    var subtab_to_activate = this.element.querySelector(".modal-subnav .modal-subnav-link[data-subtab-id="+panelId+"]");
    if (subtab_to_activate) {
      var parentTabId = subtab_to_activate.dataset.parentTabId;

      // Deactivate the currently active sub tab
      var active_subtab = this.element.querySelector(".modal-subnav[data-parent-id='"+parentTabId+"'] .modal-subnav-link.active");
      if (active_subtab) {
        active_subtab.classList.remove("active");
      }

      // Activate this sub tab
      subtab_to_activate.classList.add("active");
    }

    // Activate this sub tab's parent tab
    if (parentTabId) {
      this.activateTab(parentTabId, null, true);
    }

    // Activate the panel
    this.activatePanel(panelId);
  }

  activatePanel(panelId) { // called from both activateTab() and activateSubTab()
    // Hide all panels
    for (let panel of this.panelTargets) {
      panel.classList.add("hidden");
    }

    // Unhide this panel
    var panel_to_activate = this.element.querySelector(".share-panel[data-panel-id='"+panelId+"']");
    panel_to_activate.classList.remove("hidden");
  }
  

  // Title form field

  showTitleForm() {
    this.titleTarget.classList.add("hidden", "md:hidden");
    this.titleFormTarget.classList.remove("hidden", "md:hidden");
    this.titleFormTarget.querySelector("input[type=text]").focus();
  }

  hideTitleForm(event) {
    if (event.key == "Tab" || this.titleContainerTarget.contains(event.target) === false) {
      this.titleTarget.classList.remove("hidden", "md:hidden");
      this.titleFormTarget.classList.add("hidden", "md:hidden");
      this.titleContainerTarget.querySelector(".autosave-icon-container").classList.add("hidden");
      this.titleContainerTarget.querySelector(".autosave-icon-container .error-icon").classList.add("hidden");
    }
  }


  showRefreshPageLink(event) {
    setTimeout(() => {
      this.refreshPageLinkTarget.classList.remove("hidden");
    }, 1000);
  }

  reloadPage() {
    window.location.reload();
  }

  toggleAddSubscribers() {
    if (this.addSubscribersFormTarget.classList.contains("hidden")) {
      this.addSubscribersToggleTarget.classList.add("hidden");
      this.addSubscribersFormTarget.classList.remove("hidden");
      this.addSubscribersFormTarget.querySelector("textarea").focus();

    } else {
      this.addSubscribersFormTarget.classList.add("hidden");
      this.addSubscribersToggleTarget.classList.remove("hidden");
    }
  }

}