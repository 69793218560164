import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['removeMe'];

    static values = { 
      autoclose: Boolean
    }

    connect() {
      if (this.autocloseValue == true) {
        this.timeout = setTimeout(() => {
          this.removeMeTarget.remove();
        }, 2000)
      }
    }

    close() {
      this.removeMeTarget.remove();
    }
}