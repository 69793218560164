import {Controller} from "stimulus";

export default class extends Controller {

    static targets = ["label", "fieldName", "optionSelectType"];

    connect() {
    }

    validateFieldName() {
        var labelValue = this.labelTarget.value.toLowerCase().replace(/\s/g, '_').replace(/[^\w]/g, '');
        this.fieldNameTarget.value = labelValue;
    }

    displayFields(event) {
        var selected_option = event.target.value;
        var toggleable = document.querySelector(".form-field-fields");
        if (toggleable) {
            var values = toggleable.dataset.toggleWhenSelected.split(' ');
            if (values.includes(selected_option)) {
                toggleable.classList.remove("hidden");
            } else {
                toggleable.classList.add("hidden");
            }
        }
        if (selected_option === 'selection') {
            this.revealSelectOptionsField();
        }
        this.resetSelectOptionsValue(event);

        // Focus on the label field
        var label_field = this.element.querySelector("input#form_field_label");
        if (label_field) {
            label_field.focus();
        }
    }

    revealSelectOptionsField() {
        var select = document.querySelector(".select-options-field");
        if (select) {
            if (this.optionSelectTypeTarget.value === 'selection_dropdown') {
                select.classList.remove("hidden");
            } else {
                select.classList.add("hidden");
            }
        }
    }

    resetSelectOptionsValue(event) {
        var selected_option = event.target.value;
        var selectOptionsContainer = document.querySelector(".select-options-container");
        var multiSelectOptionsContainer = document.querySelector(".select-options-container.multi-select");
        var selectOptionFields = selectOptionsContainer.querySelectorAll('.select-option-field');
        var multiSelectOptionFields = multiSelectOptionsContainer.querySelectorAll('.select-option-field');
        if (selected_option === 'selection') {
            if (multiSelectOptionFields) {
                if (multiSelectOptionFields.length > 1) {
                    for (let i = 1; i < multiSelectOptionFields.length; i++) {
                        multiSelectOptionFields[i].remove();
                    }
                }
                if (multiSelectOptionFields[0].querySelector("input[type='text']"))
                    multiSelectOptionFields[0].querySelector("input[type='text']").value = '';


            }

        } else if (selected_option === 'multi_select') {
            if (selectOptionFields) {
                if (selectOptionFields.length > 1) {
                    for (let i = 1; i < selectOptionFields.length; i++) {
                        selectOptionFields[i].remove();
                    }
                }
                if (selectOptionFields[0].querySelector("input[type='text']"))
                    selectOptionFields[0].querySelector("input[type='text']").value = '';
            }
        }
    }


    deleteSelectOption(event) {
        event.target.closest(".select-option-field").remove();
        this.updateSelectOptionOrderFields();
    }

    moveUpSelectOptions(event) {
        this.moveSelectOption(event, -1); // Move up by decreasing index
    }

    moveDownSelectOptions(event) {
        this.moveSelectOption(event, 1); // Move down by increasing index
    }


    moveSelectOption(event, direction) {
        var selectOptionFieldContainer = event.target.closest(".select-options-container");
        var selectOptionField = event.target.closest(".select-option-field");

        const allSelectOptionFields = Array.from(selectOptionFieldContainer.querySelectorAll(".select-option-field"));

        const currentIndex = allSelectOptionFields.indexOf(selectOptionField);

        if (currentIndex === -1) {
            return; // Stop if the field is not found
        }

        const newIndex = currentIndex + direction;

        if (newIndex < 0 || newIndex >= allSelectOptionFields.length) {
            return; // Stop if the item is already at the first or last position
        }

        const targetOption = allSelectOptionFields[newIndex];

        if (direction === -1) {
            selectOptionFieldContainer.insertBefore(selectOptionField, targetOption);
            this.updateSelectOptionOrderFields();
        } else {
            selectOptionFieldContainer.insertBefore(targetOption, selectOptionField);
            this.updateSelectOptionOrderFields();
        }
    }

    updateSelectOptionOrderFields() {
        const selectOptionFieldContainer = event.target.closest(".select-options-container");
        const allSelectOptionFields = Array.from(selectOptionFieldContainer.querySelectorAll(".select-option-field"));

        allSelectOptionFields.forEach((selectOptionField, index) => {
            const orderField = selectOptionField.querySelector(".select-option-order-field");

            if (orderField) {
                orderField.value = index + 1; // Assuming index starts from 1 for user display
            }
        });
    }


    updateDefault(event) {
        const selectOptionFieldContainer = event.target.closest(".select-options-container");
        const multiSelectOptionFieldContainer = event.target.closest(".select-options-container.multi-select");
        if (multiSelectOptionFieldContainer) return;
        var allSelectOptionFields = Array.from(selectOptionFieldContainer.querySelectorAll(".select-option-field"));
        allSelectOptionFields = allSelectOptionFields.filter((selectOptionField) => selectOptionField !== event.target.closest(".select-option-field"));
        allSelectOptionFields.forEach((selectOptionField) => {
            const toggleSwitch = selectOptionField.querySelector(".select-option-default-toggle");
            if (toggleSwitch) {
                toggleSwitch.querySelector('input').value = false;
                toggleSwitch.dataset.toggleSwitchActiveValue = false;
                toggleSwitch.classList.remove("active");
                toggleSwitch.querySelector("button").classList.remove('bg-primary-600')
                toggleSwitch.querySelector("button").classList.add('bg-gray-200')
            }

        });
    }
}
