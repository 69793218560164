import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['messageTemplatesFormContainer', 'overwriteMessageTemplatesField']

  open_message_template_form(event) {
    this.messageTemplatesFormContainerTarget.classList.remove("hidden");
  }

  submit_thread_template_form(event){
    this.overwriteMessageTemplatesFieldTarget.value = event.target.dataset.overwriteMessageTemplates
    Rails.fire(this.messageTemplatesFormContainerTarget.querySelector("form"), 'submit')
  }
}
