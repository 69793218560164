import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['multiSelectLink'];

  toggle(event) {
    var multi_select_link = event.target.closest(".multi-select-link");
    if (multi_select_link.classList.contains("multi-select-link-active")) {
      multi_select_link.classList.remove("multi-select-link-active");
    } else {
      multi_select_link.classList.add("multi-select-link-active");
    }

    var active_links = this.element.querySelectorAll('.multi-select-link.multi-select-link-active');
    if (active_links.length >= 1) {
      this.element.classList.add("multi-select-links-active");
    } else {
      this.element.classList.remove("multi-select-links-active");
    }
  }
    
}