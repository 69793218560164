import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['field', 
                    'privateField'
                    ]

  connect() {
    var selected_field = this.element.querySelector("input[type='radio']:checked");
    if (selected_field) {
      selected_field.closest(".radio-field").classList.add("active");
    }
  }

  toggle(event) {
    var container = event.target.closest(".radio-field");
    var label = event.target.closest("label");
    var input = label.querySelector("input");

    if (!input.checked) {
      container.classList.remove("active");
      input.click();
    } 
  }
  

}