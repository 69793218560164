// This controller manages misc elements in the message creation UI
// This controller is set on the form element.
// This does not manage the kickoff panel elements, as those are managed in the dedicated controller, kickoff_controller.js.

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["messageTypeField",
                    "moreMenu",
                    "toggleThumbnailUploader", "thumbnailUploader",
                    "selectCameraLayout",
                    "preview", 
                    "backgroundImage",
                    "cameraStatusBtn", 
                    "pipBtn", 
                    "internalBtnTop",
                    "internalPopoverMsgMakeInternal",
                    "internalPopoverMsgMakeVisible",
                    "internalSetting",
                    "initiateDeviceSelectionBtn",
                    "camMicSelectors",
                    "camMicSelectorsContainer",
                    "selectVideoSource", 
                    "selectAudioSource",
                    "micButton",
                    "cameraButton", 
                    "messageTitle", 
                    "messageTimestamp",
                    "mobileAppCta"]

  connect() {

    // Browser detection
    const userAgent = navigator.userAgent.toLowerCase();


    let isSafari = false;
    let isFirefox = false;
    let isChrome = false;
    let safari_version;
    let isIos = false;
    let isAndroid = false;
    let isWindowsPhone = false;

    // Detect Windows Phone
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      isWindowsPhone = true;
    }

    // Detect Android device (phones, tablets)
    if (/android/i.test(userAgent)) {
      isAndroid = true;
    }

    // Detect iOS devices (iphone, ipad, ipod)
    if (/ipad|iphone|ipod/.test(userAgent) && !window.MSStream) {
      isIos = true;
    }

    // Detect Safari browser and Safari version
    if (userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1) {
      isSafari = true;
      safari_version = userAgent.substring(
          userAgent.indexOf('version')+8,
          userAgent.indexOf('version')+12
      );
    }

    // Detect Firefox browser
    if(userAgent.indexOf('firefox') > -1){
      isFirefox = true;
    }

    // Detect Chrome browser
    if(userAgent.indexOf('chrome') > -1){
      isChrome = true;
    }

    // Check to see if we're currently on a mobile-sized screen (< 640px wide)
    function mobile_screen() {
      var mq = window.matchMedia( "(max-width: 640px)" );
      if (mq.matches) {
        return true;
      } else {
        return false
      }
    }

    // If we're on an iOS or Android device, show the mobile app CTA link
    if (isIos || isAndroid) {
      // get localstorage value for dismissed
      var mobile_app_cta_dismissed = localStorage.getItem('dismissed_message_mobile_app_cta');

      if (!mobile_app_cta_dismissed && this.hasMobileAppCtaTarget) {
        var mobile_app_cta = document.querySelector('.mobile-app-cta')
        var mobile_app_link = mobile_app_cta.querySelector("a.mobile-app-link");
        if (isAndroid) {
          mobile_app_link.href = mobile_app_cta.dataset.androidAppStoreUrl;
        } else { // assume iOS
          mobile_app_link.href = mobile_app_cta.dataset.iosAppStoreUrl;
        }
        mobile_app_cta.classList.remove("hidden");
      }
    }

    // If we're on a mobile screen...
    if (mobile_screen()) {
      // Force the ui to the uploader panel and hide the button to switch to recorder:

      // Apply mobile layout (linear) to the media panel toggles, since we'll be removing one of them:
      this.element.querySelector(".media-panel-toggles").classList.add("linear-layout");
      this.element.querySelector(".media-panel-toggles").classList.remove("grid-layout");

      // Click the upload media panel toggle
      this.element.querySelector(".media-panel-toggle[data-message-type-id='upload']").click();

      // Hide the record panel toggle since that option shouldn't be available on mobile
      this.element.querySelector(".media-panel-toggle[data-message-type-id='record']").classList.add("hidden");

    } else {
      if (isSafari && (safari_version < 14.1)) {
        this.element.querySelector(".safari-uploader-message").classList.remove("hidden");
        this.element.querySelector(".switch-to-browser-recorder").classList.add("hidden");
      } else {
        // Hide the text for safari <14.1 versions
        var safari_uploader_message = this.element.querySelector(".safari-uploader-message");
        if (safari_uploader_message) {
          safari_uploader_message.classList.add("hidden");
        }
      }
    }

    if (isFirefox && this.element.querySelector(".plusCameraLabel") !== null) {
      // Don't show "+ camera" in the context of "screen + camera" since FF doesn't support screen+camera
      this.element.querySelector(".plusCameraLabel").classList.add("hidden");
    }

    if (isSafari && (safari_version > 14.0)) {
      // Swap the state of pip buttons on Safari, to force user to have to click the button to turn on pip (Safari cannot launch pip automatically)
      if (this.hasCameraStatusBtnTarget) {
        this.cameraStatusBtnTarget.querySelector(".cameraShownLabel").classList.add("hidden");
        this.cameraStatusBtnTarget.querySelector(".cameraHiddenLabel").classList.remove("hidden");
      }
      if (this.hasPipBtnTarget) {
        this.pipBtnTarget.querySelector(".pipShownLabel").classList.add("hidden");
        this.pipBtnTarget.querySelector(".pipHiddenLabel").classList.remove("hidden");
      }
    }

    if (!isChrome && this.hasBackgroundImageTarget) {
      // Unless we're in Chrome, remove the background image button from DOM 
      this.backgroundImageTarget.remove();
    }

  }

  toggleThumbnailUploader(event) {

    if (this.thumbnailUploaderTarget.classList.contains("hidden")) {
      this.thumbnailUploaderTarget.classList.remove("hidden");
    } else {
      this.thumbnailUploaderTarget.classList.add("hidden");
    }

    // Scroll down to the thumbnail uploader
    this.thumbnailUploaderTarget.scrollIntoView({behavior: "smooth", block: "center"});
  }

  toggleCameraLayout(event) {
    // Activate the layout icon in the settings panel
    var label_element = event.target.closest("label");
    for (let layout of this.selectCameraLayoutTarget.querySelectorAll("label")) {
      layout.classList.remove("active");
    }
    label_element.classList.add("active");

    // Activate the layout icon in the 'recording in progress' panel
    var selected_layout = label_element.dataset.layout;
    for (let layout_icon of label_element.querySelectorAll(".selected-camera-layout")) {
      layout_icon.classList.add("hidden");
    }
    label_element.querySelector(".selected-camera-layout[data-layout='"+selected_layout+"'");
  }

  toggledCamera() {
    var initiateDeviceSelect = this.initiateDeviceSelectionBtnTarget;
    var selectCamera = this.selectVideoSourceTarget;
    var message_type = this.messageTypeFieldTarget.value;

    if ((message_type == "screencamera" || message_type == "camera") && initiateDeviceSelect.classList.contains("sm:hidden")) {
      selectCamera.classList.remove("hidden"); // Show the camera selection dropdown
    } else {
      selectCamera.classList.add("hidden");
    }

    this.toggleSelectDevice();
  }

  toggledMic() {
    var initiateDeviceSelect = this.initiateDeviceSelectionBtnTarget;
    var selectMic = this.selectAudioSourceTarget;
    var mic_button = this.micButtonTarget;

    if (mic_button.classList.contains("active") && initiateDeviceSelect.classList.contains("sm:hidden")) {
      selectMic.classList.remove("hidden"); // Show the mic selection dropdown
    } else {
      selectMic.classList.add("hidden");
    }

    this.toggleSelectDevice();
  }

  toggleSelectDevice() {
    var selectDeviceBtn = document.querySelector('div[data-message--creation-target="selectAudioSource"]').closest('.new-msg-buttons')

    if (this.cameraButtonTarget.classList.contains("active") && this.micButtonTarget.classList.contains("active")) {
      this.camMicSelectorsContainerTarget.classList.add("space-x-3");
    } else {
      this.camMicSelectorsContainerTarget.classList.remove("space-x-3");
    }

    if (!this.cameraButtonTarget.classList.contains("active") && !this.micButtonTarget.classList.contains("active")) {
      this.camMicSelectorsTarget.classList.add("hidden");
    } else {
      this.camMicSelectorsTarget.classList.remove("hidden");
    }
  }

  toggleInternal() {
    var internal_setting = this.internalSettingTargets;
    if (this.element.classList.contains("internal-message")) {
      this.element.classList.remove("internal-message");
      this.internalBtnTopTarget.classList.remove("active");
      internal_setting[internal_setting.length - 1].value = false
    } else {
      this.element.classList.add("internal-message");
      this.internalBtnTopTarget.classList.add("active");
      internal_setting[internal_setting.length - 1].value = true
    }
  }

  editTitle() {
    this.messageTitleTarget.querySelector(".message-author").classList.add("hidden");
    if (this.hasMessageTimestampTarget) {
      this.messageTimestampTarget.querySelector(".message-author").classList.remove("hidden");
    }
    this.messageTitleTarget.querySelector(".toggle-edit-title").classList.remove("group-hover:inline-flex");
    this.messageTitleTarget.querySelector(".message-title-field").classList.remove("hidden");
    this.messageTitleTarget.querySelector(".message-title-value").classList.add("hidden");
    this.messageTitleTarget.querySelector(".message-title-field").querySelector("input[type='text']").focus();
  }

  finishEditingTitle() {
    this.messageTitleTarget.querySelector(".toggle-edit-title").classList.add("group-hover:inline-flex");
    this.messageTitleTarget.querySelector(".message-title-field").classList.add("hidden");
  }

  saveTitle() {
    var title_value = this.messageTitleTarget.querySelector(".message-title-field").querySelector("input[type='text']").value;
    if(title_value.trim().length > 0) {
      this.messageTitleTarget.querySelector(".message-author").classList.add('hidden');
      this.messageTitleTarget.querySelector(".message-title-value").innerHTML = title_value;
      if (this.hasMessageTimestampTarget) {
        this.messageTimestampTarget.querySelector(".message-author").classList.remove("hidden");
      }
    } else {
      this.messageTitleTarget.querySelector(".message-author").classList.remove('hidden');
      this.messageTitleTarget.querySelector(".message-title-value").innerHTML = '';
      if (this.hasMessageTimestampTarget) {
        this.messageTimestampTarget.querySelector(".message-author").classList.add("hidden");
      }
    }
    this.messageTitleTarget.querySelector(".message-title-value").classList.remove("hidden");
  }

  clearTitle() {
    var titleInput = this.messageTitleTarget.querySelector(".message-title-field").querySelector("input#message_title") ||
    this.messageTitleTarget.querySelector(".message-title-field").querySelector("input#message_template_message_title")
    titleInput.value = "";
    this.messageTitleTarget.querySelector(".message-author").classList.remove("hidden");
    if(this.hasMessageTimestampTarget)
      this.messageTimestampTarget.querySelector(".message-author").classList.add("hidden");
    this.messageTitleTarget.querySelector(".message-title-value").innerHTML = "";
    this.messageTitleTarget.querySelector(".message-title-value").classList.add("hidden");
  }

  dismissMobileAppCTA() {
    this.mobileAppCtaTarget.classList.add("hidden");
    localStorage.setItem('dismissed_message_mobile_app_cta', true);
  }

}