// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

// AppSignal
import Appsignal from "@appsignal/javascript"
import { plugin } from "@appsignal/plugin-window-events"

const application = Application.start()

const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

try {
  if(process.env.APPSIGNAL_FRONTEND_KEY) {
    window.appsignal = new Appsignal({
      key: process.env.APPSIGNAL_FRONTEND_KEY
    })

    const options = {
      onerror: true,
      onunhandledrejection: true
    };

    window.appsignal.use(plugin(options));
  }
} catch (e) {
  console.log(e)
}