// Used when you want a specific selection option(s) to toggle show/hide of another div

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['toggleMe']
  static values = {
    focusInput: Boolean
  }

  connect() {
  }

  toggle(event) {
    this.revealOrHideInputs(event.target.value, document.querySelectorAll(".dynamic-on-select"), this.focusInputValue)
  }


  revealOrHideInputs(selected_option, toggleables, focus_input = true) {
    for (let toggleable of toggleables) {
      if (selected_option == toggleable.dataset.toggleWhenSelected) {
        toggleable.classList.remove("hidden");
        var input = toggleable.querySelector("input")
        if (input && focus_input) {
          input.focus();
        }
      } else {
        toggleable.classList.add("hidden");
      }
    }
  }
}
