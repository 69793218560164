import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [""];

  connect() {

  }

  toggleSteps(event) {
    var workflow_run = event.target.closest(".workflow-run");
    var step_jobs = workflow_run.querySelector(".step-jobs");

    if (step_jobs.classList.contains("hidden")) {
      step_jobs.classList.remove("hidden");
    } else {
      step_jobs.classList.add("hidden");
    }
  }

}