import {Controller} from 'stimulus'

export default class extends Controller {

  static targets = ['searchContactsInput',
                    'newInviteLinkContainer',
                    'newInviteLink',
                    'invitingTeammemberField']

  static values = { 
    canInviteTeamMembers: Boolean,
    atTeamMemberLimit: Boolean
  }

  updateNewInvitationLinkUrl(event) {
    var search_contacts_input = this.searchContactsInputTarget;
    var new_invite_link = this.newInviteLinkTarget;
    var input_value_encoded = encodeURIComponent(search_contacts_input.value);
    var new_invite_link_url = new_invite_link.href;

    // if new_invite_link_url has a query parameter input_value, then update that parameter with the value of the search query.
    // Otherwise add it.
    if (new_invite_link_url.includes("?")) {
      var new_invite_link_url_no_query_string = new_invite_link_url.split("?")[0];
      var new_invite_link_url_query_string = new_invite_link_url.split("?")[1];
      var new_invite_link_url_query_params = new_invite_link_url_query_string.split("&");
      var new_invite_link_url_query_params_without_input_value = new_invite_link_url_query_params.filter(param => !param.includes("input_value="));
      new_invite_link_url_query_params_without_input_value.push("input_value=" + input_value_encoded);
      new_invite_link.href = new_invite_link_url_no_query_string + "?" + new_invite_link_url_query_params_without_input_value.join("&");
    } else {
      new_invite_link.href = new_invite_link_url+ "?input_value=" + input_value_encoded;
    }
  }

  toggleInviteeType(event) {
    var radios_group = event.target.closest('.radios-group');
    var guest_radio = radios_group.querySelector('.radio-field[data-value="guest"]');
    var team_member_radio = radios_group.querySelector('.radio-field[data-value="team_member"]');
    var invitee_type = event.target.closest('.radio-field').dataset.value;
    var inviting_teammember_field = this.invitingTeammemberFieldTarget;

    if (invitee_type == "team_member") {
      if (this.canInviteTeamMembersValue == false) {
        this.element.querySelector("#cant-invite-team-members").classList.remove("hidden");
      } else {
        if (this.atTeamMemberLimitValue == true) {
          this.element.querySelector("#team-member-cost-alert").classList.remove("hidden");
        }
        inviting_teammember_field.value = "true";
        team_member_radio.classList.add('active');
        guest_radio.classList.remove('active');
      }
    } else {
      inviting_teammember_field.value = "false";
      guest_radio.classList.add('active');
      team_member_radio.classList.remove('active');
    }
  }

  initiateNewInvitation(event) {
    if (event.keyCode == 13) { // return key code is 13.  Only run if that was pressed.

      // Only proceed if the newInviteLink is currently visible (means there are no search results and user has inputted name or email of a new person to invite)
      var new_invite_link_container = this.newInviteLinkContainerTarget;
      if (!new_invite_link_container.classList.contains('hidden')) {
        this.newInviteLinkTarget.click();
      }
    }
  }

}