// Manages JS for the manage Commerce subscription modal

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
  ];

  static values = {
  }

  connect() {
  }

}