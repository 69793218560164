// This handles the selection of a theme, which dictates the style selection for each color group.

import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = ['selectedThemeList', 'styleSelection', 'surfacesStyleSelection']

  static values = { 
    accountId: Number
  }

  connect() {
    // If there is a selected theme in localstorage, select it
    var selected_theme = localStorage.getItem("selected_theme_"+this.accountIdValue);
    if (selected_theme) {
      // Set the selected theme to the clicked option
      var selected_theme_list = this.selectedThemeListTarget;
      var selected_theme_list_items = selected_theme_list.querySelectorAll(".theme");
      for (let theme of selected_theme_list_items) {
        if (theme.dataset.themeName == selected_theme) {
          theme.classList.remove("hidden");
        } else {
          theme.classList.add("hidden");
        }
      }
      if (selected_theme == "customized") {
        selected_theme_list.querySelector(".customized-theme-selection").classList.remove("hidden");
      }
    }
  }

  select(event) {
    var theme_name = event.target.closest(".theme").dataset.themeName;
    var selected_theme_list = this.selectedThemeListTarget;
    var selected_theme_list_items = selected_theme_list.querySelectorAll(".theme");

    // Set the selected theme to the clicked option
    for (let theme of selected_theme_list_items) {
      if (theme.dataset.themeName == theme_name) {
        theme.classList.remove("hidden");
      } else {
        theme.classList.add("hidden");
      }
    }

    // If the .customized-selection element is present and shown, hide it
    var customized_theme_selection = selected_theme_list.querySelector(".customized-theme-selection");
    if (customized_theme_selection && customized_theme_selection.classList.contains("hidden") == false) {
      customized_theme_selection.classList.add("hidden");
    }

    // reset all color groups to the style selector (hide the color pickers)
    let color_groups = document.querySelectorAll(".color-group");
    for (let color_group of color_groups) {
      // reset to style selector mode
      let style_selection = color_group.querySelector(".style-selection");
      // set the data-input-generated attribute to true on style_selection element
      style_selection.setAttribute('data-input-generated', 'true');
      if (style_selection.classList.contains("hidden")) {
        let toggle_link = color_group.querySelector(".styles-colorpickers-toggle");
        toggle_link.click();
      }
    }

    // Select this theme's style for each color group
    var style_selections = this.styleSelectionTargets;
    for (let style_selection of style_selections) {
      var theme_option = style_selection.querySelector(".dropdown-menu .style-"+theme_name);
      if (theme_option) {
        theme_option.click();
      }
    }

    // Select this theme's surfaces style
    var surfaces_style_selection = this.surfacesStyleSelectionTarget;
    var surface_styles = surfaces_style_selection.querySelectorAll(".dropdown-menu .style");
    for (let surface_style of surface_styles) {
      // If surface_style.dataset.themesForSurfaceStyle contains the selected theme name click it
      if (surface_style.dataset.themesForSurfaceStyle.includes(theme_name)) {
        surface_style.click();
      }
    }

    // store the selected theme name in localstorage
    localStorage.setItem("selected_theme_"+this.accountIdValue, theme_name);
  }

}