// This handles saving color settings and storing them in localstorage

import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = ['colorsField', 
                    'colorField', 

                    'toggleSelectTheme', 
                    'toggleCustomColors',
                    'selectTheme',
                    'customColors']

  static values = { 
    accountId: Number,
    modeActiveClasses: String,
    modeInactiveClasses: String
  }

  connect() {
    let default_colors_json = this.getDefaultColors(); 
    let stored_colors_string = localStorage.getItem(`colors-${accountId}`); 
    let colors_field = this.colorsFieldTarget;

    // Set the value of the hidden colors field to the current colors
    if (stored_colors_string) {
      colors_field.value = stored_colors_string;
    } else {
      colors_field.value = JSON.stringify(default_colors_json);
    }

    // Using the value that's been saved to colors_field, set the colors_string and colors_json variables
    let colors_string = colors_field.value;
    let colors_json = JSON.parse(colors_string);

    this.updateAllColors(colors_json);
  }

  toggleMode() {
    let toggleSelectTheme = this.toggleSelectThemeTarget;
    let toggleCustomColors = this.toggleCustomColorsTarget;
    let selectTheme = this.selectThemeTarget;
    let customColors = this.customColorsTarget;

    // Split the active and inactive classes strings into arrays
    let activeToggleClasses = this.modeActiveClassesValue;
    let activeToggleClassesArray = activeToggleClasses.split(" ");
    let inactiveToggleClasses = this.modeInactiveClassesValue;
    let inactiveToggleClassesArray = inactiveToggleClasses.split(" ");


    if (selectTheme.classList.contains("hidden")) {
      toggleSelectTheme.classList.add(...activeToggleClassesArray);
      toggleSelectTheme.classList.remove(...inactiveToggleClassesArray);
      toggleCustomColors.classList.remove(...activeToggleClassesArray);
      toggleCustomColors.classList.add(...inactiveToggleClassesArray);
      selectTheme.classList.remove("hidden");
      customColors.classList.add("hidden");
    } else {
      toggleSelectTheme.classList.remove(...activeToggleClassesArray);
      toggleSelectTheme.classList.add(...inactiveToggleClassesArray);
      toggleCustomColors.classList.add(...activeToggleClassesArray);
      toggleCustomColors.classList.remove(...inactiveToggleClassesArray);
      selectTheme.classList.add("hidden");
      customColors.classList.remove("hidden");
    }
  }

  updateColor(event) {
    let colors_field = this.colorsFieldTarget;
    let colors_json = JSON.parse(colors_field.value);
    let color_picker_container = event.target.closest(".color-picker");
    let color_id = color_picker_container.dataset.colorId;
    let new_hex_value = color_picker_container.dataset.hexValue;
    let new_rgb_value = color_picker_container.dataset.rgbValue;

    // find the color with the matching color_id and update its hex and rgb properties
    let color = colors_json.find(c => c.color_id === color_id);
    if (color) {
      color.hex = new_hex_value;
      color.rgb = new_rgb_value;
    }

    colors_field.value = JSON.stringify(colors_json);
  }

  updateAllColors(colors_json) {
    for (let color_field of this.colorFieldTargets) {
      let color_id = color_field.dataset.colorId;
      let hex_field = color_field.querySelector(".hex-color-field");
      let colorpicker_field = color_field.querySelector(".colorpicker-field");

      // Find the object in colors_json that has the matching color_id
      let colorObject = colors_json.find(color => color.color_id === color_id);

      if (colorObject) {
        // If a matching color was found, update the fields
        hex_field.value = colorObject.hex;
        colorpicker_field.value = '#' + colorObject.hex;

        // Trigger change events manually
        hex_field.dispatchEvent(new Event('change'));
        colorpicker_field.dispatchEvent(new Event('change'));
      } else {
        console.log(`No color found with id ${color_id}`);
      }
    }
  }

  getDefaultColors() {
    // Find the meta tag by its name
    let metaTag = document.querySelector('meta[name="default-colors"]');

    // Get the content of the meta tag
    let content = metaTag.getAttribute('content');

    // Parse the content as JSON
    let colorArray = JSON.parse(content);

    return colorArray;
  }

}