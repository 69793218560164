import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['invitationsToggle',
                    'invitationsHiddenField',
                    'toEmailsInput',
                    'toEmailError',
                    'toEmailsHiddenField',
                    'fromNameInput',
                    'fromNameHiddenField',
                    'replyToInput',
                    'replyToHiddenField',
                    'subjectInput', 
                    'subjectHiddenField',
                    'messageInput', 
                    'messageHiddenField',
                    'messageCopyableContent',
                    'bottomMessageInput', 
                    'bottomMessageHiddenField', 
                    'bottomMessageCopyableContent', 

                    
                    'showThumbnailInEmail',
                    'hideThumbnailInEmail',
                    'thumbnailProcessing',
                    'thumbnailInEmailHiddenField',
                    'thumbnailCopyableContent',

                    'buttonRender',
                    'buttonEditor',
                    'buttonTypeInput',
                    'buttonTypeHiddenField',
                    'buttonTextInput',
                    'buttonTextHiddenField',
                    'buttonTextCopyableContent',
                    'renderedButton',
                    'renderedTextLink',
                    'renderedUrlLink',

                    'conversationInviteForm', 

                    'emailComposer',
                    'emailSent',
                    'copyableContent'
                    ]

  static values = { 
    conversationUrl: String
  }

  connect() {
    this.timer = 0;

    // This ensures the copyable content has proper formatting by default
    if(this.hasMessageCopyableContentTarget && this.hasMessageInputTarget)
      this.messageCopyableContentTarget.innerHTML = this.messageInputTarget.value;

    if(this.hasBottomMessageCopyableContentTarget && this.hasBottomMessageInputTarget)
      this.bottomMessageCopyableContentTarget.innerHTML = this.bottomMessageInputTarget.value;
  }

  // Email fields (to, reply-to, subject, message, bottom_message)

  updateInvitations() {
    this.delay().then( () => {
      this.invitationsHiddenFieldTarget.value = this.invitationsToggleTarget.value;
    })
  }

  updateFromName() {
    this.delay().then( () => {
      this.fromNameHiddenFieldTarget.value = this.fromNameInputTarget.value;
    })
  }

  updateToEmails() {
    this.delay().then( () => {
      this.toEmailsHiddenFieldTarget.value = this.toEmailsInputTarget.value;
    })
  }

  updateReplyTo() {
    this.delay().then( () => {
      this.replyToHiddenFieldTarget.value = this.replyToInputTarget.value;
    })
  }

  updateSubject() {
    this.delay().then( () => {
      this.subjectHiddenFieldTarget.value = this.subjectInputTarget.value;
    })
  }

  updateMessage() {
    this.delay().then( () => {
      this.messageHiddenFieldTarget.value = this.messageInputTarget.value;
      if(this.hasMessageCopyableContentTarget)
        this.messageCopyableContentTarget.innerHTML = this.messageInputTarget.value;
    })
  }

  updateBottomMessage() {
    this.delay().then( () => {
      this.bottomMessageHiddenFieldTarget.value = this.bottomMessageInputTarget.value;
      this.bottomMessageCopyableContentTarget.innerHTML = this.bottomMessageInputTarget.value;
    })
  }


  // Thumbnail in email composer:
  // These actions only toggle whether or not to include thumbnail in the composed email.
  // The logic for toggling beween presenting and changing the thumbnail is in conversation--thumbnail

  hideThumbnailInEmail() {
    // Toggles OFF the option show the thumbnail in the email.
    
    // Set the thumbnailInEmail field value and toggle what the user sees 
    this.thumbnailInEmailHiddenFieldTarget.value = "false";
    this.hideThumbnailInEmailTarget.classList.remove("hidden");
    this.showThumbnailInEmailTarget.classList.add("hidden");

    // Remove the thumbnail from the content for copy to clipboard by moving it outside of that copyable div, but still inside the DOM so we can move it back into the copyable div, if user wants.
    this.copyableContentTarget.insertAdjacentElement("beforebegin", this.thumbnailCopyableContentTarget);
    this.thumbnailCopyableContentTarget.classList.add("hidden");
  }

  showThumbnailInEmail() {
    // Toggles ON the option show the thumbnail in the email.

    // Set the thumbnailInEmail field value and toggle what the user sees
    this.thumbnailInEmailHiddenFieldTarget.value = "true";
    this.hideThumbnailInEmailTarget.classList.add("hidden");
    this.showThumbnailInEmailTarget.classList.remove("hidden");

    // Add thumbnail back into the content for copy to clipboard.
    this.messageCopyableContentTarget.insertAdjacentElement("afterend", this.thumbnailCopyableContentTarget);
    this.thumbnailCopyableContentTarget.classList.remove("hidden");
  }


  // Call-to-action (CTA) button in email composer:

  toggleButtonEditor() {
    if (this.buttonRenderTarget.classList.contains("hidden")) {
      this.buttonRenderTarget.classList.remove("hidden");
      this.buttonEditorTarget.classList.add("hidden");
    } else {
      this.buttonRenderTarget.classList.add("hidden");
      this.buttonEditorTarget.classList.remove("hidden");
    }
  }

  changeLinkType(event) {
    if (event.target.value == 'url') {
      this.buttonTextInputTarget.classList.add("hidden");

      this.renderedButtonTarget.classList.add("hidden");
      this.renderedTextLinkTarget.classList.add("hidden");
      this.renderedUrlLinkTarget.classList.remove("hidden");
      this.buttonTextHiddenFieldTarget.value = this.conversationUrlValue;

    } else if (event.target.value == 'text_link') {

      this.buttonTextInputTarget.classList.remove("hidden");
      this.buttonTextInputTarget.querySelector("label").innerHTML = "Link Text";

      this.renderedButtonTarget.classList.add("hidden");
      this.renderedTextLinkTarget.classList.remove("hidden");
      this.renderedUrlLinkTarget.classList.add("hidden");

    } else { // 'button'

      this.buttonTextInputTarget.classList.remove("hidden");
      this.buttonTextInputTarget.querySelector("label").innerHTML = "Button Text";

      this.renderedButtonTarget.classList.remove("hidden");
      this.renderedTextLinkTarget.classList.add("hidden");
      this.renderedUrlLinkTarget.classList.add("hidden");
    }
    this.buttonTypeHiddenFieldTarget.value = this.buttonTypeInputTarget.querySelector("select").value;
  }

  updateButtonText() {
    this.delay().then( () => {
      this.bottomMessageHiddenFieldTarget.value = this.bottomMessageInputTarget.value;

      var new_value = this.buttonTextInputTarget.querySelector("input").value;
      this.buttonTextHiddenFieldTarget.value = new_value;
      this.buttonTextCopyableContentTarget.innerHTML = new_value;
      this.renderedButtonTarget.innerHTML = new_value;
      this.renderedTextLinkTarget.innerHTML = new_value;
    })
  }

  customFormSubmit(event) {
    event.preventDefault();

    if(this.toEmailsHiddenFieldTarget.value === undefined || this.toEmailsHiddenFieldTarget.value === null || this.toEmailsHiddenFieldTarget.value === "") {
      // verify if the TO input field is blank and show the error message
      for (const error_message of this.toEmailErrorTargets) {
        error_message.classList.remove("hidden");
      }
    } else {
      // submit form if TO input field is not empty
      Rails.fire(this.conversationInviteFormTarget, 'submit')
    }

  }

  delay() {
    return new Promise(resolve => {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        this.timer = null;

        resolve(0);
      }, 500);
    })
  }

  resetEmailComposer() {
    this.emailComposerTarget.classList.remove("hidden");
    this.emailComposerTarget.querySelector("input#emails_input").value = "";
    this.emailComposerTarget.querySelector("input#emails").value = "";
    this.emailComposerTarget.querySelector("input#emails_input").focus();
    this.emailSentTarget.classList.add("hidden");
    this.element.querySelector("#toggle-invitations-via-email").classList.remove("hidden");
  }  

}