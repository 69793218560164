import {Controller} from "stimulus"

export default class extends Controller {


    connect() {
        this.updateDirectUploadUrl();
    }


    updateDirectUploadUrl() {
        const editor = document.getElementsByTagName('trix-editor');

        editor.forEach(element => {
            if (element.dataset.directUploadUrl)
                element.dataset.directUploadUrl = element.dataset.directUploadUrl + '?file_attachment=true';
        });
    }
}