// This manages passing the rewardful cookie from clarityflow.com to the user registering on app.clarityflow.com

import { Controller } from 'stimulus'

export default class extends Controller {
  
  connect() {

    // If the current user has a rewardful cookie, add a hidden field on the registration form to pass the referral ID to user.rewardful_referral (a virtual attribute)
    rewardful('ready', function() {
      if(Rewardful.referral) {
        var form = document.querySelector("form[data-controller='rewardful']");
        form.insertAdjacentHTML('beforeend', '<input type="hidden" name="rewardful_referral_id" value="' + Rewardful.referral + '">');
        form.insertAdjacentHTML('beforeend', '<input type="hidden" name="rewardful_affiliate_id" value="' + Rewardful.affiliate.id + '">');
        form.insertAdjacentHTML('beforeend', '<input type="hidden" name="rewardful_affiliate_name" value="' + Rewardful.affiliate.name + '">');
      } 
    });

  }

}