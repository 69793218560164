import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["replyButton",
    "startCreation",
    "postMessage"];

  static values = {
    newUser: Boolean,
    startCreationOnConnect: Boolean
  }

  connect() {
    // This controller is set in 2 places on the page:  The conversation show view body tag, and the message creation form tag.
    // This is what enables us to control tours on both top-level messages and threaded messages, since threaded message creation forms are loaded into the page when user initiates them.


    // Check or set the user's show_tour localstorage variable and if there shouldn't be a tour for this user, hide all tour elements
    if (localStorage.getItem("show_tour") === null) {
      if (this.newUserValue === true) {
        localStorage.setItem("show_tour", "true");
      } else {
        localStorage.setItem("show_tour", "false");
        this.hideTourElements();
      }
    } else {
      localStorage.getItem("show_tour")
      if (localStorage.getItem("show_tour") === "false") {
        this.hideTourElements();
      }
    }

    // startCreationOnConnectValue is true in these situations:
    //  - This is the first message in the conversation, and this message creator is shown on page load (conversation show view's body tag connected this stimulus controller)
    //  - This is a threaded message and this message creator was just loaded into the DOM (message's form tag connected this stimulus controller)
    if (this.startCreationOnConnectValue === true) {
      this.animateInStartCreation();
    }

    // If the reply button is shown on the page, animate it into view
    if (this.hasReplyButtonTarget) {
      this.animateInReplyButton();
    }

  }

  close(event) {
    var tour_element = event.target.closest(".tour-element");
    if (tour_element) {
      tour_element.remove();
    }
    localStorage.setItem("show_tour", "false");
  }

  animateInStartCreation(event) {
    if (this.hasStartCreationTarget) {
      this.startCreationTarget.classList.add("translate-y-8", "opacity-100");
      for (let reply_button of this.replyButtonTargets) {
        reply_button.remove();
      }
    }
  }

  animateInReplyButton() {
    if (this.hasReplyButtonTarget) {
      for (let reply_button of this.replyButtonTargets) {
        reply_button.classList.add("translate-x-8", "opacity-100");
      }
    }
  }

  hideTourElements() {
    var tour_elements = this.element.querySelectorAll(".tour-element");
    for (let tour_element of tour_elements) {
      tour_element.remove();
    }
  }

}