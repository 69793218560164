// This controller manages the buttons located in the "kickoff" panel in the message creator, which is the centered panel where the user decides which device(s) to use to record their message (screen, camera, audio, text)

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["screenOrCameraWrapper", 
                    "screenButton", 
                    "cameraButton",
                    "micButton", 
                    "textButton", 
                    "startButton"];

  static values = {
    screen: Boolean,
    camera: Boolean,
    mic: Boolean,
    text: Boolean, 
    safari: Boolean,
    firefox: Boolean,
    isAccountMember: Boolean,
    messageType: String
  }

  connect() {
        
    // Browser detection
    const sUsrAg = navigator.userAgent.toLowerCase();
    let isSafari = false;
    let isFirefox = false;
    if (sUsrAg.indexOf('safari') !== -1 && sUsrAg.indexOf('chrome') === -1) {
      isSafari = true;
      this.safariValue = true;
    }
    if(sUsrAg.indexOf('firefox') > -1){
      isFirefox = true;
      this.firefoxValue = true;
    }

    // Check to see if we're currently on a mobile-sized screen (< 768px wide)
    function mobile_screen() {
      var mq = window.matchMedia( "(max-width: 768px)" );
      if (mq.matches) {
        return true;
      } else {
        return false
      }
    }

    // If browser doesn't support screen+camera mode...
    if (isFirefox || isSafari) {
      this.element.classList.add("no-screen-camera-support");
    } else { 
      this.element.classList.remove("no-screen-camera-support");
    }

    // Set the default message type
    var form_element = this.element.closest("form");
    var message_type_field = form_element.querySelector("input[data-message--creation-target='messageTypeField']");

    function stringToBoolean(string) {
      if (string) {
        return JSON.parse(string)
      }
      // Return null for other string values
      return undefined;
    }

    var default_message_type = form_element.dataset.defaultMessageType;
    var enable_screen_messages = stringToBoolean(form_element.dataset.enableScreenMessages);
    var enable_camera_messages = stringToBoolean(form_element.dataset.enableCameraMessages);
    var enable_mic_messages = stringToBoolean(form_element.dataset.enableMicMessages);
    var enable_file_uploads = stringToBoolean(form_element.dataset.enableFileUploads);
    var enable_library = form_element.dataset.enableLibrary;
    var enable_embeds = stringToBoolean(form_element.dataset.enableEmbeds);
    var enable_file_attachments = stringToBoolean(form_element.dataset.enableFileAttachments);

    var text_message_types = ['cameratext', 'screentext', 'screencameratext', 'audiotext', 'uploadtext', 'embedtext'];
    if (default_message_type && text_message_types.some(type => default_message_type.includes(type))) {
      // Remove the last 4 characters from the value
      default_message_type = default_message_type.slice(0, -4);
      var enable_text_block = true
    }

    if (!default_message_type) {
      default_message_type = "screencamera";
    }
    if (isFirefox || isSafari) {
      if (default_message_type == "screen" || default_message_type == "screencamera") {
        this.cameraButtonTarget.classList.add("inactive");
      } else if (default_message_type == "camera") {
        this.screenButtonTarget.classList.add("inactive");
      }
    }

    if (mobile_screen()) {
      // We only temporarily set this value "upload".
      // Once user uploads a file, this value will be changed (via creation_controller#manual_upload) to either 'video' or 'audio'
      // If user switches to text, then this value will change to 'text'
      message_type_field.value = "upload"
    } else {
      message_type_field.value = default_message_type;
      if (default_message_type == "screencamera") {
        this.setScreenCamera();
      } else if (default_message_type == "screen") {
        this.setScreen();
      } else if (default_message_type == "camera") {
        this.setCamera();
      } else if (default_message_type == "audio") {
        this.setAudio();
      } else if (default_message_type === 'upload' || default_message_type === 'library' || default_message_type === 'embed') {
        this.setNewMessageCreationFrom(form_element, default_message_type);
      }
      if(enable_file_uploads === false){
        this.setTogglePanel('upload')
      }
    }
    if (enable_text_block){
      this.setTextOnly();
    }

    if (default_message_type == 'text') {
      this.setText();
      this.setNewMessageCreationFrom(form_element, default_message_type);
    }
    if (enable_screen_messages === false && enable_camera_messages === false && enable_mic_messages === false) {
      this.setTogglePanel('record')
    }
    if (enable_embeds === false) {
      this.setTogglePanel('embed')
    }
    if (enable_library === 'false') {
      this.setTogglePanel('library')
    }
    if (enable_file_attachments === false) {
      this.setTogglePanel('files')
    }

    // If the URL has `text_content` param, then force open the text panel and fill it's value with the value of `text_content`
    var url = new URL(window.location.href);
    var text_content = url.searchParams.get("text_content");
    if (text_content) {
      var text_button = this.textButtonTarget;
      text_button.click();
      form_element.querySelector("trix-editor").innerHTML = text_content;
    }
    
  }

  // Toggles 'screen' button when clicked
  toggleScreen(event) {
    var button = this.screenButtonTarget;

    if (this.screenValue == true) { // setting to false
      this.screenValue = false;
      if(this.micToggleForNonAccountMember() && this.cameraValue == false){
        this.micValue = false;
      }
      toggleOff(button);

      if (this.firefoxValue || this.safariValue) {
        this.screenOrCameraWrapperTarget.classList.remove("screen-selected");
        this.screenOrCameraWrapperTarget.classList.add("none-selected");
      }
    } else { // setting to true
      this.screenValue = true;
      if(this.micToggleForNonAccountMember()) {
        this.micValue = true;
      }
      toggleOn(button);

      if (this.firefoxValue || this.safariValue) {
        this.screenOrCameraWrapperTarget.classList.add("screen-selected");
        this.screenOrCameraWrapperTarget.classList.remove("camera-selected");
        this.screenOrCameraWrapperTarget.classList.remove("none-selected");
        this.cameraButtonTarget.classList.add("inactive");
        this.cameraValue = false;
        toggleOff(this.cameraButtonTarget);
      }
    }

    this.updateMessageType();
  }

  // Toggles 'camera' button when clicked
  toggleCamera(event) {
    var button = this.cameraButtonTarget;

    if (this.cameraValue == true) { // setting to false
      this.cameraValue = false;
      if(this.micToggleForNonAccountMember() && this.screenValue == false){
        this.micValue = false;
      }
      toggleOff(button);

      if (this.firefoxValue || this.safariValue) {
        this.screenOrCameraWrapperTarget.classList.remove("camera-selected");
        this.screenOrCameraWrapperTarget.classList.add("none-selected");
      }
    } else { // setting to true
      this.cameraValue = true;
      if(this.micToggleForNonAccountMember()){
        this.micValue = true;
      }
      toggleOn(button);

      if (this.firefoxValue || this.safariValue) {
        this.screenOrCameraWrapperTarget.classList.add("camera-selected");
        this.screenOrCameraWrapperTarget.classList.remove("screen-selected");
        this.screenOrCameraWrapperTarget.classList.remove("none-selected");
        this.screenButtonTarget.classList.add("inactive");
        this.screenValue = false;
        toggleOff(this.screenButtonTarget);
      }
    }

    this.updateMessageType();
  }

  // Toggles 'mic' button when clicked
  toggleMic(event) {
    var button = this.micButtonTarget;

    if (this.micValue == true) {
      this.micValue = false;
      toggleOff(button);
    } else {
      this.micValue = true;
      toggleOn(button);
    }

    this.updateMessageType();
  }

  // Toggles 'text' button when clicked
  toggleText(event) {
    var button = this.textButtonTarget;

    if (this.textValue == true) {
      this.textValue = false;
      toggleOff(button);
    } else {
      this.textValue = true;
      toggleOn(button);
    }
  }

  // Sets to "screencamera" mode on initial page load
  setScreenCamera() {
    this.screenValue = "true";
    this.cameraValue = "true";
    this.micValue = "true";
    this.textValue = "false";

    var screen_button = this.screenButtonTarget;
    var camera_button = this.cameraButtonTarget;
    var mic_button = this.micButtonTarget;
    var text_button = this.textButtonTarget;

    toggleOn(screen_button);
    toggleOn(camera_button);
    toggleOn(mic_button);
    toggleOff(text_button);

    if (this.firefoxValue || this.safariValue) {
      // turn off camera button since firefox/safari can't do screen+camera
      this.cameraValue = "false";
      toggleOff(camera_button);
      this.screenOrCameraWrapperTarget.classList.add("screen-selected");
      this.screenOrCameraWrapperTarget.classList.remove("camera-selected");
    }

    this.updateMessageType();
  }

  // Sets to "screen" mode on initial page load
  setScreen() {
    this.screenValue = "true";
    this.cameraValue = "false";
    this.micValue = "true";
    this.textValue = "false";

    var screen_button = this.screenButtonTarget;
    var camera_button = this.cameraButtonTarget;
    var mic_button = this.micButtonTarget;
    var text_button = this.textButtonTarget;

    toggleOn(screen_button);
    toggleOff(camera_button);
    toggleOn(mic_button);
    toggleOff(text_button);

    if (this.firefoxValue || this.safariValue) {
      this.screenOrCameraWrapperTarget.classList.add("screen-selected");
      this.screenOrCameraWrapperTarget.classList.remove("camera-selected");
    }

    this.updateMessageType();
  }

  // Sets to "camera" mode on initial page load
  setCamera() {
    this.screenValue = "false";
    this.cameraValue = "true";
    this.micValue = "true";
    this.textValue = "false";

    var screen_button = this.screenButtonTarget;
    var camera_button = this.cameraButtonTarget;
    var mic_button = this.micButtonTarget;
    var text_button = this.textButtonTarget;

    toggleOff(screen_button);
    toggleOn(camera_button);
    toggleOn(mic_button);
    toggleOff(text_button);

    if (this.firefoxValue || this.safariValue) {
      this.screenOrCameraWrapperTarget.classList.add("camera-selected");
      this.screenOrCameraWrapperTarget.classList.remove("screen-selected");
    }

    this.updateMessageType();
  }

  // Sets to "audio" mode on initial page load
  setAudio() {
    this.screenValue = "false";
    this.cameraValue = "false";
    this.micValue = "true";
    this.textValue = "false";

    var screen_button = this.screenButtonTarget;
    var camera_button = this.cameraButtonTarget;
    var mic_button = this.micButtonTarget;
    var text_button = this.textButtonTarget;

    toggleOff(screen_button);
    toggleOff(camera_button);
    toggleOn(mic_button);
    toggleOff(text_button);

    this.updateMessageType();
  }

  // Sets to "text" mode on initial page load
  setText() {
    this.screenValue = "false";
    this.cameraValue = "false";
    this.micValue = "false";
    this.textValue = "true";

    var screen_button = this.screenButtonTarget;
    var camera_button = this.cameraButtonTarget;
    var mic_button = this.micButtonTarget;
    var text_button = this.textButtonTarget;

    toggleOff(screen_button);
    toggleOff(camera_button);
    toggleOff(mic_button);

    this.updateMessageType();
    text_button.click();

    toggleOn(text_button);
  }

  // This function used to only enable text block without disabling any other toggle.
  setTextOnly() {
    this.textValue = "true";
    var text_button = this.textButtonTarget;
    text_button.click();

    toggleOn(text_button);
  }

  setNewMessageCreationFrom(form_element, default_message_type) {
    var media_panel = form_element.getElementsByClassName('media-panel')
    var media_panel_toggle = form_element.getElementsByClassName('media-panel-toggle')

    media_panel.forEach(panel => {
      if (!panel.classList.contains('hidden')) {
        panel.classList.add('hidden')
      }
    })
    media_panel_toggle.forEach(toggle => {
      if (toggle.dataset.messageTypeId === default_message_type && !toggle.classList.contains('active')) {
        toggle.click()
      } else if (toggle.classList.contains('active')) {
        toggle.classList.remove('active')
      }
    })
    var new_message_form = form_element.querySelector('.' + default_message_type + '-panel')
    if (new_message_form) {
      new_message_form.classList.remove('hidden')
    } else {
      this.element.querySelector(".message-block-toggle[data-message-type-id='text']").click();
    }
  }

  setTogglePanel(media) {
    var media_panel_toggle = media === "files" ?
        document.getElementsByClassName('message-block-toggle') :
        document.getElementsByClassName('media-panel-toggle')

    media_panel_toggle.forEach(element => {
      if (media === element.dataset.messageTypeId) {
        element.classList.add('hidden')
      }
    })
  }

  // Updates the hidden message type field & the kickoff button text
  updateMessageType() {
    var start_button = this.startButtonTarget;
    var start_button_text = start_button.querySelector("span");
    var screen = this.screenValue;
    var camera = this.cameraValue;
    var mic = this.micValue;
    var message_type_field = this.element.closest("form").querySelector("input[data-message--creation-target='messageTypeField']");

    if (screen) {
      // screen
      start_button.dataset.action = "click->message--creation#start_screen_preview";
      start_button.classList.remove("hidden");
      start_button_text.innerHTML = "Share your screen..."
      if (screen && camera) {
        message_type_field.value = "screencamera";
        start_button_text.innerHTML = "Start screen & camera..."
      } else {
        message_type_field.value = "screen";
      }
    } else if (!screen && camera) {
      // camera
      start_button.dataset.action = "click->message--creation#start_camera_preview";
      start_button.classList.remove("hidden");
      start_button_text.innerHTML = "Turn on camera..."
      message_type_field.value = "camera";
    } else if (!screen && !camera && mic) {
      // mic only
      start_button.dataset.action = "click->message--creation#start_mic_preview";
      start_button.classList.remove("hidden");
      start_button_text.innerHTML = "Turn on mic..."
      message_type_field.value = "audio";
    } else {
      start_button.classList.add("hidden");
      message_type_field.value = "text";
    }
    this.messageTypeValue = message_type_field.value;
  }

  // Hides "+ camera" when camera will not be included in this message recording
  toggleCameraLabels(message_type) {
    var form = this.closest("form");
  }

  micToggleForNonAccountMember(){
    // returns true when:
    // - User is logged-in
    // - User is not an account_member
    // - The mic button is hidden
    return !(this.isAccountMemberValue) && this.micButtonTarget.classList.contains('hidden')
  }

}


function toggleOn(button) {
  button.classList.add("active");
}

function toggleOff(button) {
  button.classList.remove("active");
}
