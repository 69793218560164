export default class search_methods {

  async get_search_results(path) {
    var results = fetch(path, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      return response.json();
    }).then((json) => {
      if (json.search_results_found === true) {
        return json.search_results
      }
    }).catch((error) => {
      console.log(error)
      error.json().then(errorMessage => {
        console.log(errorMessage)
      })
    })

    return results
  }
}