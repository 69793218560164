import {Controller} from "stimulus"

export default class extends Controller {

    static targets = ["blocksContainer",
        "mediaPanel",
        "mediaPanelToggle",
        "block",
        "blockToggle",
        "hiddenPills",
        "shownPills",
        "typePill",
        "messageTypeField"
    ]

    static values = {}

    connect() {
    }

    openMenu() {
        this.blocksContainerTarget.classList.add("opacity-40");

        // If tour was present, hide it while the message types menu is open
        var tour_element = document.querySelector(".tour-element#tour-create-message");
        if (tour_element) {
            tour_element.classList.add("sm:hidden", "lg:hidden", "xl:hidden", "2xl:hidden");
        }
    }

    closeMenu() {
        this.blocksContainerTarget.classList.remove("opacity-40");

        // If tour was present, reveal it again
        var tour_element = document.querySelector(".tour-element#tour-create-message");
        if (tour_element) {
            tour_element.classList.remove("sm:hidden", "lg:hidden", "xl:hidden", "2xl:hidden");
        }
    }

    // Toggle the media panels
    togglePanel(event) {
        var panel_toggle = event.target.closest(".media-panel-toggle");
        var message_type_id = panel_toggle.dataset.messageTypeId;
        var menu_panel_toggle = this.element.querySelector(".message-type-menu .media-panel-toggle[data-message-type-id='" + message_type_id + "']"); // Might be different from the above .media-panel-toggle if the toggle link resides outside of the dropdown menu.  This identifies the toggle in the dropdown menu so that we can activate it.
        var panel = this.element.querySelector(".media-panel." + message_type_id + "-panel");
        if (panel_toggle.classList.contains("active")) { // deactivating panel
            panel.classList.add("hidden");
            panel_toggle.classList.remove("active");
            this.deactivateAllMediaPanels(); // deactivates all panels
            this.activateDefaultBlock(); // In case all blocks have been deactivated, activate the default block
        } else { // activating panel
            if (panel) {
                panel.classList.remove("hidden");
                menu_panel_toggle.classList.add("active");
                var input = panel.querySelector("input");
                if (input) {
                    input.focus();
                }

                // If we're activating the conversation_link panel, then deactivate all blocks
                if (message_type_id == "conversation_link") {
                    for (let block_toggle of this.blockToggleTargets) {
                        block_toggle.classList.remove("active");
                    }
                    for (let block of this.blockTargets) {
                        block.classList.add("hidden");
                    }
                }

                // If we're activating the library panel, focus the search field
                if (message_type_id == "library") {
                    var search_input = panel.querySelector("input#search");
                    search_input.focus();
                }
            }
            this.deactivateAllMediaPanels(message_type_id); // deactivates all panels except this one
        }

        if (panel_toggle.dataset.setMessageType) {
            var message_type_value_to_set = panel_toggle.dataset.setMessageType;
            this.messageTypeFieldTarget.value = message_type_value_to_set;
        }

        if (panel_toggle.dataset.messageTypeId === "record") {
            this.messageTypeFieldTarget.value = document.getElementsByClassName('kickoff-recording')[0].dataset['message-KickoffRecordingMessageTypeValue']
        }

        // If this panel change requires revealing the message template title field, process that
        this.toggleMessageTemplateTitleBlock();

        // Update the Pills in the message type selector
        this.updatePills();
    }

    deactivateAllMediaPanels(exclude_message_type_id) {
        for (let media_panel of this.mediaPanelTargets) {
            if (!media_panel.classList.contains(exclude_message_type_id + "-panel")) {
                media_panel.classList.add("hidden");
            }
        }
        for (let media_panel_toggle of this.mediaPanelToggleTargets) {
            if (media_panel_toggle.getAttribute('data-message-type-id') != exclude_message_type_id) {
                media_panel_toggle.classList.remove("active");
            }
        }
    }

    // Toggle message blocks (optional blocks displayed underneath the media panel)
    toggleBlock(event) {
        var block_toggle = event.target.closest(".message-block-toggle");
        var message_type_id = block_toggle.dataset.messageTypeId;
        var block = this.element.querySelector(".message-block." + message_type_id + "-block");
        var block_toggles = this.element.querySelectorAll(".message-block-toggle[data-message-type-id='" + message_type_id + "']");
        if (block_toggle.classList.contains("active")) { // deactivating block
            block.classList.add("hidden");
            for (let toggle of block_toggles) {
                toggle.classList.remove("active");
            }
            this.activateDefaultBlock(); // In case all blocks have been deactivated, activate the default block
        } else { // activating block
            if (block) {
                block.classList.remove("hidden");
                block.scrollIntoView({behavior: "smooth", block: "center"}); // Scroll down to the block
                for (let toggle of block_toggles) {
                    toggle.classList.add("active");
                }
            }

            // Deactivate Conversation Link block
            var conversation_link_block_toggle = this.element.querySelector(".panel-as-block-toggle[data-message-type-id='conversation_link']");
            if (conversation_link_block_toggle) {
                conversation_link_block_toggle.classList.remove("active");
                this.element.querySelector(".media-panel.conversation_link-panel").classList.add("hidden");
            }

        }

        // If this panel change requires revealing the message template title field, process that
        this.toggleMessageTemplateTitleBlock();

        // Update the Pills in the message type selector
        this.updatePills();
    }

    updatePills() {
        // Get the currently active message type IDs
        var active_type_ids = []
        var type_elements = this.mediaPanelToggleTargets.concat(this.blockToggleTargets);
        for (let type_element of type_elements) {
            if (type_element.matches(".message-type-menu .active")) {
                active_type_ids.push(type_element.dataset.messageTypeId);
            }
        }

        // Move active pills out of the 'hidden-pills' container and into the 'shown-pills' container
        // Using this method to enable the use of 'divide-x' class in between the shown pills
        for (let pill of this.typePillTargets) {
            if (active_type_ids.includes(pill.dataset.messageTypeId)) {
                this.shownPillsTarget.appendChild(pill);
            } else {
                this.hiddenPillsTarget.appendChild(pill);
            }
        }
    }

    activateDefaultBlock() {
        var first_active_block = this.element.querySelector(".message-block-toggle.active");
        var first_active_panel = this.element.querySelector(".media-panel-toggle.active");
        var default_block_toggle = this.element.querySelector(".message-block-toggle[data-message-type-id='text'");
        if (!first_active_block && !first_active_panel) {
            default_block_toggle.click();
        }
    }

    toggleMessageTemplateTitleBlock() {
        var messageTemplateTitleBlock = this.element.querySelector(".message-template-title-field-block");

        if (messageTemplateTitleBlock) {
            var is_program_item = messageTemplateTitleBlock.classList.contains("via-program-item"); // it's a program_item (can be a message_template in a program_item or a message_template in a thread_template in a program_item)
            var is_thread_template = messageTemplateTitleBlock.classList.contains("via-thread-template"); // Adding to a thread_template and we're not in a program_item

            if (is_program_item || is_thread_template) {
                // Set library and message_template_input variables
                var libraryPanel = this.element.querySelector(".media-panel.library-panel");
                var activeBlock = this.element.querySelector(".message-block:not(.hidden):not(.new-message-submit-block)");
                var message_template_title_input = messageTemplateTitleBlock.querySelector("input");

                // If the library panel is hidden OR there is at least one other block active (like the text block or files block etc.)...
                if (libraryPanel.classList.contains("hidden") || activeBlock) {
                    // show the Message Template Title Field
                    messageTemplateTitleBlock.classList.remove("hidden");
                    message_template_title_input.required = true
                    if (message_template_title_input.value == "") {
                        message_template_title_input.focus();
                    }
                } else if (!libraryPanel.classList.contains("hidden")) {
                    // Library Panel is shown, so don't show the Message Template Title Field
                    messageTemplateTitleBlock.classList.add("hidden");
                    message_template_title_input.removeAttribute('required');
                }
            }
        }
    }


    revealProductOfferForm(event) {
        var product_select_field = event.target.closest('.product-offer-select-field');
        var form_id = product_select_field.closest('form').id
        var form_type = product_select_field.dataset.formType;
        var product_id = product_select_field.value;
        var message_id = product_select_field.dataset.messageId;
        var message_thread_id = product_select_field.dataset.messageThreadId;
        var threaded_message = product_select_field.dataset.threadedMessage;
        var message_template_id = product_select_field.dataset.messageTemplateId;
        var thread_template_id = product_select_field.dataset.threadTemplateId;
        var program_item_id = product_select_field.dataset.programItemId;

        var reveal_form_url = '/reveal_product_offer_form/?product_id=' + product_id + '&form_id=' + form_id + '&form_type=' + form_type + '&message_id=' + message_id + '&message_thread_id=' + message_thread_id + '&threaded_message=' + threaded_message + '&message_template_id=' + message_template_id + '&thread_template_id=' + thread_template_id + '&program_item_id=' + program_item_id;

        Rails.ajax({
            type: "get",
            url: reveal_form_url,
        })
    }

    cancelProductOffer(event) {
        var productOfferBlock = event.target.closest(".product-offer-block");
        var productOfferForm = productOfferBlock.querySelector(".product-offer-form");
        var productOfferSelectField = productOfferBlock.querySelector('.product-offer-select-field')
        var productOfferAction = event.target.closest('.product-offer-actions')
        var productOfferToggle = document.querySelector(".message-block-toggle.active[data-message-type-id='product-offer']")
        productOfferSelectField.value = 'unselected'
        productOfferAction.classList.add('hidden')
        productOfferForm.innerHTML = ''
        productOfferBlock.classList.add('hidden')
        if (productOfferToggle)
            productOfferToggle.classList.remove("active");
    }
}
