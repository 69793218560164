import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["applyBtn", "ErrorContainer"]

  connect() {
  }

  revealApplyBtn(event) {
    if (this.hasApplyBtnTarget) {
      if(event.target.value.length > 0){
        this.applyBtnTarget.classList.remove("hidden");
      }
      else{
        this.applyBtnTarget.classList.add("hidden");
      }
    }
  }

  validateAndRevealApplyBtn(event) {
    if(event.target.value.length < 0) {
      this.applyBtnTarget.classList.add("hidden");
    } else {
      this.isValidUrl(event.target.value, event)
    }
  }


  isValidUrl(url, event) {
    var urlPattern = new RegExp("^http(s?)\:\/\/[0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*(:(0-9)*)*(\/?)([a-zA-Z0-9\-\.\?\,\'\/\\\+&amp;%\$#_]*)?$");

    if(url && urlPattern.test(url) && new URL(url).hostname === window.location.hostname){
      fetch(`/conversations/validate_conversation_url?url=${url}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      }).then(response => response.text())
        .then(data => {
          if(JSON.parse(data)['valid']){
            this.hideEmbedError()
            this.dispatchLinkValidated(event)
          }
          else{
            this.ErrorContainerTarget.innerHTML = 'Conversation URL is invalid'
            this.ErrorContainerTarget.classList.remove('hidden')
            this.dispatchLinkNotValid(event)
          }
        })
    }
    else{
      this.ErrorContainerTarget.innerHTML = 'Conversation URL is invalid'
      this.ErrorContainerTarget.classList.remove('hidden')
      this.dispatchLinkNotValid(event)
    }
  }

  hideEmbedError(){
    this.ErrorContainerTarget.innerHTML = ''
    this.ErrorContainerTarget.classList.add('hidden')
  }

  dispatchLinkValidated(event){
    event.target.dispatchEvent(new CustomEvent("link-validated", { bubbles: true }));
  }

  dispatchLinkNotValid(event){
    event.target.dispatchEvent(new CustomEvent("link-invalid", { bubbles: true }));
  }
}
