import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ["autofocusField", "submitLink"]

  open(event) {
    var modal_id_to_open = event.target.closest(".modal-trigger").dataset.modalIdToOpen;
    var modal = document.querySelector("#"+modal_id_to_open);
    modal.classList.remove("hidden");
    modal.classList.add("shown");
    if (this.hasAutofocusFieldTarget) {
      this.autofocusFieldTarget.focus();
    }
  }

  close(event) {
    var modal = event.target.closest(".modal");
    modal.classList.add("hidden");
    modal.classList.remove("shown", "modal-transition", "modal-transition-in");
  }

  closeAndRemove(event) {
    var modal = event.target.closest(".modal");
    modal.remove();
  }

  clickOutsideHide(event) {
    var modal_container = event.target.closest(".modal");
    if (modal_container) {
      var modal_box = modal_container.querySelector(".modal-box");
    
      if (modal_box.contains(event.target) === false) {
        modal_container.classList.add("hidden");
        modal_container.classList.remove("shown", "modal-transition-in", "modal-transition");
      }

      if(event.target.id === "troubleshoot-modal") {
        this.reload_window()
      }
    }
  }

  clickOutsideHideAndRemove(event) {
    var modal_container = event.target.closest(".modal");
    var modal_box = modal_container.querySelector(".modal-box");

    if (modal_box.contains(event.target) === false) {
      modal_container.remove();
    }

    if(event.target.id === "troubleshoot-modal") {
      this.reload_window()
    }
  }

  escHide(event) {
    if (event.key == "Escape") {
      var shown_modal = document.querySelector(".modal.shown");
      if (shown_modal) {
        shown_modal.classList.add("hidden");
        shown_modal.classList.remove("shown", "modal-transition-in", "modal-transition");
      }
    }
  }

  escHideAndRemove(event) {
    if (event.key == "Escape") {
      var shown_modal = document.querySelector(".modal.shown");
      if (shown_modal) {
        shown_modal.remove();
      }
    }
  }

  submitForm(event) {
    var form = event.target.closest("form");
    form.submit();
  }

  reload_window() {
    window.location.reload(true);
  }

  preventFutureClicksForLink(event) {
    event.preventDefault();

    this.submitLinkTarget.disabled = true
  }
}