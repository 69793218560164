// This handles the selection dropdowns for styles in a color group

import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = ['selectedStyleList']

  static values = { 
    accountId: Number
  }

  connect() {
  }

  select(event) {
    var option_element = event.target.closest(".style");
    var style_name = option_element.dataset.styleName;
    var selected_style_list = this.selectedStyleListTarget;
    var selected_style_list_items = selected_style_list.querySelectorAll(".style");

    // Set the selected option to the clicked option
    for (let style of selected_style_list_items) {
      if (style.dataset.styleName == style_name) {
        style.classList.remove("hidden");
      } else {
        style.classList.add("hidden");
      }
    }

    // If the .customized-selection element is present and shown, hide it
    var customized_selection = selected_style_list.querySelector(".customized-selection");
    if (customized_selection && customized_selection.classList.contains("hidden") == false) {
      customized_selection.classList.add("hidden");
    }

    // Update the color pickers for this group with the selected set of colors
    var color_group_container = option_element.closest(".color-group");
    var colorpickers = color_group_container.querySelectorAll(".color-picker");

    // Get the json string from the HTML data attribute on the option_element
    // Replace its single quotes with double quotes so that it can be parsed
    var colorsGroupStr = option_element.dataset.colorsGroup.replace(/'/g, '\"');
    var colorsGroup = JSON.parse(colorsGroupStr);

    // Convert the colorsGroup array into an object indexed by color_id
    var colorsObject = {};
    for (let color of colorsGroup) {
      colorsObject[color.color_id] = color;
    }

    // Loop through all of the color pickers and update their values
    for (let colorpicker of colorpickers) {
      let color_id = colorpicker.dataset.colorId;
      let color = colorsObject[color_id]; // Get the color object using color_id

      if (color) {
        // If a matching color was found, update the fields
        let hex_field = colorpicker.querySelector(".hex-color-field");
        let rgb_field = colorpicker.querySelector(".rgb-field");
        let colorpicker_field = colorpicker.querySelector(".colorpicker-field");

        hex_field.value = color.hex;
        rgb_field.value = color.rgb; // Update RGB field
        colorpicker_field.value = '#' + color.hex;

        // set the data-input-generated attribute to true on hex_field and colorpicker_field
        hex_field.setAttribute('data-input-generated', 'true');
        colorpicker_field.setAttribute('data-input-generated', 'true');

        // fire the input event on the hex field
        hex_field.dispatchEvent(new Event('input'));
      } else {
        console.log("No color found with this ID.");
      }
    }

    // Check if input was generated by an external event (i.e. user selecting a theme from the theme selector)
    var inputGenerated = selected_style_list.closest(".style-selection").getAttribute('data-input-generated') === 'true';

    if (!inputGenerated) {
      // Update the theme selection's selected item to "customized"
      var theme_selection = document.querySelector(".theme-selection");
      var selected_theme_list = theme_selection.querySelector(".selected-theme");
      var selected_theme = selected_theme_list.querySelector(".theme:not(.hidden)");
      if (selected_theme) {
        selected_theme.classList.add("hidden");
      }
      var customized_theme_selection = selected_theme_list.querySelector(".customized-theme-selection");
      if (customized_theme_selection) {
        customized_theme_selection.classList.remove("hidden");
      }

      // Set the selected theme localstorage to "customized"
      localStorage.setItem("selected_theme_"+this.accountIdValue, "customized");
    }

  }

}