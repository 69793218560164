import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['input', 'copyButton', 'richContent']
  static values = { 
    prepend: String,
    success: String, 
    string: String,
    append: String
  }

  copy() {
    if (this.hasInputTarget) {
      var input_value = this.inputTarget.value; 
    }
    
    var prepend_value = this.prependValue;
    var success_value = this.successValue;
    var string_value = this.stringValue;
    var append_value = this.appendValue

    if (string_value) {
      var copy_text = string_value;
    } else {
      if (prepend_value && input_value && append_value) {
        var copy_text = prepend_value+input_value+append_value;
       } else if (prepend_value && input_value) {
        var copy_text = prepend_value+input_value;
      } else if (append_value && input_value) {
        var copy_text = input_value+append_value;
      } else {
        var copy_text = input_value;
      }
    }

    var input = document.createElement('input');
    input.setAttribute('value', copy_text);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);

    if (success_value) {
      this.copyButtonTarget.innerHTML = success_value;
    } else {
      this.copyButtonTarget.innerHTML = "Copied"
    }
    
  }

  highlight(event) {
    event.target.closest("input").select();
  }

  copyRichContent() {
    var rich_content = this.richContentTarget.innerHTML;
    copyRichContentToClipboard(rich_content);

    var success_value = this.successValue;
    if (success_value) {
      this.copyButtonTarget.innerHTML = success_value;
    } else {
      this.copyButtonTarget.innerHTML = "Copied"
    }
  }

}

function copyRichContentToClipboard(str) {
  function listener(e) {
    e.clipboardData.setData("text/html", str);
    e.clipboardData.setData("text/plain", str);
    e.preventDefault();
  }
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
};