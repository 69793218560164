import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "eventToggle", "eventsInput" ]

  static values = {
    currentEvents: Array
  }

  connect() {
    var event_toggles = this.eventToggleTargets;
    var current_events = this.currentEventsValue;

    if (current_events) {
      // This is an existing webhook.  Set its current events values:
      for (let event_toggle of event_toggles) {
        var event_name = event_toggle.dataset.eventName;
        if (current_events.includes(event_name)) {
          event_toggle.classList.add("active");
          this.selectOption(event_name);
        } else {
          event_toggle.classList.remove("active");
          this.deSelectOption(event_name);
        }
      }
    } else {
      // This is a new webhook being creating.  Set the default events value:
      for (let event_toggle of event_toggles) {
        if (event_toggle.classList.contains("active")) {
          this.selectOption(event_toggle.dataset.eventName);
        }
      }
    }
    
  }

  toggleEvent(event) {
    var toggle_container = event.target.closest(".toggle");
    var event_name = toggle_container.dataset.eventName;
    if (toggle_container.classList.contains("active")) {
      this.selectOption(event_name);
    } else {
      this.deSelectOption(event_name);
    }
  }

  selectOption(option) {
    var select = this.eventsInputTarget;
    select.querySelector("option[value='"+option+"']").selected = true;
  }

  deSelectOption(option) {
    var select = this.eventsInputTarget;
    select.querySelector("option[value='"+option+"']").selected = false;
  }

}