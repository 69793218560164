export default class UploadError {
  constructor() {
    this.errorHash = {};
    this.errorSend = false;

    this.message_form = document.querySelector("#new-conversation");
    if(this.message_form === undefined || this.message_form === null) {
      this.message_form = document.querySelector("#new_message");
    }
  }

  getValuesFromMediaStream() {
    var streams = (window.streamArr !== undefined && window.streamArr.length > 0) ? window.streamArr : window.stream;

    if(streams === undefined) {
      this.errorHash.camera = this.errorHash.screen = this.errorHash.mic = false;
    } else {
      if(streams instanceof Array) {
        streams.forEach(stream => this.assignActiveMediasValues(stream));
      } else {
        this.assignActiveMediasValues(streams);
      }
    }
  }

  assignActiveMediasValues(mediaStream) {
    mediaStream.getTracks().forEach(stream => {
      if(stream.kind === "video") {

        if(stream.label.toLowerCase().includes("camera")) {
          this.errorHash.camera = true;
          if(!this.errorHash.hasOwnProperty("screen")) this.errorHash.screen = false;
          if(!this.errorHash.hasOwnProperty("mic")) this.errorHash.mic = false;
        } else {
          this.errorHash.screen = true;
          if(!this.errorHash.hasOwnProperty("camera")) this.errorHash.camera = false;
          if(!this.errorHash.hasOwnProperty("mic")) this.errorHash.mic = false;
        }

      } else if(stream.kind === "audio") {

        this.errorHash.mic = true;
        if(!this.errorHash.hasOwnProperty("camera")) this.errorHash.camera = false;
        if(!this.errorHash.hasOwnProperty("screen")) this.errorHash.screen = false;

      }
    })
  }

  assignError(error, additionalInfoHash) {
    this.errorHash.error = error;
    if(additionalInfoHash["errorName"] !== undefined) {
      this.errorHash.error_category = additionalInfoHash["errorName"];
    } else {
      this.errorHash.error_category = error.name;
    }
    this.errorHash.browser = additionalInfoHash["browserName"];
    if(typeof accountId !== 'undefined') this.errorHash.account_id = accountId;
    if(typeof userId !== 'undefined') this.errorHash.user_id = userId;
    this.errorHash.internet_connection = navigator.onLine;

    if(additionalInfoHash["recordingTimerSec"] !== undefined)
      this.errorHash.recording_timer_sec = additionalInfoHash["recordingTimerSec"]

    if(additionalInfoHash["key"] !== undefined)
      this.errorHash.key = additionalInfoHash["key"]

    if(additionalInfoHash["uploadId"] !== undefined)
      this.errorHash.upload_id = additionalInfoHash["uploadId"]

    this.getValuesFromMediaStream(this.errorHash.error_category);
    if(additionalInfoHash["errorName"] !== undefined && additionalInfoHash["errorName"] === 'UserOffline') this.errorSend = false;
  }
  sendError() {
    if(this.errorSend) {
      return this.errorSend;
    }

    this.errorSend = true;

    fetch(`/accounts/${accountId}/recording_issues_logs`,
        {
          method: "POST",
          body: new URLSearchParams(this.errorHash)
        })
        .then(response => response.json())
        .then(data => {
          if(this.message_form) {
            var hiddenField = document.createElement("input");
            hiddenField.type = "hidden";
            hiddenField.value = data.id;
            hiddenField.name = "recording_issue_log_id";

            this.message_form.appendChild(hiddenField);
          }
        })
  }

  setItemInLocalStorage() {
    var error_info;
    error_info = localStorage.getItem("error-info");
    if (error_info) {
      error_info = JSON.parse(error_info);
      var error_hash = {
        browserName: error_info[0],
        errorName: error_info[1]
      }
      window.uploadErrorInitializer.assignError("User's network got disconnected", error_hash);
      window.uploadErrorInitializer.sendError();

      localStorage.removeItem("error-info");
    }
  }
}