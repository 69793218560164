import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["viewContent", "editContent", "richtextInput", "saveBtn"]

  static values = {
  }

  connect() {
    const observer = new MutationObserver((mutations) => {
      this.revealSaveBtn();
    });

    observer.observe(this.element, {childList: true, subtree: true});

    this.viewContentTargets.forEach((element) => {
      element.querySelectorAll('a').forEach(function(link) {
        if (link.host !== window.location.host || (link.host === window.location.host && link.pathname.includes('/p/'))) {
          link.target = "_blank"
        }
      })
    })
  }

  toggleEdit() {
    var viewContent = this.viewContentTarget;
    var editContent = this.editContentTarget;
    if (viewContent.classList.contains("hidden")) {
      viewContent.classList.remove("hidden");
      editContent.classList.add("hidden");
    } else {
      viewContent.classList.add("hidden");
      editContent.classList.remove("hidden");
      editContent.querySelector("trix-editor").focus();
    }
  }

  focusEdit() { 
    // used when we're not toggling between view/edit containers and only showing the edit container (like when it's a new object form)
    // This ensures the container gets the active styles
    this.editContentTarget.classList.add("border-2","border-primary-600");
  }
  defocusEdit() { 
    // used when we're not toggling between view/edit containers and only showing the edit container (like when it's a new object form)
    // This ensures the container gets the active styles
    this.editContentTarget.classList.remove("border-2","border-primary-600");
  }

  toggleEditorOff(event) {
    if (this.hasViewContentTarget && !this.element.contains(event.target)) {
      this.viewContentTarget.classList.remove("hidden");
      this.editContentTarget.classList.add("hidden");
    }
  }

  revealSaveBtn() {
    if (this.hasSaveBtnTarget) {
      this.saveBtnTarget.classList.remove("hidden");
    }
  }
}
