// This manages custom events sent to Plausible Analytics

import { Controller } from 'stimulus'

export default class extends Controller {
  
  connect() {

    // New public account signup
    if (window.location.href.indexOf("new_account=true") > -1) {
      plausible('Free Signup');
    }

    // New account signup by a respondent
    if (window.location.href.indexOf("new_account_via_resp=true") > -1) {
      plausible('Free Signup by Respondent');
    }

    // New paying customer converted
    if (window.location.href.indexOf("subscription_success=true") > -1) {
      plausible('New Paid Subscription');
    }

  }

}