import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["selectedEmbedType",
    "embedTypeInput",
    "applyBtn",
    "embeddedType",
    "EmbedUrlError",
    "embedPlayerLinkField",
    "embedIframeField",
    "mobileValidateBtn"]

  static values = {
  }

  toggleEmbedType(event) {
    var selected_type_id = event.target.closest(".embed-type-selection-link").dataset.embedTypeId;

    // Activate the selected type at the top of the dropdown
    for (let selected of this.selectedEmbedTypeTargets) {
      if (selected.dataset.id == selected_type_id) {
        selected.classList.remove("hidden");
      } else {
        selected.classList.add("hidden");
      }
    }

    // Activate the selected type's input field
    for (let inputContainer of this.embedTypeInputTargets) {
      if (inputContainer.dataset.embedTypeId == selected_type_id) {
        this.embeddedTypeTarget.value = selected_type_id
        inputContainer.classList.remove("hidden");
        var input_field = inputContainer.querySelector(".embed-input-field")
        input_field.focus();

        if(selected_type_id === 'player_link')
          this.isValidUrl(input_field.value)
        else
          this.hideEmbedError()
      } else {
        inputContainer.classList.add("hidden");
      }
    }

    // Close the dropdown
    this.element.querySelector(".dropdown-menu-container").classList.add("hidden");
  }

  revealApplyBtn(event) {
    if (this.hasApplyBtnTarget) {
      if(event.target.value.length > 0){
        this.applyBtnTarget.classList.remove("hidden");
      }
      else{
        this.applyBtnTarget.classList.add("hidden");
      }
    }
  }

  validateAndRevealApplyBtn(event) {
    var embed_url_field = this.embedTypeInputTarget.querySelector("input");
    if (this.hasApplyBtnTarget) {
      if(embed_url_field.value.length > 0 && this.isValidUrl(embed_url_field.value)){
        this.applyBtnTarget.classList.remove("hidden");
      }
      else{
        this.applyBtnTarget.classList.add("hidden");
      }
    }
    if (this.hasMobileValidateBtn) {
      this.mobileValidateBtn.classList.add("hidden");
    }
  }

  isValidUrl(url) {
    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
      '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator

    if(urlPattern.test(url)){
      this.hideEmbedError()
      return true
    }
    else{
      this.EmbedUrlErrorTarget.innerHTML = 'URL is invalid'
      this.EmbedUrlErrorTarget.classList.remove('hidden')
      return false
    }
  }

  hideEmbedError(){
    this.EmbedUrlErrorTarget.innerHTML = ''
    this.EmbedUrlErrorTarget.classList.add('hidden')
  }

  removeEmbedMediaLink(event) {
    event.preventDefault();
    this.embedIframeFieldTarget.value = "";
    this.embedPlayerLinkFieldTarget.value = "";
  }
}
