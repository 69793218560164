import { Controller } from 'stimulus'
import { getCookieValue } from "../helpers";

export default class extends Controller {
  static targets = ['replyBtn', 'messageForm']
  static values = {
    hasNoMessages: Boolean
  }

  connect() {
    
  }

  retrieveMessageCreatorDetailsForMobile(event) {
    if (getCookieValue('X-App-Identifier')) {
        const args = ['Reply Button clicked'];
        if (event.target.closest('button') === null) return window.flutter_inappwebview.callHandler('messageHandler', ...args);
        if (event.target.closest('button').dataset === null) return window.flutter_inappwebview.callHandler('messageHandler', ...args);

        if (event.target.closest('button').dataset.messageThreadId) {
          window.flutter_inappwebview.callHandler('messageHandler', JSON.stringify({'message_thread_id': event.target.closest('button').dataset.messageThreadId}))
        } else {
          window.flutter_inappwebview.callHandler('messageHandler', ...args);
        }
    }
  }
}