import { Controller } from 'stimulus'

var timer = null;
export default class extends Controller {

    static targets = ["input", 
                      "slugResponse", 
                      "submitButton", 
                      "onboardingSlugChanged", 
                      "onboardingSlugUnchanged",
                      "onboardingSlugCanChange"]
    static values = {
      responsePresent: Boolean,
      onboarding: Boolean,
      payingSubscription: Boolean
    }

    connect() {
    }

    fetchResult() {
      if (this.hassubmitButtonTarget !== null) {
        this.submitButtonTarget.classList.add("btn-disabled")
        this.submitButtonTarget.setAttribute("disabled", "disabled")
      }
      clearTimeout(timer);
      timer = setTimeout(function() {
        this.getResultAndUpdate(this.submitButtonTarget);
      }.bind(this), 1000)
    }

    getResultAndUpdate(button) {
      let accountSlug = this.inputTarget;

      var params = new URLSearchParams({
        slug: accountSlug.value, 
        onboarding: this.onboardingValue
      })

      fetch(`/accounts/${accountId}/slug_validation?${params}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => response.json())
          .then(data => {
            var values = JSON.parse(data.response);
            var accountSlugContainer = this.element;
            var slugResponseDiv = this.slugResponseTarget;

            slugResponseDiv.classList.remove("hidden");
            slugResponseDiv.innerHTML = "";

            if (values.status === "error") {
              slugResponseDiv.classList.remove("alert-success");
              slugResponseDiv.classList.add("alert-error");
            } else {
              slugResponseDiv.classList.add("alert-success");
              slugResponseDiv.classList.remove("alert-error");
            }

            if ("errors" in values) {
              for (const error of values.errors) {
                var errorItem = document.createElement("div");
                errorItem.setAttribute("class", "");
                errorItem.innerHTML = error;
                slugResponseDiv.appendChild(errorItem);
              }
            } else {
              var successMessage = document.createElement("div");
              successMessage.setAttribute("class", "");
              successMessage.innerHTML = values.success;
              slugResponseDiv.appendChild(successMessage);
            }
            this.responsePresentValue = true;
            if (button!== null) {
                button.classList.remove("btn-disabled")
                button.removeAttribute("disabled", "disabled")
            }

            if (this.onboardingValue == true && this.responsePresentValue == true && values.status === "success") {
              var url = new URL(window.location.href);
              if ( (this.payingSubscriptionValue == true) || (url.searchParams.get('subscription_success')) ) {
                console.log('yuppppp');
                this.onboardingSlugChangedTarget.classList.add("hidden");
                this.onboardingSlugUnchangedTarget.classList.add("hidden");
                this.onboardingSlugCanChangeTarget.classList.remove("hidden");
              } else {
                this.onboardingSlugChangedTarget.classList.remove("hidden");
                this.onboardingSlugUnchangedTarget.classList.add("hidden");
                this.onboardingSlugCanchangeTarget.classList.add("hidden");
              }
              
            }
          })
          .catch(e => {
              if (button !== null) {
                  button.classList.remove("btn-disabled")
                  button.removeAttribute("disabled", "disabled")
              }
              console.log(e)
          });
    }
}