import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['newContactAttributeBtn']
  static values = { 
  }

  connect() {
  }

  cancelEdit(event) {
    var contactAttributeContainer = event.target.closest(".contact-attribute-container");
    var form = contactAttributeContainer.querySelector("form");
    var viewContactAttribute = contactAttributeContainer.querySelector(".view-contact-attribute");
    contactAttributeContainer.classList.remove("editing");
    form.remove();
    viewContactAttribute.classList.remove("hidden");
  }

  cancelNew(event) {
    var newContactAttributeContainer = event.target.closest("#new-contact-attribute");
    newContactAttributeContainer.remove();
    this.newContactAttributeBtnTarget.classList.remove("hidden");
  }

}