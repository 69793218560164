// This is a generic handler for a group of radio buttons (a single field, selectable via a .radios-group)
// It uses "fake" radio elements to set the value of a hidden field.

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["hiddenField"]

  connect() {
    // If the hidden field currently has a value, then find the radio-field by that value name and activate it.
    var current_value = this.hiddenFieldTarget.value;
    if (current_value) {
      var radio_to_activate = this.element.querySelector(".radio-field[data-radio-option-name='" + current_value + "']")
      if (radio_to_activate) {
        // First check to see if another radio field is currently active and deactivate it.
        var active_radio = this.element.querySelector(".radio-field.active");
        if (active_radio) {
          active_radio.classList.remove("active");
        }
        // now activate the radio field that matches the hidden field value.
        radio_to_activate.classList.add("active");
      }
    }
  }

  toggle(event) {
    var radio_field = event.target.closest(".radio-field");
    var radio_option_value = radio_field.dataset.radioOptionName;

    // First check to see if another radio field is currently active and deactivate it.
    var active_radio = this.element.querySelector(".radio-field.active");
    if (active_radio) {
      active_radio.classList.remove("active");
    }

    // Now activate this radio_field
    radio_field.classList.add("active");

    // Set the hidden value to this radio_option_value
    this.hiddenFieldTarget.value = radio_option_value;
  }
}