import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ['launchAccountOnboardingLink']
  static values = {
    autolaunch: Boolean,
    accountSlug: String
  }

  connect() {
    // UPDATE JUNE 2024:  Disabling auto-popup of onboarding setup guide.
    // To re-enable, uncomment this code and also uncomment the code in in account_onboardings_helper.rb for autolaunch_account_onboarding?(account)

    // // Handle auto launching the modal on page load.
    // if (this.autolaunchValue == true && this.hasLaunchAccountOnboardingLinkTarget) {
    //   // On page load, add fade_in param to the link that launches the modal, which will apply a short delay and fade in effect when clicked.
    //   this.launchAccountOnboardingLinkTarget.href = this.launchAccountOnboardingLinkTarget.href + '&fade_in=true';
    //
    //   // click the link to launch the modal.
    //   this.launchAccountOnboardingLinkTarget.click();
    // }

  }


  // These actions are related to the setup guide modal, which has multiple tabs.

  toggleTab(event) {
    this.stopVideos();
  }

  toggleNext(event) {
    var current_tab_position = event.target.closest(".btn").dataset.currentTabPosition;
    var next_tab_position = parseInt(current_tab_position) + 1;
    var next_tab = document.querySelector('#account-onboarding-modal .tabs-list .tab[data-tab-position="' + next_tab_position + '"]');
    if (next_tab) {
      next_tab.click();
    }
  }

  togglePrev(event) {
    var current_tab_position = event.target.closest(".btn").dataset.currentTabPosition;
    var prev_tab_position = parseInt(current_tab_position) - 1;
    var prev_tab = document.querySelector('#account-onboarding-modal .tabs-list .tab[data-tab-position="' + prev_tab_position + '"]');
    if (prev_tab) {
      prev_tab.click();
    }
  }

  stopVideos() {
    var videos = document.querySelectorAll('iframe, video');
    Array.prototype.forEach.call(videos, function (video) {
      if (video.tagName.toLowerCase() === 'video') {
        video.pause();
      } else {
        var src = video.src;
        video.src = '';  // Set src to empty string to force iframe to reload
        video.src = src + (src.includes('?') ? '&' : '?') + 't=' + new Date().getTime();
      }
    });
  }

  escRemoveMinimize(event) {
    if (event.key == "Escape") {
      var shown_modal = document.querySelector(".modal.shown");
      if (shown_modal) {
        shown_modal.remove();
        this.minimize();
      }
    }
  }

  clickOutsideRemoveMinimize(event) {
    var modal_container = event.target.closest(".modal");
    var modal_box = modal_container.querySelector(".modal-box");

    if (modal_box.contains(event.target) === false) {
      modal_container.remove();
      this.minimize();
    }
  }

  minimize() {
    var tip_relaunch_onboarding = document.querySelector('.relaunch-onboarding-widget #tip-relaunch-onboarding');

    // get localstorage value for hide_tip_relaunch_onboarding
    var hide_tip_relaunch_onboarding = localStorage.getItem('hide_tip_relaunch_onboarding');
    // if hide_tip_relaunch_onboarding is not true, then show the tip
    if (tip_relaunch_onboarding && hide_tip_relaunch_onboarding != 'true') {
      tip_relaunch_onboarding.classList.remove('hidden', 'opacity-0');
      tip_relaunch_onboarding.classList.add("translate-x-8", "opacity-100");
    }

    // insert a link on the page and then click it to launch the modal
    var link = document.createElement('a');
    var account_slug = this.accountSlugValue;
    link.href = `/${account_slug}/minimize_account_onboarding`;
    link.classList.add('hidden');
    link.setAttribute('data-remote', 'true');
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  hideTipRelaunchOnboarding(event) {
    var tip_element = event.target.closest("#tip-relaunch-onboarding");
    tip_element.remove();
    localStorage.setItem('hide_tip_relaunch_onboarding', 'true');
    document.querySelector('.close-relaunch-onboarding').classList.remove('hidden');
  }

}