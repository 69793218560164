import { Controller } from "stimulus"
import { getCookieValue } from "../../helpers";

export default class extends Controller {

  static targets = ['messageThreadContainer',
                    'threadedMessages',
                    'replyInThreadBtn',
                    'ThreadLoadMoreLink',
                    'newThreadLink',
                    'newMessageLink']

  static values = { 
    threadTemplate: Boolean 
  }

  connect(){
  }

  newReplyInThread(event){
    var threadBottomOrTop = event.target.closest('.thread-bottom') || event.target.closest('.thread-top-reply-buttons')
    var replyButtonsTopOrBottom = threadBottomOrTop.querySelector('.reply-in-thread-button-top') || threadBottomOrTop.querySelector('.reply-in-thread-button-bottom')
    var threadedReplyBtn = replyButtonsTopOrBottom.querySelector('.reply-in-thread-button')
    var threadId = threadedReplyBtn.dataset.messageThreadId
    var threadedForm = document.querySelector(`#create-message-${threadId}`)

    if (getCookieValue('X-App-Identifier')) {
      return window.flutter_inappwebview.callHandler('messageHandler', JSON.stringify({'message_thread_id': threadId}))
    } else {
      if (replyButtonsTopOrBottom) replyButtonsTopOrBottom.classList.add('hidden')
    }

    if (threadedForm) {
      threadedForm.classList.remove("hidden")
    } else{
      var params = new URLSearchParams({
        viewer: viewer,
        hide_message_creation_modal: threadedReplyBtn.dataset.hideModal
      });
      fetch("/message_threads/" +`${threadId}/new_thread/?${params}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json'
      }
    }).then((result) => {
      result.text().then( (val) => eval(val) );
    })
    }
  }


  closeMessageThreadsList(event){
    var message_thread_container = this.messageThreadContainerTarget;
    var replyInThreadBtnTop = this.messageThreadContainerTarget.querySelector('.reply-in-thread-button-top')
    // update the message-thread-container classes so that the visible thread container styles are hidden
    message_thread_container.classList.remove('thread-open');
    message_thread_container.classList.add('thread-closed');

    // If the top message has no media, we need to add back the no-media class
    if (this.element.dataset.noMedia == "true") {
      message_thread_container.classList.add('no-media');
    }

    // Remove the message creation form inside the thread from the DOM (so that new message forms outside this thread will continue to work)
    var create_message_in_thread_form = message_thread_container.querySelector(".create-message-in-thread") || message_thread_container.querySelector(".threaded-form form");
    if (create_message_in_thread_form) {
      create_message_in_thread_form.remove();
    }

    // Unhide the load more messages or new thread link
    if (this.hasThreadLoadMoreLinkTarget) {
      this.ThreadLoadMoreLinkTarget.classList.remove('hidden');
    } else if (this.hasNewThreadLinkTarget) {
      this.newThreadLinkTarget.classList.remove('hidden');
    }
    if (this.hasNewMessageLinkTarget)
      this.newMessageLinkTarget.classList.remove('hidden');

    if (replyInThreadBtnTop) {
      replyInThreadBtnTop.classList.add('hidden')
    }

    // Show the main-level reply button(s)
    var main_level_reply_buttons = document.querySelectorAll(".new-main-level-message-btn");
    if (main_level_reply_buttons) {
      for (let reply_button of main_level_reply_buttons) {
        reply_button.classList.remove("hidden");
      }
    }

    // If viewing a thread template (in a program_item), unhide the top message, since it was hidden when the thread was opened
    var top_message_container = message_thread_container.querySelector(".thread-top-message-container");
    top_message_container.classList.remove("hidden");

    // Scroll back to the top of this thread
    message_thread_container.scrollIntoView({behavior: "smooth", block: "center"})
  }

  newThreadBtnInThread(event){
    var replyInThreadBtnTopOrBottom = event.target.closest('.reply-in-thread-button-top') || event.target.closest('.reply-in-thread-button-bottom')

    if (replyInThreadBtnTopOrBottom) replyInThreadBtnTopOrBottom.classList.add("hidden");
  }
}
