// Used for account onboarding flows 
// Not be confused with user onboarding flows, which use the stimulus controllers in /javascript/controllers/onboardings/

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['onboardingContentBox']

  connect() {
    this.onboardingContentBoxTarget.classList.remove("opacity-0");
    this.onboardingContentBoxTarget.classList.add("opacity-100");
  }

  nextStep() {
    this.onboardingContentBoxTarget.classList.add("opacity-0");
    this.onboardingContentBoxTarget.classList.remove("opacity-100");
  }

}