// This controller (embed_controller.js) is for users obtaining an embed code to embed this ZM message in another website.
// Not to be confused with embed_media_controller.js which controls the UI for embeding 3rd-party media within Clarityflow.

import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["embedCodeInput"]

  connect() {
  }

  // When user obtains the message embed code, they can toggle ?show_author off/on the src URL using this action
  toggleShowAuthor(event) {
    var input = this.embedCodeInputTarget;
    var input_value = input.value;
    var copy_element = this.element.querySelector("div[data-copy-string-value]");

    // If show_author is present, remove it.
    if (input_value.includes("show_author=true")) {
      if (input_value.includes("?show_author=true")) { // show_author is the only param
        input.value = input_value.replace("?show_author=true", ""); // replace in the hidden input
        copy_element.dataset.copyStringValue = input_value.replace("?show_author=true", ""); // replace the copy element data attr
      } else if (input_value.includes("&show_author=true")) { // show_author is present along with token param
        input.value = input_value.replace("&show_author=true", ""); // replace in the hidden input
        copy_element.dataset.copyStringValue = input_value.replace("&show_author=true", ""); // replace the copy element data attr
      }
    } else { // show_author is not present, so add it.
      if (input_value.includes("?token")) { // token is present
        var token_index = input_value.indexOf("token=") + 6;
        var token_value = input_value.substring(token_index, input_value.indexOf("'", token_index));
        input.value = input_value.replace(token_value, token_value+"&show_author=true"); // replace in the hidden input
        copy_element.dataset.copyStringValue = input_value.replace(token_value, token_value+"&show_author=true"); // replace the copy element data attr
      } else { // token isn't present
        var src_index = input_value.indexOf("src='") + 5;
        var src_value = input_value.substring(src_index, input_value.indexOf("'", src_index));
        input.value = input_value.replace(src_value, src_value+"?show_author=true"); // replace in the hidden input
        copy_element.dataset.copyStringValue = input_value.replace(src_value, src_value+"?show_author=true"); // replace the copy element data attr
      }
    }

  }

}