// This manages custom events sent to Plausible Analytics

import { Controller } from 'stimulus'

export default class extends Controller {
  
  static values = { 
    userPresent: Boolean,
    conversationPresent: Boolean
  }

  connect() {

    // Track the page view
    if (this.userPresentValue == true) {
      fbq('track', 'Page view (logged-in user');
    } else {
      // User is not logged-in
      if (this.conversationPresentValue == true) {
        // Viewing a conversation
        fbq('track', 'Conversation page view (non-logged-in user');
      } else {
        // Not viewing a conversation
        fbq('track', 'Non-conversation page view (non-logged-in user');
      }
    }

    // New public account signup
    if (window.location.href.indexOf("new_account=true") > -1) {
      fbq('track', 'Free signup', {type: "public"});
    }

    // New account signup by a respondent
    if (window.location.href.indexOf("new_account_via_resp=true") > -1) {
      fbq('track', 'Free signup', {type: "respondent"});
    }

    // New account signup by a respondent
    if (window.location.href.indexOf("new_respondent=true") > -1) {
      fbq('track', 'Respondent registration');
    }

    // New paying customer converted
    if (window.location.href.indexOf("subscription_success=true") > -1) {
      fbq('track', 'New paid subscription', {value: 50.00});
    }

  }

}