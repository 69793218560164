// handles dropdown menus that trigger on click.  If you need a hover-based dropdown menu, use dropdown_hover_controller.

// both dropdown controllers offer this system for handling dropdowns that overlap other dropdowns (like on record-listings).  
// How to use:
// - Set the "dropdown-hide-overlapped-value" to "true"
// - Set the "siblings-group-id" to an ID shared by all dropdowns in this group (for example, the record-listing.id). This ensures that these siblings won't be hidden when dropdown is open.
// - Add "dropdown-overlaps" class to all other elements that this dropdown may overlap when opened.

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['button', 'menu'];
  static values = {
    hideOverlapped: Boolean
  }

  toggle(event) {
    var menu = this.menuTarget;

    if (this.hideOverlappedValue) {
      var overlappedDropdowns = document.querySelectorAll(".dropdown-overlaps");
      var siblingsGroupId = this.element.dataset.siblingsGroupId;
    }

    if (menu.classList.contains("hidden")) {
      menu.classList.remove("hidden");

      if (overlappedDropdowns) {
        for (let overlappedDropdown of overlappedDropdowns) {
          if (overlappedDropdown !== this.element && overlappedDropdown.dataset.siblingsGroupId !== siblingsGroupId) {
            overlappedDropdown.classList.add("hidden");
          }
        }
      }
    } else {
      menu.classList.add("hidden");

      if (overlappedDropdowns) {
        for (let overlappedDropdown of overlappedDropdowns) {
          if (overlappedDropdown !== this.element && overlappedDropdown.dataset.siblingsGroupId !== siblingsGroupId) {
            overlappedDropdown.classList.remove("hidden");
          }
        }
      }
    }

  }

  hide(event) {
    if (this.element.contains(event.target) === false) {
      this.menuTarget.classList.add("hidden");
    }
  }
}