// `camera_media_stream` function will get the camera + mic stream by requesting the users for access
//
// IMPORTANT FUNCTIONS
// getDevices()
// gotDevices()
// getStream()
// gotStream()
// Above mentioned functions are the main functions used to get the camera + mic stream
import {SelfieSegmentation} from "@mediapipe/selfie_segmentation"
import DetectAudioLevel from "../concerns/detect_audio_level";

export default function camera_media_stream() {
  var previewElement = this.previewTarget;
  var audioSelect = this.selectAudioSourceTarget.querySelector("select");
  var selectVideoSource = this.selectVideoSourceTarget;
  var selectAudioSource = this.selectAudioSourceTarget;
  var videoSelect = this.selectVideoSourceTarget.querySelector("select");
  var startCameraRecordBtn = this.startCameraRecordBtnTarget;
  var startScreenRecordBtn = this.startScreenRecordBtnTarget;
  var startMicRecordBtn = this.startMicRecordBtnTarget;
  var loadingIcon = this.loadingIconTarget;
  var mediaField = this.mediaFieldTarget;
  var activeMediaElements = this.activeMediaTargets;
  var activeMedia = [];
  var audioStream, cameraStream, cameraStream1;
  var stopAnim = undefined;
  var streamStop = this.stopBtnTarget;
  var resetStream = this.resetBtnTarget;
  const canvasElement = document.querySelector('#recordingCanvas1');
  const noImage = document.querySelector("#nobackground");
  var image = document.getElementById('bgimage');
  var new_video = document.querySelector("#new_video");
  var imageCheck;
  const sUsrAg = navigator.userAgent.toLowerCase();
  let isChrome = false;
  var camMicSelectorsTarget = this.camMicSelectorsTarget
  var messageUITarget = this.messageUITarget

  const selfieSegmentation = new SelfieSegmentation({
    locateFile: (file) => {
      return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@0.1.1632777926/${file}`;
    }
  });

  if(sUsrAg.indexOf('chrome') > -1) {
    isChrome = true;
  }

  if(resetStream) {
    resetStream.addEventListener("click", (e) => {
      if (stopAnim) {
        cancelAnimationFrame(stopAnim);
        stopAnim = undefined;
      }
    });
  }

  if(document.getElementById('bgimage') !== null && isChrome) {
    var withoutQueryParam = document.getElementById('bgimage').src.indexOf(noImage.src.slice(noImage.src.length - 6)) > -1
    var withQueryParam = document.getElementById('bgimage').src.split('?')[0].indexOf(noImage.src.split('?')[0].slice(noImage.src.split('?')[0].length - 6)) > -1
  }

  if (image !== null && isChrome) {
    image.crossOrigin = "Anonymous";
    image.addEventListener('load', function () {
      withoutQueryParam = document.getElementById('bgimage').src.indexOf(noImage.src.slice(noImage.src.length - 6)) > -1
      withQueryParam = document.getElementById('bgimage').src.split('?')[0].indexOf(noImage.src.split('?')[0].slice(noImage.src.split('?')[0].length - 6)) > -1

      if (document.getElementById('bgimage').src.includes("?")) {
        imageCheck = withQueryParam
      } else {
        imageCheck = withoutQueryParam
      }
      if (imageCheck) {
        image = null;
      } else {
        image = document.getElementById('bgimage');
      }

      callingCanvasFromMediapipe()
      
      stopStreams();
      getStream();
    });
  }

  if (document.getElementById('bgimage') && isChrome) {
    if (document.getElementById('bgimage').src.includes("?")) {
      imageCheck = withQueryParam
    } else {
      imageCheck = withoutQueryParam
    }

    if (imageCheck) {
      image = null;
    } else {
      image = document.getElementById('bgimage');
    }
  }

  audioSelect.onchange = () => { callingCanvasFromMediapipe(); getStream(); };
  videoSelect.onchange = () => { callingCanvasFromMediapipe(); getStream(); };

  callingCanvasFromMediapipe();

  // Show and hide audio icon in `start recording` button
  showHideActiveMediaElements.call(this);

  // Listen for stop event and stop animation
  listenStopEvent.call(this);

  if (localStorage.getItem("zipmessage-camera") || localStorage.getItem("zipmessage-mic")) {
    getDevices().then(gotDevices).then(() => getStream.call(this)).then(getDevices).then(gotDevices);
  } else {
    getStream.call(this).then(getDevices).then(gotDevices);
  }

  // ---MAIN FUNCTION STARTS---

  function getDevices() {
    console.log(navigator.mediaDevices.enumerateDevices())
    return navigator.mediaDevices.enumerateDevices();
  }

  function gotDevices(deviceInfos) {
    videoSelect.innerHTML = "";
    audioSelect.innerHTML = "";
    window.deviceInfos = deviceInfos; // make available to console
    console.log('Available input and output devices:', deviceInfos);
    let layoutCamera = localStorage.getItem("zipmessage-camera");
    let layoutMic = localStorage.getItem("zipmessage-mic");

    for (const deviceInfo of deviceInfos) {
      const option = document.createElement('option');
      option.value = deviceInfo.deviceId;
      if (deviceInfo.kind === 'audioinput') {
        option.text = deviceInfo.label || `Microphone ${audioSelect.length + 1}`;

        if (layoutMic === JSON.stringify(option.value)) {
          option.selected = true;
        }

        audioSelect.appendChild(option);
      } else if (deviceInfo.kind === 'videoinput') {
        if (deviceInfo.label && deviceInfo.label.toLowerCase().indexOf('back') >= 0) {
          option.text = "Back camera - facing out"
        } else if (deviceInfo.label && deviceInfo.label.toLowerCase().indexOf('front') >= 0) {
          option.text = "Front camera - facing you"
        } else {
          option.text = deviceInfo.label || `Camera ${videoSelect.length + 1}`;
        }

        if (layoutCamera === JSON.stringify(option.value)) {
          option.selected = true;
        }

        videoSelect.appendChild(option);
      }
    }
  }

  function getStream() {
    stopStreams();

    const videoSource = videoSelect.value;

    var idealWidth = 1280;
    var idealHeight = 720;
    var minWidth = 320;
    var minHeight= 320;

    if(mediaField && mediaField.dataset.enableHdVideo) {
      idealWidth = 1920;
      idealHeight = 1080;
      minWidth = 500;
      minHeight = 500;
    }

    const constraints = {

      video: {
        deviceId: videoSource ? {exact: videoSource} : undefined,
        width: {
          min: minWidth,
          ideal: idealWidth
        },
        height: {
          min: minHeight,
          ideal: idealHeight
        },
        facingMode: "user"
      }
    };

    return navigator.mediaDevices.getUserMedia(constraints).then(stream => {
      if(image !== null){
        new_video.srcObject = stream;
        new_video.muted = true;
        new_video.play();
      }
      window.cameraStream1 = stream;
      cameraStream1 = stream;
      window.streamArr.push(stream);

      console.log(activeMedia, 'activeMedia')
      if(activeMedia.includes("mic")) {
        return getAudioStream.call(this)
      } else {
        gotStream(stream);
      }
    }).catch(error => handleError.call(this, error));
  }

  function getAudioStream() {
    const audioSource = audioSelect.value;

    const audioConstraints = {
      audio: {deviceId: audioSource ? {exact: audioSource} : undefined},
      echoCancellation: true,
      noiseSuppression: true,
      sampleRate: 44100
    }

    return navigator.mediaDevices.getUserMedia(audioConstraints).then(stream => {
      audioStream = stream;

      gotStream(stream);

      // show audio level of mic
      var detectAudio = new DetectAudioLevel();
      detectAudio.detectAudioLevel(stream, 'audio-meter', camMicSelectorsTarget, messageUITarget);
    }).catch(error => handleError.call(this, error))
  }


  function gotStream(stream) {
    if(videoSelect.value !== null && videoSelect.value.length > 0) localStorage.setItem("zipmessage-camera", JSON.stringify(videoSelect.value));
    if(audioSelect.value !== null && audioSelect.value.length > 0) localStorage.setItem("zipmessage-mic", JSON.stringify(audioSelect.value));

    if(activeMedia.includes("mic")){
      audioSelect.selectedIndex = Array.from(audioSelect.options).
      findIndex(option => getIndexOfOption(option, audioStream.getAudioTracks()[0].label));
    }
    if(activeMedia.includes("camera")){
     videoSelect.selectedIndex = Array.from(videoSelect.options).
     findIndex(option => getIndexOfOption(option, cameraStream1.getVideoTracks()[0].label));
     }

    console.log(image !== null, isChrome)
    console.log(image)
    if (image !== null && isChrome ) {
      sendToMediaPipe();
    }else{
      cameraStream = cameraStream1
    }
    previewElement.querySelector("video").srcObject = cameraStream;
    window.stream = cameraStream;
    if (audioStream) {
      window.stream.addTrack(audioStream.getAudioTracks()[0]);
    }

    if(startCameraRecordBtn || startScreenRecordBtn || startMicRecordBtn) {
      loadingIcon.classList.add("hidden"); // Hide the loading spinner icon
      startCameraRecordBtn.classList.remove("hidden"); // show the "start recording camera" button
      startScreenRecordBtn.classList.add("hidden"); // hide the "start recording screen" button
      startMicRecordBtn.classList.add("hidden"); // hide the "start recording mic" button
    }
  }

  const sendToMediaPipe = async () => {
    // If there video isn't ready yet, just loop again
    if (new_video.videoWidth) {
      await selfieSegmentation.send({ image: new_video });
    }
   stopAnim = requestAnimationFrame(sendToMediaPipe);
  };

  function callingCanvasFromMediapipe() {
    if(image === null) return

    if (isChrome) {
      selfieSegmentation.setOptions({
        modelSelection: 1,
      });

      cameraStream = canvasElement.captureStream();

      streamStop.addEventListener("click", (e) => {
        stopStreams();
      });

      selfieSegmentation.onResults(onResults);
    }
  }

  function listenStopEvent() {
    if(!(this.hasStopBtnTarget === true)) return false;

    this.stopBtnTarget.addEventListener("click", (e) => {
      if(stopAnim) {
        stopAnim();
        stopAnim = undefined;
      }
    })
  }


  function onResults(results) {
    const canvasCtx = canvasElement.getContext('2d');
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.drawImage(results.segmentationMask, 0, 0,
      canvasElement.width, canvasElement.height);

    // Only overwrite existing pixels.
    if(image !== null) {
      canvasCtx.globalCompositeOperation = 'source-out';
      canvasCtx.drawImage(image, 0, 0, canvasElement.width, canvasElement.height);
    }else{
      canvasCtx.globalCompositeOperation = 'source-out';
      canvasCtx.fillStyle = '#00FF00';
    }

    // Only overwrite missing pixels.
    canvasCtx.globalCompositeOperation = 'destination-atop';
    canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.restore();
  }

  // ---MAIN FUNCTION ENDS---

  // ---HELPER FUNCTION STARTS---

  function showHideActiveMediaElements() {
    if (activeMediaElements === undefined || activeMediaElements === null) return
    // Get media elements that are active
    for (const activeMediaElement of activeMediaElements) {
      if (activeMediaElement.classList.contains("active"))
        activeMedia.push(activeMediaElement.dataset.device);
    }

    // Hide `mic` icon in start recording button
    if (!activeMedia.includes("mic")) {
      selectAudioSource.classList.add("hidden");
      var micLabels = this.element.querySelectorAll(".plusMicLabel");
      for (let micLabel of micLabels) {
        micLabel.classList.add("hidden");
      }
    }
  }

  function stopStreams() {
    // Stop already active media streams

    if (window.stream) {
      window.stream.getTracks().forEach(track => {
        track.stop();
      });
    }

    if(window.streamArr) {
      window.streamArr.forEach(stream => {
        stream.getTracks().forEach(track => {
          track.stop();
        });
      })

      window.streamArr = [];
    }
    window.streamArr = [];

    if (stopAnim) {
      cancelAnimationFrame(stopAnim);
      stopAnim = undefined;
    }
  }

  function getIndexOfOption(option, label) {
    // Get the index of options in select
    return (option.text === label) ||
        (option.text.toLowerCase().indexOf('you') >= 0 && label.toLowerCase().indexOf('front') >= 0) ||
        (option.text.toLowerCase().indexOf('out') >= 0 && label.toLowerCase().indexOf('back') >= 0);
  }

  // ---HELPER FUNCTION ENDS---

  function handleError(error) {
    console.error('Error: ', error);
    console.error('Error: ', error.name);

    this.mediaError(error)
    if(window.appsignal !== undefined) { var span = window.appsignal.createSpan();
      span.setParams({ account_id: accountId })
      span.setTags({
        tag: "Media error"
      }).setError({name: "Camera permission denied", message: error});
      window.appsignal.send(span);};
  }
}