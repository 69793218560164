// This handles a single color picker field and 2-way syncing the colorpicker with it's accompanying hex code text input.

import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = ["rgbField", "hexField", "colorPickerField"]

  static values = { 
    accountId: Number,
    hex: String,
    rgb: String
  }

  connect() {

  }

  updateColor(event) {
    var rgb_field = this.rgbFieldTarget;
    var hex_field = this.hexFieldTarget;
    var colorpicker_field = this.colorPickerFieldTarget;
    let new_hex_value;
    let new_rgb_value;

    // Check if input was generated by an external event (i.e. user selecting a style from the style selector, which updates the values on the associated color pickers)
    var inputGenerated = colorpicker_field.getAttribute('data-input-generated') === 'true';

    // if the colorpicker field was clicked
    if (event.target == colorpicker_field) {
      var colorpicker_value = colorpicker_field.value;

      // Strip the colorpicker_value of # because we must save the hex field value without a #
      if (colorpicker_value[0] == "#") {
        colorpicker_value = colorpicker_value.substring(1);
      }

      new_hex_value = colorpicker_value
      new_rgb_value = this.hexToRGB(new_hex_value);

      // Update the hex field with the colorpicker value
      hex_field.value = new_hex_value;

      // Update the rgb field with the colorpicker value
      rgb_field.value = new_rgb_value;

    } else { // the hex field was clicked
      var hex_field_value = hex_field.value;

      new_hex_value = hex_field.value;
      new_rgb_value = this.hexToRGB(new_hex_value);

      // if the new_hex_value does not start with a #, add it because the the colorpicker needs it included
      if (new_hex_value[0] != "#") {
        new_hex_value = "#" + new_hex_value;
      }

      // Update the colorpicker field with the hex code WITH the #
      colorpicker_field.value = new_hex_value;

      // Update the rgb field with the hex field value
      rgb_field.value = new_rgb_value;
    }


    // Strip the new_hex_value of # because we must save the hex field value without a #
    if (new_hex_value[0] == "#") {
      new_hex_value = new_hex_value.substring(1);
    }
    this.element.dataset.hexValue = new_hex_value;
    this.element.dataset.rgbValue = new_rgb_value;

    if (!inputGenerated) {

      // Update this selection's selected item to "customized"
      var color_group = colorpicker_field.closest(".color-group");
      var selected_style_list = color_group.querySelector(".selected-style");
      var selected_style = selected_style_list.querySelector(".style:not(.hidden)");
      if (selected_style) {
        selected_style.classList.add("hidden");
      }
      var customized_selection = selected_style_list.querySelector(".customized-selection");
      if (customized_selection) {
        customized_selection.classList.remove("hidden");
      }

      // Update the theme selection's selected item to "customized"
      var theme_selection = document.querySelector(".theme-selection");
      var selected_theme_list = theme_selection.querySelector(".selected-theme");
      var selected_theme = selected_theme_list.querySelector(".theme:not(.hidden)");
      if (selected_theme) {
        selected_theme.classList.add("hidden");
      }
      var customized_theme_selection = selected_theme_list.querySelector(".customized-theme-selection");
      if (customized_theme_selection) {
        customized_theme_selection.classList.remove("hidden");
      }

      // Set the selected theme localstorage to "customized"
      localStorage.setItem("selected_theme_"+this.accountIdValue, "customized");
    }

    // Reset the data-input-generated attribute to false after handling the event
    colorpicker_field.setAttribute('data-input-generated', 'false');
    hex_field.setAttribute('data-input-generated', 'false');

  }

  hexToRGB(hex) {
    let r = 0, g = 0, b = 0;

    // 3 digits
    if (hex.length == 3) {
      r = "0x" + hex[0] + hex[0];
      g = "0x" + hex[1] + hex[1];
      b = "0x" + hex[2] + hex[2];

    // 6 digits
    } else if (hex.length == 6) {
      r = "0x" + hex[0] + hex[1];
      g = "0x" + hex[2] + hex[3];
      b = "0x" + hex[4] + hex[5];
    }
    
    // Ensure values are between 0 and 255
    r = Math.max(0, Math.min(255, r));
    g = Math.max(0, Math.min(255, g));
    b = Math.max(0, Math.min(255, b));

    return `${+r} ${+g} ${+b}`;
  }

}