import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['input', 'linkContainer', 'linkElement']
  
  static values = { 
  }

  connect() {
    var linkContainer = this.linkContainerTarget;
    var input = this.inputTarget;
    var value = input.value;
    var linkElement = this.linkElementTarget;
    if (linkContainer && value && !(/\s/.test(value)) && value.includes(".") && (value.includes("http://") || value.includes("https://"))) {
      linkContainer.classList.remove("hidden");
      linkElement.href = value;
      linkElement.innerHTML = value;
    }
  }

  focusInput() {
    this.linkContainerTarget.classList.add("hidden");
    this.inputTarget.focus();
  }

}