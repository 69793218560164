import { Controller } from "stimulus";
import videojs from "video.js";

export default class extends Controller {
  static targets = ["player"];

  static values = {
    messageID: Number,
    userID: Number,
    type: String,
  };

  connect() {
    window.onload = (event) => {
      sessionStorage.removeItem("background_image_id");
    };
    if (this.typeValue == "audio") {
      var player = videojs(this.playerTarget, {
        fluid: false,
        fill: true,
        aspectRatio: "20:3",
        responsive: true,
        autoplay: false,
        liveui: true,
        featuresPlaybackRate: true,
        playbackRates: [0.5, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5],
        controlBar: {
          volumePanel: { inline: false },
        },
      });
    } else {
      var player = videojs(this.playerTarget, {
        fluid: true,
        responsive: true,
        autoplay: false,
        liveui: true,
        featuresPlaybackRate: true,
        playbackRates: [0.5, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5],
        controlBar: {
          volumePanel: { inline: false },
        },
      });
    }

    if (
      this.data.has("duration") &&
      this.data.has("available") &&
      this.data.get("available") !== "true"
    ) {
      var duration = parseInt(this.data.get("duration"));
      player.duration = function () {
        return duration; // the amount of seconds of video
      };
    }
  }

  play(event) {
    var registerMessagePlay = event.target.closest(".message-body").querySelector("#register-message-play")
    if (registerMessagePlay) {
      registerMessagePlay.click();
    }
  }
}
