import { Controller } from 'stimulus'

export default class extends Controller {


  appendSpaceToSpaceContainer(event){
    var space_selection = event.target.closest('form').querySelector('.space_program_space_id');
    fetch(`/space_container/${event.target.value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "same-origin"
    }).then(function (result) {
      result.text().then(function (val) {
        return eval(val);
      });
    });
  }
}