import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['accountsList']

  connect() {
    // Focus the first acount in the list, if the list is present
    if (this.hasAccountsListTarget) {
      this.accountsListTarget.querySelector("a").focus();
    }
  }

  navigate_accounts(event) {
    if (this.hasAccountsListTarget) {
      var account_links = Array.from(this.accountsListTarget.querySelectorAll("a"));
      var focused_account = this.accountsListTarget.querySelector("a:focus");
      var i = 0;

      if (event.keyCode == 38) { // UP key is pressed
        event.preventDefault();
        if (focused_account) {
          i = account_links.indexOf(focused_account);
          if (i == 0) {
          } else {
            i = i - 1;
            account_links[i].focus();
          }
        } else {
          account_links[i].focus();
        }
      }
      if (event.keyCode == 40) { // DOWN key is pressed
        event.preventDefault();
        if (focused_account) {
          i = account_links.indexOf(focused_account);
          if (i == account_links.length - 1) {
            this.searchInputTarget.focus();
          } else {
            i = i + 1;
            account_links[i].focus();
          }
        } else {
          account_links[i].focus();
        }
      }
    }
  }

}