import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['optionField'];

    oneOptionSelected(event) {
        if (event.target.checked && this.hasOptionFieldTarget) {
            var checkboxes = this.optionFieldTargets;
            for (let checkbox of checkboxes) {
                if (event.target !== checkbox) {
                    checkbox.checked = false;
                }
            }
        }
    }
}