import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["sidebar",
    "toggleSidebar",
    "sidebarSection"]

  static values = {
    activeItem: String,
    activeSubSection: String,
    activeSubSectionLink: String
  }

  connect() {
    // Configure the sidebar opened/closed state on page load
    if (this.hasSidebarTarget) {
      var sidebar = this.sidebarTarget;
      // Sidebar defaults to closed state.
      // If the user left their sidebar in an open state, set it to open.
      // But if the user is on a medium-sized screen (768-1024), then set it to closed, since the opened state here is full-width.
      var sidebar_state = localStorage.getItem("sidebar_state");

      if (sidebar.classList.contains("always-open")) {
        sidebar.classList.remove("sidebar-closed");
        sidebar.classList.add("sidebar-open");
      } else if (sidebar_state == "closed" || medium_screen()) {
        sidebar.classList.add("sidebar-closed");
        sidebar.classList.remove("sidebar-open");
      }

      // If the viewport is a short height (defined in short_screen()), apply this class to sidebar, so that we can adjust elements accordingly.
      if (short_screen()) {
        sidebar.classList.add("short-screen");
      }
    }

    // Activate the current sidebar item (if applicable)
    if (this.hasActiveItemValue) {
      var sidebar_link = document.querySelector(`#sidebar-link-${this.activeItemValue}`);
      if (sidebar_link) {
        sidebar_link.classList.add("active");
      }
    }

    // Activate the current sidebar sub-section (if applicable)
    if (this.hasActiveSubSectionValue) {
      var sidebar_sub_section = document.querySelector(`#sidebar-sub-section-${this.activeSubSectionValue}`);
      if (sidebar_sub_section) {
        sidebar_sub_section.classList.add("active");
      }
    }

    // Activate the current sidebar sub-section link (if applicable)
    if (this.hasActiveSubSectionLinkValue) {
      var sidebar_sub_section_link = document.querySelector(`#sidebar-sub-section-link-${this.activeSubSectionLinkValue}`);
      if (sidebar_sub_section_link) {
        sidebar_sub_section_link.classList.add("active");
      }
    }

  }

  // On desktop: Toggle the sidebar to "open" (default) or "closed" (minimal thin bar) state
  toggleSidebar(event) {
    var sidebar = this.sidebarTarget;

    if (sidebar.classList.contains("sidebar-open")) {
      sidebar.classList.remove("sidebar-open");
      sidebar.classList.add("sidebar-closed");
      localStorage.setItem("sidebar_state", "closed");
    } else {
      sidebar.classList.remove("sidebar-closed");
      sidebar.classList.add("sidebar-open");
      localStorage.setItem("sidebar_state", "open");
    }
  }

  // On mobile:  Toggle the sidebar on and off
  toggleOnMobile(event) {
    var sidebar = this.sidebarTarget;

    if (medium_screen()) {

      // User is on a medium screen (768-1024), and they clicked the "X"
      // Instead of hiding the sidebar, we want to toggle it back to closed-state.
      sidebar.classList.remove("sidebar-open");
      sidebar.classList.add("sidebar-closed");

    } else { // User is on a small screen (<768) so we will show/hide the sidebar.

      if (sidebar.classList.contains("hidden")) {
        sidebar.classList.remove("hidden");

        // On mobile, we start with the 'sidebar-open' styles (in sidebar.scss), then tweak within that using media queries
        // So we must make sure the sidebar has the .sidebar-open class and not the .sidebar-closed class.
        sidebar.classList.remove("sidebar-closed");
        sidebar.classList.add("sidebar-open");

      } else {
        sidebar.classList.add("hidden");
      }

    }

  }

  toggleSubSection(event) {
    var section_content = event.target.closest(".section-content");
    var section_subnavs = section_content.querySelectorAll(".section-subnav");
    var toggled_section_subnav = event.target.closest(".section-subnav");

    if (toggled_section_subnav.classList.contains("active")) {
      toggled_section_subnav.classList.remove("active");
    } else {
      for (let section_subnav of section_subnavs) {
        section_subnav.classList.remove("active");
      }
      toggled_section_subnav.classList.add("active");
    }
  }

}

// function to check if the screen is between min width 768px and max width 1024px
function medium_screen() {
  var mq = window.matchMedia("(min-width: 768px) and (max-width: 1024px)");
  if (mq.matches) {
    return true;
  } else {
    return false
  }
}

function short_screen() {
  return window.innerHeight < 700;
}