export default class DetectAudioLevel {

  detectAudioLevel(stream, meterId, camMicSelectorsTarget, messageUITarget) {
    var audioContext;
    var meter;
    var mediaStreamSource;
    var audioMeter = camMicSelectorsTarget.querySelector('#' + meterId)
    var WIDTH = audioMeter.width;
    var HEIGHT = audioMeter.height;
    var canvasContext = audioMeter.getContext("2d");
    var maxWidth = WIDTH * 1.8;
    var preRecAudioThresholdReached = false; // flag to identify if the audio threshold is reached in pre-recording state
    var recStateAudioThresholdReached = false; // flag to identify if the audio threshold is reached in recording state
    var recordingStateFlag = false;
    var audioInternalTimer = 4000;
    var audioLevelSet = false;
    var micCheckMessage = document.querySelector('#mic-check-message'); // Used on invitation onboarding mic check
    var micButton = document.querySelector('div[data-message--kickoff-recording-target="micButton"]')

    if(micButton == null)
      var micButton = document.querySelector('div[data-invitations--invitation-onboarding-target="micSelectorsContainer"]')

    audioContext = new AudioContext();
    // Create an AudioNode from the stream.
    mediaStreamSource = audioContext.createMediaStreamSource(stream);

    // Create a new volume meter and connect it.
    meter = this.createAudioMeter(audioContext);
    mediaStreamSource.connect(meter);

    if(messageUITarget) {
      var preRecAudioAlert = messageUITarget.querySelector("#lowAudioLevelAlert");
      var recAudioAlert = messageUITarget.querySelector("#recLowAudioLevelAlert");
    }

    // kick off the visual updating
    onLevelChange();

    function onLevelChange() {
      var isMicActive = micButton.classList.contains('active');
      canvasContext.clearRect(0, 0, WIDTH, HEIGHT); // clear the background
      canvasContext.fillStyle = "#E0EBFD";

      var actualWidth = meter.volume * maxWidth;

      canvasContext.fillRect(0, 0, actualWidth, HEIGHT); // draw a bar based on the current volume

      // Check if the audio level is reached 10% of the max width and set the "recording threshold reached flag" to true.
      if (actualWidth >= maxWidth * 0.1) {
        preRecAudioThresholdReached = true;

        if (recordingStateFlag)
          recStateAudioThresholdReached = true
      }

      if(audioLevelSet === false && micCheckMessage) {
        if(preRecAudioThresholdReached) {
          audioLevelSet = true
          micCheckMessage.innerHTML = 'Audio level is good!';
        } else {
          micCheckMessage.innerHTML = 'Speak into your mic to confirm your audio level';
        }
      }

      if (messageUITarget) {
        // Set the recording flag to true when this function executed first time after recording started
        if (recordingStateFlag === false && document.querySelector("#stopRecordingBtn").classList.contains("recording") && stream['active']) {
          preRecAudioThresholdReached = false
          recordingStateFlag = true;
          preRecAudioAlert.classList.add("hidden");
        }

        // Hide/show audio alert based on toggle state(on/off) of Mic
        if (!isMicActive) {
          preRecAudioThresholdReached = true;
          preRecAudioAlert.classList.add("hidden");
          recAudioAlert.classList.add("hidden");
        }

        if (recordingStateFlag) { // Control the recording state low level alert if it is in recording state
          preRecAudioAlert.classList.add("hidden");

          setTimeout(() => {
            if (recStateAudioThresholdReached) {
              recAudioAlert.classList.add("hidden");
            } else {
              preRecAudioAlert.classList.add("hidden");
              recAudioAlert.classList.remove("hidden");
            }
          }, audioInternalTimer)
        } else { // Control the pre-recording state low level alert if it is in pre-recording state
          setTimeout(() => {
            if (preRecAudioThresholdReached) {
              preRecAudioAlert.classList.add("hidden");
            } else {
              preRecAudioAlert.classList.remove("hidden");
            }
          }, audioInternalTimer)
        }
      }

      if(stream['active'] && isMicActive) // set up the next visual callback only if the stream is 'active'
        window.requestAnimationFrame(onLevelChange);
    }
  }

  createAudioMeter(audioContext,clipLevel,averaging,clipLag) {
    var processor = audioContext.createScriptProcessor(512);
    processor.onaudioprocess = this.volumeAudioProcess;
    processor.clipping = false;
    processor.lastClip = 0;
    processor.volume = 0;
    processor.clipLevel = clipLevel || 0.98;
    processor.averaging = averaging || 0.95;
    processor.clipLag = clipLag || 750;

    // this will have no effect, since we don't copy the input to the output,
    // but works around a current Chrome bug.
    processor.connect(audioContext.destination);

    processor.checkClipping =
      function(){
        if (!this.clipping)
          return false;
        if ((this.lastClip + this.clipLag) < window.performance.now())
          this.clipping = false;
        return this.clipping;
      };

    processor.shutdown =
      function(){
        this.disconnect();
        this.onaudioprocess = null;
      };

    return processor;
  }

  volumeAudioProcess( event ) {
    var buf = event.inputBuffer.getChannelData(0);
    var bufLength = buf.length;
    var sum = 0;
    var x;

    // Do a root-mean-square on the samples: sum up the squares...
    for (var i=0; i<bufLength; i++) {
      x = buf[i];
      if (Math.abs(x)>=this.clipLevel) {
        this.clipping = true;
        this.lastClip = window.performance.now();
      }
      sum += x * x;
    }

    // ... then take the square root of the sum.
    var rms =  Math.sqrt(sum / bufLength);

    // Now smooth this out with the averaging factor applied
    // to the previous sample - take the max here because we
    // want "fast attack, slow release."
    this.volume = Math.max(rms, this.volume * this.averaging);
  }
}
