// Used to control the conversation sidebar navigator

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = [
      'messagesNav',
      'sidebarFilterUnseenToggle',
      'statusWidget',
      'statusWidgetUnseenFilterToggle'
    ]

  static values = {
    loadUrl: String,
    userSignedIn: Boolean
  }

  connect() {
    if (this.hasMessagesNavTarget) {
      // When the page loads, load the sidebar navigation using ajax
      this.loadSidebar();

      if (this.userSignedInValue == true) {
        var cta_banner = document.querySelector("#upgrade-cta");

        // Set offsetTop to be the distance of the messages nav from the top of the viewport when the page is scrolled to the top.
        if (cta_banner) {
          this.offsetTop = 210;
        } else {
          this.offsetTop = 165;
        }

        this.isFixed = false;
      } else {
        this.offsetTop = 0;
        this.isFixed = true;      
      }

      // If the page is not scrolled to the top at the time the page loads, then run stickyNav() to ensure the sidebar is fully visible on load.
      if (window.pageYOffset !== 0) {
        this.stickyNav();
      }
    }
  }

  async loadSidebar() {
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = this.loadUrlValue
    link.setAttribute('data-remote', 'true')

    // Append to the document
    document.body.appendChild(link)

    // Trigger the click event manually
    await this.triggerClickEvent(link)

    // Remove the link from the DOM
    document.body.removeChild(link)

    // Apply class to handle scrollbar on sidebar nav if it overflows
    this.overflowNav();

    // apply .has-unseen to conversation threads that contain unseen messages for the user
  }

  triggerClickEvent(element) {
    return new Promise(resolve => {
      element.addEventListener('ajax:success', resolve, { once: true })
      element.click()
    })
  }

  scroll_to_message(event) {
    event.preventDefault(); 
    var navigate_link = event.target.closest(".message-link");
    var message_id = navigate_link.dataset.messageId;
    var message_thread_id = navigate_link.dataset.messageThreadId;
    var message = document.querySelector("#messages #message-"+message_id);
    if(message){
      message.scrollIntoView({behavior: "smooth", block: "start"});
    }
    else { // If the target message is not present on the page, click the "load all" link to load all messages
      var link = document.querySelector('.load-all-messages') || document.querySelector('.load-few-messages');
      if (link) {
        link.href += "&message_id=" + message_id + '&message_thread_id=' + message_thread_id;
        link.click()
      }
    }
  }

  stickyNav() {
    var distance_from_top = 0;
    if (window.scrollY >= this.offsetTop - distance_from_top && !this.isFixed) {
      this.messagesNavTarget.classList.add('fixed-nav');
      this.isFixed = true;
    } else if (window.scrollY < this.offsetTop - distance_from_top && this.isFixed) {
      this.messagesNavTarget.classList.remove('fixed-nav');
      this.isFixed = false;
    }
    this.overflowNav();
  }

  overflowNav() {
    // Applies class to nav so that bottom is styled to show user that they can scroll the overflowing nav
    var conversation_nav_links_container = this.messagesNavTarget.querySelector("#conversation-sidebar-links-container");

    if (conversation_nav_links_container) {
      // Check if the element's content overflows
      if (conversation_nav_links_container.scrollHeight > conversation_nav_links_container.clientHeight) {
        // If content overflows, add the class
        conversation_nav_links_container.classList.add('overflowing-nav');
      } else {
        // If content doesn't overflow, remove the class
        conversation_nav_links_container.classList.remove('overflowing-nav');
      } 
    }
  }

  toggleFilterUnseenMessageThreads() {
    var sidebar_settings = document.querySelector("#conversation-sidebar-section .section-settings.conversation-sidebar-options");
    var sidebar_filter_unseen_toggle = this.sidebarFilterUnseenToggleTarget;
    var status_widget = this.statusWidgetTarget;
    var status_widget_unseen_toggle = this.statusWidgetUnseenFilterToggleTarget;
    
    if (this.element.classList.contains("hiding-seen-message-threads")) { // We're showing all messages

      // Remove body class to show all messages
      this.element.classList.remove("hiding-seen-message-threads");

      // Remove active status from sidebar messages settings icon 
      sidebar_filter_unseen_toggle.classList.remove("active");
      if (sidebar_settings) {
        sidebar_settings.classList.remove("active");
      }

      // Hide the conversation status widget
      status_widget.classList.add("hidden");
      status_widget_unseen_toggle.classList.add("hidden");

      if (this.element.classList.contains("viewing-single-thread-view")) {
        messages.querySelectorAll('.message').forEach(message => {
          if (message && !message.classList.contains('unseen')){
            message.classList.remove('hidden');
          }
        })
      }

    } else { // We're hiding unseen messages

      // Add body class to hide unseen messages
      this.element.classList.add("hiding-seen-message-threads");

      // Add active status to sidebar messages settings icon 
      sidebar_filter_unseen_toggle.classList.add("active");
      if (sidebar_settings) {
        sidebar_settings.classList.add("active");
      }

      // Show the conversation status widget
      status_widget.classList.remove("hidden");
      status_widget_unseen_toggle.classList.remove("hidden");

      if (this.element.classList.contains("viewing-single-thread-view")) {
        messages.querySelectorAll('.message').forEach(message => {
          if (message && !message.classList.contains('unseen')){
            message.classList.add('hidden');
          }
        })
      }
    }

    // run overflowNav again since the sidebar height changed
    this.overflowNav();
  }

}