import {Controller} from 'stimulus'

export default class extends Controller {

  static targets = ["monthlyPlans", "monthlyToggle", "annualPlans", "annualToggle"]

  connect() {
    console.log('connected!');
  }

  toggleMonthly() {
    this.toggleSwitcherOn(this.monthlyToggleTarget);
    this.toggleSwitcherOff(this.annualToggleTarget);
    this.togglePanelOn(this.monthlyPlansTarget);
    this.togglePanelOff(this.annualPlansTarget);
  }

  toggleAnnual() {
    this.toggleSwitcherOff(this.monthlyToggleTarget);
    this.toggleSwitcherOn(this.annualToggleTarget);
    this.togglePanelOff(this.monthlyPlansTarget);
    this.togglePanelOn(this.annualPlansTarget);
  }

  toggleSwitcherOn(selection) {
    selection.classList.add("bg-tan-200", "text-black");
  }

  toggleSwitcherOff(selection) {
    selection.classList.remove("bg-tan-200", "text-black");
  }

  togglePanelOn(panel) {
    panel.classList.add("md:flex");
    panel.classList.remove("hidden", "md:hidden");
  }

  togglePanelOff(panel) {
    panel.classList.remove("md:flex");
    panel.classList.add("hidden", "md:hidden");
  }
}
