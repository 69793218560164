import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content"];

  connect() {}

  mouseOver() {
    this.contentTarget.classList.remove("hidden");
    this.adjustPositionIfOutsideViewport(this.contentTarget);
  }

  mouseOut() {
    this.contentTarget.classList.add("hidden");
  }

  adjustPositionIfOutsideViewport(element) {
    const rect = element.getBoundingClientRect();
    const triggerRect = this.element.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;

    let newTop;
    let newLeft;

    // Check if the element is outside the viewport
    if (rect.top < 0) {
      // Element is above the viewport, position it below the trigger
      element.style.bottom = "auto";
      element.style.top = "25px";
    } else if (rect.bottom > viewportHeight) {
      // Element is below the viewport, position it above the trigger
      element.style.top = "auto";
      element.style.bottom = "25px";
    } else {
      // Element is inside the viewport vertically, no change needed
    }

    if (rect.left < 0) {
      // Element is to the left of the viewport
      element.style.right = "auto";
      element.style.left = "0";
    } else if (rect.right > viewportWidth) {
      // Element is to the right of the viewport
      element.style.left = "auto";
      element.style.right = "0";
    } else {
      // Element is inside the viewport horizontally, no change needed
    }
  }
}
