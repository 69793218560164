import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["scheduleNewMessageIconBtn",
                    "scheduleNewMessageBlock",
                    "editScheduleBlock", 
                    "updateScheduleButton",
                    "scheduledBooleanInput",
                    "draftBooleanInput"]

  toggleScheduleNewMessageBlock() {
    if (this.scheduleNewMessageBlockTarget.classList.contains("hidden")) {
      // toggle open the scheduling block
      this.scheduleNewMessageBlockTarget.classList.remove("hidden");
      this.scheduleNewMessageBlockTarget.scrollIntoView({behavior: "smooth", block: "center"});
      this.scheduleNewMessageBlockTarget.querySelector("input#message_scheduled_published_at").focus();
      this.scheduleNewMessageIconBtnTarget.classList.add("active");

      // toggle the hidden scheduled and draft boolean fields to true
      this.toggleDraftAndScheduleBooleans(true);
    } else {
      // toggle closed the scheduling block
      this.scheduleNewMessageBlockTarget.classList.add("hidden");
      this.scheduleNewMessageIconBtnTarget.classList.remove("active");

      // toggle the hidden scheduled and draft boolean fields to false
      this.toggleDraftAndScheduleBooleans(false);
    }
  }

  cancelUpdateScheduleMessage(event) {
    var draft_message = event.target.closest(".draft-message");
    var edit_message_form = draft_message.querySelector("form#edit-message");
    edit_message_form.remove();

    // If message was scheduled already, unhide the scheduled info block
    var scheduled_info_block = draft_message.querySelector(".scheduled-info-block");
    if (scheduled_info_block) {
      scheduled_info_block.classList.remove("hidden");
    }
  }

  revealScheduleButton(event) {
    var draft_message = event.target.closest(".draft-message");
    if (draft_message) {
      this.updateScheduleButtonTarget.classList.remove("hidden");
    }
  }

  toggleDraftAndScheduleBooleans(value) {
    var draft_boolean = this.draftBooleanInputTarget;
    var scheduled_boolean = this.scheduledBooleanInputTarget;
    if (value == true) {
      draft_boolean.value = true;
      scheduled_boolean.value = true;
    } else if (value == false) {
      draft_boolean.value = false;
      scheduled_boolean.value = false;
    }
  }

}