// This handles the customer-facing purchase flow UI 

import { Controller } from "stimulus"

export default class extends Controller {

    static targets = [];

    static values = {}

    connect() {
    }
}