import {Controller} from 'stimulus'

export default class extends Controller {

  static targets = [
    "bookingPageUiContainer"
  ]

  showBookingUi() {
    var booking_ui = this.bookingPageUiContainerTarget;
    booking_ui.scrollIntoView({behavior: `smooth`, block: 'start'});
  }
}