import {Controller} from 'stimulus'

export default class extends Controller {

    static targets = ['conversationError']

    connect() {

    }

    updateConversationInSpace(event) {
        var conversation_id = event.target.value
        var space_container_slug = event.target.dataset.spaceContainerSlug
        var space_slug = event.target.dataset.spaceSlug

        var error = document.querySelector('.error-explanation')
        if (conversation_id === ''){
            if (error) error.innerHTML = ''
            this.conversationErrorTarget.classList.remove('hidden')
        }else {
            if (!this.conversationErrorTarget.classList.contains('hidden')) {
                this.conversationErrorTarget.classList.add('hidden');
            }
            Rails.ajax({
                type: "patch",
                url: '/manage_spaces/' + space_container_slug + '/' + space_slug + '/' + conversation_id + '/add_remove_conversation_in_space'
            })
        }
    }
}