import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['modal']

  static values = {
  }

  connect() {

    if (this.hasModalTarget) {
      var new_respondent = window.location.href.indexOf("new_respondent=true") > -1;
      var via_intake = window.location.href.indexOf("via_intake=true") > -1;
      
      if (new_respondent || via_intake) {
        var modal = this.modalTarget;
        modal.classList.remove("hidden");
        var delayInMilliseconds = 1000; //miliseconds
        setTimeout(function() {
          modal.classList.add("modal-transition-in");
        }, delayInMilliseconds);
      }
    }
    
  }
  
}