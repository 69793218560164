import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["editTagForm", "tagLink"];

  cancelEdit(event) {
    this.editTagFormTarget.parentNode.removeChild(this.editTagFormTarget);
    this.tagLinkTarget.classList.remove("hidden");

    var edit_tag_button = this.element.querySelector(".edit-tag-button");
    if (edit_tag_button) {
      edit_tag_button.classList.remove("hidden");
    }
  }

}