import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['chromeInfo',
                    'firefoxInfo',
                    'safariInfo'
                    ];

  connect() {

    // Browser detection
    const sUsrAg = navigator.userAgent.toLowerCase();
    let isChrome = false;
    let isFirefox = false;
    let isSafari = false;
    let safari_version;
    if(sUsrAg.indexOf('chrome') > -1){
      isChrome = true;
    }
    if(sUsrAg.indexOf('firefox') > -1){
      isFirefox = true;
    }
    if (sUsrAg.indexOf('safari') !== -1 && sUsrAg.indexOf('chrome') === -1) {
      isSafari = true;
      safari_version = sUsrAg.substring(
          sUsrAg.indexOf('version')+8,
          sUsrAg.indexOf('version')+12
      );
    }

    if (isChrome) {
      this.chromeInfoTarget.classList.remove("hidden");
      this.firefoxInfoTarget.classList.add("hidden");
      this.safariInfoTarget.classList.add("hidden");
    } else if (isFirefox) {
      this.chromeInfoTarget.classList.add("hidden");
      this.firefoxInfoTarget.classList.remove("hidden");
      this.safariInfoTarget.classList.add("hidden");
    } else if (isSafari) {
      this.chromeInfoTarget.classList.add("hidden");
      this.firefoxInfoTarget.classList.add("hidden");
      this.safariInfoTarget.classList.remove("hidden");
    } else {
      // Assume Chrome
      this.chromeInfoTarget.classList.remove("hidden");
      this.firefoxInfoTarget.classList.add("hidden");
      this.safariInfoTarget.classList.add("hidden");
    }

  }
    
}