// This handles the Zoom effect when scrolling through messages in a conversation

import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['mediaMessage', 
                    'scrollZoomOnBtn',
                    'scrollZoomOffBtn'
                    ]

  static values = {
    scrollMessagesSetting: Boolean
  }

  initialize() {
    var scroll_zoom_setting = localStorage.getItem('scroll-zoom');
    var supports_intersectionOptions = ('IntersectionObserver' in window) || ('IntersectionObserverEntry' in window) || ('intersectionRatio' in window.IntersectionObserverEntry.prototype);
    if ((scroll_zoom_setting && scroll_zoom_setting == 'off') || !supports_intersectionOptions) { // Scroll Zoom setting is off OR IntersectionObserver isn't supported in this browser
      // Set Zoom Scrolling to Off state
      this.element.classList.add("disable-zoom-effect");      
    } else { // Scroll Zoom effect is on

      var mediaMessages = this.mediaMessageTargets;
      initZoomEffect(mediaMessages);
    }

  }

  toggle_zoom_effect(event) {
    var body_tag = this.element;
    var zoom_on_btn = this.scrollZoomOnBtnTarget;
    var zoom_off_btn = this.scrollZoomOffBtnTarget;

    if (body_tag.classList.contains("disable-zoom-effect")) {
      // Set Zoom on state
      this.element.classList.remove("disable-zoom-effect");
      localStorage.setItem('scroll-zoom', 'on');
      
      // Initialize Scrolling effect
      var mediaMessages = this.mediaMessageTargets;
      initZoomEffect(mediaMessages);
    } else {
      // Set Zoom off state
      this.element.classList.add("disable-zoom-effect");
      localStorage.setItem('scroll-zoom', 'off');
    }
  }

}

function intersectionCallback(entries) {
  entries.forEach(function(entry) {
    if (entry.isIntersecting) {
      // Add the focused class:
      entry.target.classList.add("focused");
    } else {
      // Otherwise remove the focused class
      entry.target.classList.remove("focused");
    }
  });
}

function initZoomEffect(entries) {
  // Set up the observer for messages entering the viewport
  let intersectionOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.6
  };

  const intersectionObserver = new IntersectionObserver(intersectionCallback, intersectionOptions);
  entries.forEach(function(entry) {
    // Add each message (with media) to the watcher
    intersectionObserver.observe(entry);
  });
}