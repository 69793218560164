 import { Controller } from "stimulus"
import mixpanel from 'mixpanel-browser'

export default class extends Controller {

  static targets = []

  static values = {
    page: String,
    userId: Number
  }

  connect() {
    // To see the mixpanel event in JS console, uncommon this line and comment out the one below it.
    mixpanel.init('76d721eb45e7fcac6d16d683cfbb8314', {debug: true}); 
    // mixpanel.init('76d721eb45e7fcac6d16d683cfbb8314'); 

    // Identify this user if they're logged in
    if (this.userIdValue) {
      mixpanel.identify(this.userIdValue);
    }

    // Track user viewing any of our account signup views as the first step of our funnel.
    // All are tracked in the 'View Account Signup Page' event, with property "page_name" to distinguish which path they took.
    var account_creation_pages = ["Public Account Signup", "Additional Account Signup", "Respondent Account Signup"];
    if (account_creation_pages.includes(this.pageValue)) {
      mixpanel.track('Viewed Account Signup Page', {"page_name": this.pageValue});
    }
    
  }

  copy_conversation_email(event) {
    var copy_button = document.querySelector(".copy-conversation-email-button");
    var user_id = copy_button.dataset.mixpanelUserId;
    mixpanel.track('Share Conversation via Email', {"$user_id": user_id, "copied_to_clipboard": true});
  }

}