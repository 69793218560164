// Used for handling single-file upload fields with a drag and drop area.
// If we need a multi-file upload area, use dropzone.

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['dropArea', 'input', 'filesList', 'templateFileElement', 'mobileLabel'];

  dragOver(event) {
    event.preventDefault();
    this.dropAreaTarget.classList.add("border-gray-400");
  }

  dragLeave(event) {
    event.preventDefault();
    this.dropAreaTarget.classList.remove("border-gray-400");
  }

  inputFiles(event) {
    event.preventDefault();
    this.filesListTarget.classList.remove("hidden");

    if (event.dataTransfer) { // file was dropped into the drop area on desktop
      var files = event.dataTransfer.files;
    } else { // label was clicked and user browsed and selected file(s)
      var files = event.target.files;
      if (this.hasMobileLabelTarget) {
        this.mobileLabelTarget.innerHTML = "Media is ready to save..."
      }
    }

    var templateFileElement = this.templateFileElementTarget;

    this.inputTarget.files = files;
    
    // Since we've already selected the 1 file to upload, hide the drop area
    this.dropAreaTarget.classList.add("hidden");

    // Show the selected file
    templateFileElement.querySelector(".filename").innerHTML = files[0].name;
    templateFileElement.classList.remove("hidden");
  }

  removeFile(event) {
    var oldFilesList = this.inputTarget.files;
    var fileElementToRemove = event.target.closest(".file");
    var fileNameToRemove = fileElementToRemove.dataset.fileName;

    // Create a DataTransfer to transfer the files from the oldFilesList to the new
    const dT = new DataTransfer();
    for (let file of oldFilesList) {
      if (file.name != fileNameToRemove) {
        dT.items.add(new File(['name'], file.name));
      }
    }

    // Replace the old input with the new
    this.replaceInput();
    var newInput = this.element.querySelector("input[data-file-uploads-target='input']");

    // Set the new input's fileList to dT.files
    newInput.files = dT.files;
    console.log(newInput.files);

    // Remove the file element from the DOM
    fileElementToRemove.remove();

    // If we've removed the only file, hide the files list
    if (newInput.files.length == 0) {
      this.filesListTarget.classList.add("hidden");
    }
  }

  resetField(event) {
    this.replaceInput();
    // Show the drop area and the hide the files list to reset
    this.filesListTarget.classList.add("hidden");
    this.dropAreaTarget.classList.remove("hidden");
  }

  replaceInput() {
    // Clear the file(s) from the input by replacing the old input with a new one with all the same attributes
    var oldInput = this.inputTarget;
    var newInput = document.createElement("input"); 
    for (let attribute of oldInput.attributes) {
      newInput.setAttribute(attribute.nodeName ,attribute.nodeValue);
    }
    oldInput.insertAdjacentElement("afterend", newInput);
    oldInput.remove();
  }

}