// When editing a product, this stimulus controller is used for both of these, since their UI's are very similar:
// purchase-actions
// subscription-end-actions

import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = [
    'submitButton',
    'newActionForm',
    'actionsList',
    'newActionButton',
    'noActions'
  ];

  static values = {
    productSlug: String
  }

  connect() {
  }

  toggle() {
  }

  cancelNewAction(event) {
    this.newActionFormTarget.remove();

    if (this.hasActionsListTarget) {
      this.newActionButtonTarget.classList.remove("hidden");
    } else {
      this.noActionsTarget.classList.remove("hidden");
    }
  }

  revealSubmitBtn() {
    this.submitButtonTarget.classList.remove('hidden');
  }

  revealContactAttributeValueField(event) {
    var selected_contact_attribute_id = event.target.value;
    console.log("selected_contact_attribute_id", selected_contact_attribute_id);
    var action_id = event.target.dataset.actionId;
    var actions_type = event.target.dataset.actionsType;
    var param;

    if (actions_type === 'subscription_end_actions')
      var param = `${actions_type}_id=${event.target.dataset.subscriptionEndActionId}`
    else
      var param = `${actions_type}_id=${event.target.dataset.purchaseActionId}`

    const url = `/products/${this.productSlugValue}/fetch_contact_attribute_value_field?selected_contact_attribute_id=${selected_contact_attribute_id}&actions_type=${actions_type}&action_id=${action_id}&${param}`;
    this.fetch_action_field(url);
  }

  fetch_action_field(url) {
    Rails.ajax({
      type: "GET",
      url: url,
      dataType: 'script',  
      success: (data) => {
        // Successfully fetched redirect_rule_form
      },
      error: (err) => {
        console.error("Failed to fetch action field:", err);
      }
    });
  }

}