// This controls view-specific searching of specific objects.  For example, searching objects in an index view, or searching contacts in the invitation flow, and other searchable areas of the app.

import search_methods from "./lib/search_methods";
import { Controller } from "stimulus";
import { debounce } from "helpers";

export default class extends Controller {
  static targets = [
    "searchInput",
    "unfilteredList",
    "filteredList",
    "clearSearchIcon",
    "hideWhenFiltered",
    "noResults",
    "newObjectNameContainer",
  ];

  static values = {
    searchPath: String,
  };

  connect() {
    window.objectsSearchInitializer = new search_methods(
      this.searchInputTarget,
      this.searchResultsContainerTarget,
      this.searchResultsTarget
    );
    this.search = debounce(this.search, 200);
  }

  toggleFocus(event) {
    if (event.key == "/") {
      this.searchInputTarget.focus();
    } else if (event.key == "Escape" || event.type == "click") {
      this.filteredListTarget.classList.add("hidden");
      if (this.hasUnfilteredListTarget) {
        this.unfilteredListTarget.classList.remove("hidden");
      }
      this.searchInputTarget.value = "";
      this.searchInputTarget.blur();
      this.clearSearchIconTarget.classList.add("hidden");
      for (let element of this.hideWhenFilteredTargets) {
        element.classList.remove("hidden");
      }
    }
  }

  search(event) {
    var inputValue = this.searchInputTarget.value;
    var params = new URLSearchParams({ q: inputValue });

    if (this.searchPathValue.indexOf("?") > -1) {
      var searchPath = this.searchPathValue + "&" + params;
    } else {
      var searchPath = this.searchPathValue + "?" + params;
    }

    if (this.hasUnfilteredListTarget) {
      var unfilteredListContainer = this.unfilteredListTarget;
    }
    var filteredListContainer = this.filteredListTarget;
    var noResultsContainer = this.noResultsTarget;
    var hideElementsWhenFiltered = this.hideWhenFilteredTargets;
    if (this.hasNewObjectNameContainerTarget) {
      var newObjectNameContainer = this.newObjectNameContainerTarget;
    }

    if (unfilteredListContainer) {
      unfilteredListContainer.classList.add("hidden");
    }

    if (
      this.searchInputTarget &&
      event.keyCode != 40 &&
      event.keyCode != 38 &&
      event.key != "Escape"
    ) {
      // 38 = arrow_up, 40 = arrow_down
      if (
        typeof inputValue != "undefined" &&
        inputValue != null &&
        inputValue.trim().length !== 0
      ) {
        window.objectsSearchInitializer
          .get_search_results(searchPath)
          .then((response) => {
            this.clearSearchIconTarget.classList.remove("hidden");
            for (let element of hideElementsWhenFiltered) {
              element.classList.add("hidden");
            }
            if (response === undefined) {
              this.noResultsTarget.classList.remove("hidden");
              filteredListContainer.classList.add("hidden");
              if (unfilteredListContainer) {
                unfilteredListContainer.classList.add("hidden");
              }
              if (newObjectNameContainer) {
                newObjectNameContainer.innerHTML = inputValue;
              }
            } else {
              noResultsContainer.classList.add("hidden");
              filteredListContainer.classList.remove("hidden");
              filteredListContainer.innerHTML = response;
            }
          });
      }

      if (inputValue == "") {
        // user deleted contents of input field, making it blank
        this.noResultsTarget.classList.add("hidden");
        this.clearSearchIconTarget.classList.add("hidden");
        if (unfilteredListContainer) {
          unfilteredListContainer.classList.remove("hidden");
        }
        filteredListContainer.classList.add("hidden");
        for (let element of hideElementsWhenFiltered) {
          element.classList.remove("hidden");
        }
      }
    }
  }

  navigate(event) {
    var filteredListContainer = this.filteredListTarget;
    if (
      !filteredListContainer.classList.contains("hidden") &&
      (event.keyCode == 40 || event.keyCode == 38)
    ) {
      var search_results = Array.from(
        filteredListContainer.querySelectorAll("a.searchable-object")
      );
      var focused_result = filteredListContainer.querySelector(
        "a.searchable-object:focus"
      );
      var i = 0;

      if (event.keyCode == 38) {
        // UP key is pressed
        event.preventDefault();
        if (focused_result) {
          i = search_results.indexOf(focused_result);
          if (i == 0) {
            this.searchInputTarget.focus();
          } else {
            i = i - 1;
            search_results[i].focus();
          }
        } else {
          search_results[i].focus();
        }
      }
      if (event.keyCode == 40) {
        // DOWN key is pressed
        event.preventDefault();
        if (focused_result) {
          i = search_results.indexOf(focused_result);
          if (i == search_results.length - 1) {
            this.searchInputTarget.focus();
          } else {
            i = i + 1;
            search_results[i].focus();
          }
        } else {
          search_results[i].focus();
        }
      }
    }
  }
}
