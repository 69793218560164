import {Controller} from "stimulus";

export default class extends Controller {

    connect() {
    }

    revealFormFields(event){
        var selectedFormId = event.target.value;
        var reveal_form_url = '/reveal_form_block/?form_id=' + selectedFormId

        Rails.ajax({
            type: "get",
            url: reveal_form_url,
        })
    }
}