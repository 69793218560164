const RESET_TIMEOUT_MILLIS = 3000;
const CONFIRMATION_MESSAGE = 'Are you sure?';

import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['link', 'confirmMessageContainer']

  connect() {
    this.delete = false;
  }

  click(event) {
    if(this.hasLinkTarget) {
      if(this.linkTarget.dataset.linkDisabled === 'true')
        return
    }

    let preventRemove = this.element.getAttribute("data-prevent-remove");
    if(this.delete && preventRemove) {
      this.resetState();
    } else if (this.delete && (preventRemove === undefined || preventRemove === null)) {
      this.delete = false;
      this.linkTarget.addEventListener('ajax:success', this.handleSuccess.bind(this))
      this.linkTarget.addEventListener('ajax:error', this.handleError.bind(this))
    } else {
      if (this.hasConfirmMessageContainerTarget) {
        var confirm_container = this.confirmMessageContainerTarget;
      } else {
        var confirm_container = this.linkTarget;
      }
      this.oldMessage = this.linkTarget.innerHTML;
      var custom_confirmation_message = this.element.dataset.confirmationMessage || this.element.dataset.deleteConfirmationMessage;
      if (custom_confirmation_message) {
        confirm_container.innerHTML = custom_confirmation_message
      } else {
        confirm_container.innerHTML = CONFIRMATION_MESSAGE;
      }
      this.delete = true;
      this.timeout = setTimeout(() => {
        this.resetState();
      }, RESET_TIMEOUT_MILLIS);
      event.preventDefault();
      event.stopImmediatePropagation()
      return false;
    }
  }

  handleSuccess(event) {
    clearTimeout(this.timeout);
    var messageTemplateId = this.linkTarget.dataset.messageTemplateId;
    if(messageTemplateId)
      return
    this.element.style = 'display: none;';

    if (this.element.parentNode) {
      this.element.parentNode.removeChild(this.element);
    }
  }

  handleError(event) {
    clearTimeout(this.timeout);
    this.resetState();
    this.element.style = '';
  }

  resetState() {
    var message;
    if (this.delete && this.hasLinkTarget) {
      this.linkTarget.removeEventListener('ajax:success', this.handleSuccess.bind(this))
      this.linkTarget.removeEventListener('ajax:error', this.handleError.bind(this))
      if(this.linkTarget.getAttribute('data-old-delete-text')){
        message = this.linkTarget.getAttribute('data-old-delete-text')
      }else{
        message = this.oldMessage;
      }
      this.linkTarget.innerHTML = message;
      this.delete = false;
    }
  }
  

  // This action is used specifically in the message creation UI, to discard a recording before saving it
  discardRecording(event) {
    if (this.linkTarget.getAttribute('data-prevent-execution') !== null)
      return

    this.click(event);
    this.linkTarget.removeAttribute("data-discard-recording")
    this.linkTarget.setAttribute("data-action", "click->message--creation#discard_and_re_record")

    setTimeout(() => {
      this.linkTarget.setAttribute("data-action", "click->delete#discardRecording")
    }, RESET_TIMEOUT_MILLIS);
  }
}