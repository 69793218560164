import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["embedCodeInput"]

  connect() {
  }

  // When user obtains the intake page embed code, they can toggle ?show_headline off/on in the src URL using this action
  toggleHeadline(event) {
    var input = this.embedCodeInputTarget;
    var input_value = input.value;
    var copy_element = this.element.querySelector("div[data-copy-string-value]");

    // If show_headline is present, remove it.
    if (input_value.includes("show_headline=true")) {
      input.value = input_value.replace("?show_headline=true", ""); // replace in the hidden input
      copy_element.dataset.copyStringValue = input_value.replace("?show_headline=true", ""); // replace the copy element data attr
    } else { // show_headline is not present, so add it.
      var src_index = input_value.indexOf("src='") + 5;
      var src_value = input_value.substring(src_index, input_value.indexOf("'", src_index));
      input.value = input_value.replace(src_value, src_value+"?show_headline=true"); // replace in the hidden input
      copy_element.dataset.copyStringValue = input_value.replace(src_value, src_value+"?show_headline=true"); // replace the copy element data attr
    }

  }

}