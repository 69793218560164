// Set on the form for creating a program_item or message_template within a program_item's thread_template

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
  ];

  connect() {
    var libraryPanelToggle = this.element.querySelector(".media-panel-toggle[data-message-type-id='library']");
    libraryPanelToggle.click();
  }

}