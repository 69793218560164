import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["downloadInstruction", "uploadInstruction"]

  static values = {
  }

  connect() {
    var new_message_form = document.querySelector("#new_message");
    if (!new_message_form) { // it's an intake page
      new_message_form = document.querySelector("#new-conversation");
    }
    var download_button = new_message_form.querySelector(".more-controls-buttons #downloadBtn");

    if (!download_button.classList.contains("hidden")) {
      this.downloadInstructionTarget.classList.remove("hidden");
      this.uploadInstructionTarget.classList.remove("hidden");

      // Number the steps apprioriately
      var steps = this.element.querySelectorAll(".clarityflow-login-instruction-step");
      var counter = 1;
      for (let step of steps) {
        if (!step.classList.contains("hidden")) {
          step.querySelector(".clarityflow-login-step-number").innerHTML = counter;
          counter++;
        }
      }
    }
  }

  downloadRecording() {
    var new_message_form = document.querySelector("#new_message");
    if (!new_message_form) { // it's an intake page
      new_message_form = document.querySelector("#new-conversation");
    }
    var download_button = new_message_form.querySelector(".more-controls-buttons #downloadBtn");
    download_button.click();
  }

}