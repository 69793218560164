import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["tab", "panel"]

  connect() {
  }

  toggle(event) {
    var selected_tab = event.target.closest(".tab");
    var selected_tab_id = selected_tab.dataset.tabId;
    var selected_panel = this.element.querySelector(".panel[data-panel-id='" + selected_tab_id + "']");

    // Toggle tabs
    for (let tab of this.tabTargets) {
      tab.classList.remove("active");
    }
    if (selected_tab.classList.contains("active")) {
      selected_tab.classList.remove("active");
    } else {
      selected_tab.classList.add("active");
    }

    // Toggle panels
    for (let panel of this.panelTargets) {
      panel.classList.add("hidden");
    }
    selected_panel.classList.remove("hidden");
  }

}