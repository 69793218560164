import { Controller } from "stimulus"

export default class extends Controller {

  static targets = []

  static values = {
  }

  connect() {
    // If a sub-section has .open-on-load class, then click its section header link to trigger the opening and loading of this subnav content
    var subnav_to_open_on_load = this.element.querySelector(".open-on-load");
    if (subnav_to_open_on_load) {
      var trigger_link = subnav_to_open_on_load.querySelector(".sub-section-header-link");
      trigger_link.click();
    }
  }

}