import { Controller } from "stimulus";


export default class extends Controller {

  connect() {
    var newMessageForm = document.getElementById("new_message");
    var newConversationForm = document.getElementById("new-conversation");
    var form;

    if(newConversationForm) {
      form = newConversationForm;
    } else if (newMessageForm) {
      form = newMessageForm;
    }

    var downloadBtn = form.querySelector("#downloadBtn");

    if(form && downloadBtn && (downloadBtn.href !== undefined || downloadBtn.href !== null)) {
      var recordingError = document.getElementById("recording-error");
      recordingError.classList.remove("hidden");
    }
  }

  downloadBlob() {
    var downloadBtn = document.querySelector("#downloadBtn");

    if(downloadBtn.href !== undefined) {
      downloadBtn.click();
    }
  }

}