// When editing a product, this stimulus controller is used on the after purchase (redirect_rule) options

import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = [
  ];

  static values = {
    productSlug: String,
  }

  connect() {
  }

  toggleRedirectType(event) {
    var selected_redirect_type = event.target.value;
    const url = `/products/${this.productSlugValue}/reload_redirect_rule_form?selected_redirect_type=${selected_redirect_type}`;
    this.fetch_redirect_rule_form(url);
  }

  toggleSpaceType(event) {
    var selected_space_type = event.target.value;
    const url = `/products/${this.productSlugValue}/reload_redirect_rule_form?selected_redirect_type=space&selected_space_type=${selected_space_type}`;
    this.fetch_redirect_rule_form(url);
  }

  fetch_redirect_rule_form(url) {
    Rails.ajax({
      type: "GET",
      url: url,
      dataType: 'script',  
      success: (data) => {
        // Successfully fetched redirect_rule_form
      },
      error: (err) => {
        console.error("Failed to fetch redirect_rule_form:", err);
      }
    });
  }

}