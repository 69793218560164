import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cloneButton", "clonesContainer", "elementToClone", "pluralizeMe"]

  static values = {
  }

  connect() {
  }

  cloneElement(){
    var elementToClone = this.elementToCloneTargets[0];
    var clonedElement = elementToClone.cloneNode(true);
    var insertedElement = this.clonesContainerTarget.insertAdjacentElement('beforeend', clonedElement);

    // If the cloned element contains an input, focus the cloned input
    var input = insertedElement.querySelector('input');
    if (input) {
      input.value = '';
      input.focus();
    }

    // If we need to pluralize a label's text when element is cloned
    var pluralizeElement = this.pluralizeMeTarget;
    if (pluralizeElement) {
      var text = pluralizeElement.innerText;
      if (!text.endsWith('s')) {
        pluralizeElement.innerHTML = text+"s";
      }
    }
  }
  
}

