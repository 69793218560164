import {Controller} from 'stimulus'

export default class extends Controller {

    static targets = ['name', 'description']

    static values = {}

    connect() {
    }

    changeDefaultToggleLabel(event) {
        var invitationType = event.target.value
        var toggleLabel = document.querySelector('#invitation-template-invitation-type')
        if (toggleLabel) {
            if (invitationType === 'space_conversation') {
                var invitationTypeValue = 'group_conversation'
            } else if (invitationType === 'space_membership') {
                var invitationTypeValue = 'group_membership'
            } else if (invitationType === 'program_enrollment') {
                var invitationTypeValue = 'course_enrollment'
            } else {
                var invitationTypeValue = invitationType
            }
            toggleLabel.innerText = invitationTypeValue.replace(/_/g, ' ')
        }
        if (this.hasNameTarget && this.hasDescriptionTarget) {
            var namePlaceholders = this.nameTarget.dataset.placeholders
            var descPlaceholders = this.descriptionTarget.dataset.placeholders

            // Parse the placeholders JSON string to an object
            var namePlaceholdersObj = JSON.parse(namePlaceholders)
            var descPlaceholdersObj = JSON.parse(descPlaceholders)

            // Update the placeholder of the name field
            if (this.hasNameTarget && namePlaceholdersObj.hasOwnProperty(invitationType)) {
                this.nameTarget.placeholder = namePlaceholdersObj[invitationType]
            }

            // Update the placeholder of the description field
            if (this.hasDescriptionTarget && descPlaceholdersObj.hasOwnProperty(invitationType)) {
                this.descriptionTarget.placeholder = descPlaceholdersObj[invitationType]
            }
        }
    }
}