import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["continueBtn", "termsCheckbox"];

  connect() {
  }

  toggleTerms() {
    if (this.termsCheckboxTarget.checked) {
      this.continueBtnTarget.classList.remove("btn-white", "text-gray-400");
      this.continueBtnTarget.classList.add("btn-primary");
      this.continueBtnTarget.removeAttribute("disabled");
    } else {
      this.continueBtnTarget.classList.add("btn-white", "text-gray-400");
      this.continueBtnTarget.classList.remove("btn-primary");
      this.continueBtnTarget.setAttribute("disabled", true);
    }
  }

}