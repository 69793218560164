import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["newTagButton", "createTagForm"];

  connect() {
  }

  cancelCreate(event) {
    this.createTagFormTarget.remove();
    this.newTagButtonTarget.classList.remove("hidden");
  }

}