import {Controller} from "stimulus";


export default class extends Controller {
  static targets = ["toggles", "messageTemplate", "threadTemplate", "searchFieldContainer", "librarySearch", "messageTemplateSelectField", "threadTemplateSelectField", "searchField", 'clearSearchIcon', 'searchResult', "selectedResult", "messageAuthorDropdown"]

  static values = {}

  connect() {
  }

  toggleMessageTemplate() {
    this.togglesTarget.classList.add("hidden");
    this.messageTemplateTarget.classList.remove("hidden");
    if (this.hasThreadTemplateTarget)
      this.threadTemplateTarget.classList.add("hidden");
  }

  toggleThreadTemplate() {
    this.togglesTarget.classList.add("hidden");
    this.messageTemplateTarget.classList.add("hidden");
    this.threadTemplateTarget.classList.remove("hidden");
  }

  cancel() {
    this.togglesTarget.classList.remove("hidden");
    this.messageTemplateTarget.classList.add("hidden");
    this.threadTemplateTarget.classList.add("hidden");
  }

  selectTemplates(event) {
    this.togglesTarget.classList.remove("hidden");
    this.librarySearchTarget.classList.add("hidden");
  }

  searchTemplates() {
    this.togglesTarget.classList.add("hidden");
    this.messageTemplateTarget.classList.add("hidden");
    this.threadTemplateTarget.classList.add("hidden");
    this.librarySearchTarget.classList.remove("hidden");
  }

  setThreadTemplate(event) {
    var thread_template_id = event.target.closest('.search-result').dataset.templateId
    this.threadTemplateSelectFieldTarget.value = thread_template_id;
    this.messageTemplateSelectFieldTarget.value = '';
  }

  setMessageTemplate(event) {
    var message_template_id = event.target.closest('.search-result').dataset.templateId
    this.messageTemplateSelectFieldTarget.value = message_template_id;
    if (this.hasThreadTemplateSelectFieldTarget) this.threadTemplateSelectFieldTarget.value = '';
  }

  setSearchField(event) {
    this.searchFieldTarget.value = event.target.closest('.search-result').dataset.templateName;
  }

  resetSearchField() {
    this.searchFieldContainerTarget.classList.remove("hidden");
    this.searchFieldTarget.focus()
    if (this.hasSearchResultTarget) {
      this.selectedResultTarget.classList.add('hidden')
      var selected_template = this.selectedResultTarget.querySelector('.selected-template')
      if (selected_template) {
        selected_template.innerHTML = '';
      }
    }
    this.searchFieldTarget.value = ''
    this.searchResultTarget.innerHTML = ''
    this.clearSearchIconTarget.classList.add("hidden");
    if (this.hasThreadTemplateSelectFieldTarget) this.threadTemplateSelectFieldTarget.value = '';
    if (this.hasMessageTemplateSelectFieldTarget) this.messageTemplateSelectFieldTarget.value = '';
    if (this.hasMessageAuthorDropdownTarget){
      this.messageAuthorDropdownTarget.innerHTML = '';
    }
  }

  display_library(event) {
    var template_type = event.target.closest('.search-result').dataset.templateType
    if (template_type === 'ThreadTemplate') {
      var params = new URLSearchParams({
        thread_template_id: event.target.closest('.search-result').dataset.templateId,
      });
    } else if (template_type === 'MessageTemplate') {
      var params = new URLSearchParams({
        message_template_id: event.target.closest('.search-result').dataset.templateId,
      });
    }


    var path = `/${accountId}/display_selected_template?${params}`;
    Rails.ajax({
      type: "get",
      url: path
    })

  }
}