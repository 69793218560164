import { Controller } from 'stimulus'

export default class extends Controller {

    static targets = ["toggleable"]

    static values = {
    }

    connect() {
    }
    
    toggle(event) {
      var toggleable_elements = this.toggleableTargets;

      for (let toggleable_element of toggleable_elements) {
        if (toggleable_element.classList.contains("hidden")) {
          toggleable_element.classList.remove("hidden");
        } else {
          toggleable_element.classList.add("hidden");
        }
      }
    }
}