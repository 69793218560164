// `screen_media_stream` function will get the screen + camera + mic stream by requesting the users for access
//
// Full Screen
// If User shares full Screen then PiP will be used to record camera preview
// Once the permissions are granted full screen stream will be returned
//
// Windowed screen
// If User shares windowed Screen then PiP window will be opened
// but both screen and camera preview will be rendered in HTML canvas
// Each frame rendered in HTML canvas will be shared as a stream
//
// IMPORTANT FUNCTIONS
// getDevices()
// gotDevices()
// getStream()
// gotStream()
// getCameraStream()
// getAudioStream()
// drawVideo()
// Above mentioned functions are the main functions used to get the screen stream


import DetectAudioLevel from "../concerns/detect_audio_level";

export default function screen_media_stream() {
  var previewElement = this.previewTarget;
  var audioSource = this.selectAudioSourceTarget;
  var videoSource = this.selectVideoSourceTarget;
  var resetButton = this.resetBtnTarget;
  var audioSelect = this.selectAudioSourceTarget.querySelector("select");
  var videoSelect = this.selectVideoSourceTarget.querySelector("select");
  var startScreenCameraBtn = document.getElementById("startScreenCameraRecordingBtn");
  var selectCameraLayout = this.selectCameraLayoutTarget;
  var cameraLayoutSelect = this.selectCameraLayoutTarget.querySelector("fieldset");
  var startCameraRecordBtn = this.startCameraRecordBtnTarget;
  var startScreenRecordBtn = this.startScreenRecordBtnTarget;
  var startMicRecordBtn = this.startMicRecordBtnTarget;
  var loadingIcon = this.loadingIconTarget;
  var mediaField = this.mediaFieldTarget;
  var preRecordPipBtnTarget = this.preRecordPipBtnTarget;
  var duringRecordPipBtnTarget = this.duringRecordPipBtnTarget;
  var canDrawInCanvas = false;
  var recordingStopped = false;
  var frameRate = 22;
  var stopAnim;
  var currentTabVisibility, timeoutReference, displaySurface, entireScreen, pipOpen, canvas, context;
  let audioStream, cameraStream, screenStream;
  var activeMediaElements = this.activeMediaTargets;
  var activeMedia = [];
  var camera_video = document.createElement("video");
  var camMicSelectorsTarget = this.camMicSelectorsTarget
  var messageUITarget = this.messageUITarget

  camera_video.classList.add("hidden");
  previewElement.insertAdjacentElement("afterend", camera_video);

  const sUsrAg = navigator.userAgent.toLowerCase();
  let isSafari = false;
  if (sUsrAg.indexOf('safari') !== -1 && sUsrAg.indexOf('chrome') === -1) {
    isSafari = true;
    entireScreen = true;
    preRecordPipBtnTarget.dataset.cameraOn = "false";
  }

  // When Audio, Camera or Camera layout is changed stop the current stream and request access to new device
  if(isSafari){
    audioSelect.onchange = startStream;
    videoSelect.onchange = startStream;
  } else {
    audioSelect.onchange = getStream;
    videoSelect.onchange = getStream;
  }
  // When camera Layout is changed in windowed mode then reset HTML canvas stream
  cameraLayoutSelect.onchange = restartCanvas;

  // Show and hide media like audio, camera icon in `start recording` button
  showHideActiveMediaElements.call(this);

  // start a dummy stream
  if(!isSafari) startPip().then();

  // Get previously used devices for recording
  if((localStorage.getItem("zipmessage-camera") ||
      localStorage.getItem("zipmessage-mic") ||
      localStorage.getItem("zipmessage-camera-layout")) && !isSafari) {
    // Call `gotDevices` before getStream to pass previously used devices
    getDevices().then(gotDevices).then(() => getStream.call(this)).then(getDevices).then(gotDevices);

    if(localStorage.getItem("zipmessage-camera-layout"))
      choosePreviousCameraLayout();
  } else if(isSafari) {
    getStream.call(this);
  } else {
    getStream.call(this).then(getDevices).then(gotDevices);
  }

  // Listen for stop event and stop animation
  listenStopEvent.call(this);

  // ---MAIN FUNCTION STARTS---

  function getDevices() {
    // return all the media devices present in the system
    return navigator.mediaDevices.enumerateDevices();
  }

  function gotDevices(deviceInfos) {
    // based on the devices present update media settings dropdown
    videoSelect.innerHTML = "";
    audioSelect.innerHTML = "";
    window.deviceInfos = deviceInfos; // make available to console


    console.log('Available input and output devices:', deviceInfos);
    for (const deviceInfo of deviceInfos) {
      const option = document.createElement('option');
      option.value = deviceInfo.deviceId;

      let layoutCamera = localStorage.getItem("zipmessage-camera");
      let layoutMic = localStorage.getItem("zipmessage-mic");

      if (deviceInfo.kind === 'audioinput') {
        option.text = deviceInfo.label || `Microphone ${audioSelect.length + 1}`;

        if(layoutMic === JSON.stringify(option.value)) {
          option.selected = true;
        }

        audioSelect.appendChild(option);
      } else if (deviceInfo.kind === 'videoinput') {
        if(deviceInfo.label && deviceInfo.label.toLowerCase().indexOf('back') >= 0) {
          option.text = "Back camera - facing out"
        } else if(deviceInfo.label && deviceInfo.label.toLowerCase().indexOf('front') >= 0) {
          option.text = "Front camera - facing you"
        } else {
          option.text = deviceInfo.label || `Camera ${videoSelect.length + 1}`;
        }

        if(layoutCamera === JSON.stringify(option.value)) {
          option.selected = true;
        }

        videoSelect.appendChild(option);
      }
    }
  }

  function getStream() {
    stopStreamsAndAnimation();

    const displayMediaOptions = {
      video: { mediaSource: "screen" },
      selfBrowserSurface: "include" // Chrome is rolling out the change to hide the current tab
      // (unless selfBrowserSurface is set to 'include' user can't current tab in the navigator pop up window) along with the reordering of tabs.
    }

    return navigator.mediaDevices.getDisplayMedia(displayMediaOptions)
        .then(stream => {
          screenStream = stream;
          window.streamArr.push(stream);

          return gotStream.call(this, stream);
        })
        .catch(error => handleError.call(this, error));
  }

  function gotStream(stream) {
    window.screenStream = stream;
    if(!isSafari) {
      displaySurface = stream.getVideoTracks()[0].getSettings().displaySurface;
      entireScreen = (displaySurface === "monitor")
    }

    return startStream.call(this, stream);
  }

  function startStream(){
    if(activeMedia.includes("camera") && document.pictureInPictureEnabled && (preRecordPipBtnTarget.dataset.cameraOn === "true" || (isSafari && preRecordPipBtnTarget.dataset.cameraOn === "false"))) {
      return getCameraStream.call(this)
    } else if(activeMedia.includes("mic")) {
      return getAudioStream.call(this)
    } else {
      drawVideo(context);
    }
  }

  function getCameraStream() {
    const videoSource = videoSelect.value;

    var idealWidth = 1280;
    var idealHeight = 720;
    var minWidth = 320;
    var minHeight= 320;

    if(mediaField.dataset.enableHdVideo) {
      idealWidth = 1920;
      idealHeight = 1080;
      minWidth = 500;
      minHeight = 500;
    }

    const constraints = {
      video: {
        deviceId: videoSource ? {exact: videoSource} : undefined,
        width: {
          min: minWidth,
          ideal: idealWidth
        },
        height: {
          min: minHeight,
          ideal: idealHeight
        },
        facingMode: "user"
      }
    };

    return navigator.mediaDevices.getUserMedia(constraints)
        .then(stream => {
          window.cameraStream = stream;
          cameraStream = stream;
          window.streamArr.push(stream);

          if(activeMedia.includes("mic")) {
            return getAudioStream.call(this)
          } else {
            drawVideo(context);
          }
        })
        .catch(error => handleError.call(this, error));
  }

  function getAudioStream() {
    const audioSource = audioSelect.value;

    const audioConstraints = {
      audio: {deviceId: audioSource ? {exact: audioSource} : undefined},
      echoCancellation: true,
      noiseSuppression: true,
      sampleRate: 44100
    }

    return navigator.mediaDevices.getUserMedia(audioConstraints).then(stream => {
      audioStream = stream;
      window.streamArr.push(stream);
      canDrawInCanvas = true;

      // show audio level of mic
      var detectAudio = new DetectAudioLevel();
      detectAudio.detectAudioLevel(stream, 'audio-meter', camMicSelectorsTarget, messageUITarget);

      drawVideo(context);
    }).catch(error => handleError.call(this, error))
  }

  function drawVideo(context) {
    var mergedStream;

    if(isSafari)
      getDevices().then(device_info => gotDevices(device_info))

    updateMediaSetting();

    var screen_video = document.createElement("video");
    screen_video.srcObject = screenStream;
    screen_video.muted = true;
    screen_video.play();

    if(cameraStream) {
      camera_video.srcObject = cameraStream;
      camera_video.setAttribute("id", "cameraStreamPip")
      camera_video.play();
    }

    if (document.pictureInPictureEnabled && cameraStream) {
      // Browser supports picture in picture api

      // show camera preview in PiP
      camera_video.onloadedmetadata = (e) => {
        if(isSafari) {
          preRecordPipBtnTarget.addEventListener("click", (e) => {
            if(document.pictureInPictureElement) {
              document.exitPictureInPicture().then(() => {
                updatePreRecordPipText();
                updateDuringRecordPipText();
              });
            } else {
                camera_video.requestPictureInPicture().then(() => {
                  camera_video.play();
                  camera_video.width = 0;
                  camera_video.height = 0;
                  camera_video.classList.remove("hidden");
                });
            }
          });
          addListenersForPipSafari();
        }
        else {
          addListenersForPip();
        }

        window.pipCamera = camera_video;
        preRecordPipBtnTarget.classList.remove("hidden");
      };

      if (entireScreen) {
        // Recording entire screen and camera preview in PiP
        mergedStream = screenStream;
      } else {

        // when window is recorded draw camera preview in canvas
        let recordingArgs = getLayoutPosition(screenStream, cameraStream, canvas);
        currentTabVisibility = document.visibilityState;

        stopAnim = audioTimerLoop(draw.bind(event, screen_video, camera_video, canvas, context, recordingArgs), (1000 / frameRate));

        mergedStream = canvas.captureStream(frameRate);
      }
    } else {
      // Browser that doesn't supports Picture in Picture api will record only screen
      mergedStream = screenStream;
    }

    // Set currently active media device ids to local storage
    if(videoSelect.value !== null && videoSelect.value.length > 0) localStorage.setItem("zipmessage-camera", JSON.stringify(videoSelect.value));
    if(audioSelect.value !== null && audioSelect.value.length > 0) localStorage.setItem("zipmessage-mic", JSON.stringify(audioSelect.value));

    if(audioStream){
      audioSelect.selectedIndex = Array.from(audioSelect.options).
      findIndex(option => getIndexOfOption(option, audioStream.getAudioTracks()[0].label));
    }

    if(cameraStream) {
      videoSelect.selectedIndex = Array.from(videoSelect.options).
      findIndex(option => getIndexOfOption(option, cameraStream.getVideoTracks()[0].label));
    }

    window.stream = mergedStream;
    if(audioStream) {
      window.stream.addTrack(audioStream.getAudioTracks()[0])
    }
    previewElement.querySelector("video").srcObject = mergedStream;
    loadingIcon.classList.add("hidden"); // Hide the loading spinner icon
    startMicRecordBtn.classList.add("hidden"); // hide the "start recording mic" button
    startCameraRecordBtn.classList.add("hidden"); // hide the "start recording camera" button
    startScreenRecordBtn.classList.remove("hidden"); // show the "start recording screen" button
  }

  // ---MAIN FUNCTION ENDS---


  // ---HELPER FUNCTION STARTS---

  function restartCanvas() {
    if(stopAnim) {
      stopAnim(); // stop canvas
      stopAnim = undefined;
    }

    gotStream(window.screenStream)
  }

  function showHideActiveMediaElements() {
    // Get media elements that are active
    for(const activeMediaElement of activeMediaElements) {
      if(activeMediaElement.classList.contains("active"))
        activeMedia.push(activeMediaElement.dataset.device);
    }

    // Hide `mic` icon in start recording button
    if (!activeMedia.includes("mic")) {
      var micLabels = this.element.querySelectorAll(".plusMicLabel");
      for (let micLabel of micLabels) {
        micLabel.classList.add("hidden");
      }
    }

    // Show/Hide `camera` icon in start recording button
    if(activeMedia.includes("camera")) {
      // canvas
      canvas = document.querySelector("#recordingCanvas");
      context = canvas.getContext("2d", {
        alpha: false,
        antialias: false
      })
    } else {
      this.element.querySelector(".plusCameraLabel").classList.add("hidden");
    }

  }

  function startPip() {
    return new Promise((resolve, reject) => {
      if (activeMedia.includes("camera")) {
        context.fillStyle = "black";
        context.fillRect(0, 0, 352, 240);
        camera_video.srcObject = canvas.captureStream(1);

        camera_video.onloadedmetadata = (e) => {
          camera_video.requestPictureInPicture()
        }
      }

      return resolve('dummy video');
    })
  }

  function choosePreviousCameraLayout() {
    cameraLayoutSelect
        .querySelectorAll('input[name="camera-layout"]')
        .forEach((input) => {
              if(JSON.stringify(input.value) === localStorage.getItem("zipmessage-camera-layout")) {
                input.setAttribute("checked", "checked");

                resetCameraLayout();

                var label = input.closest("label");
                if(label) label.classList.add("active");
              } else {
                input.removeAttribute("checked");
              }
            }
        )
  }

  function listenStopEvent() {
    if(!(this.hasStopBtnTarget === true)) return false;

    this.stopBtnTarget.addEventListener("click", (e) => {
      if(stopAnim) {
        stopAnim();
        stopAnim = undefined;
      }
      recordingStopped = true;

      if(timeoutReference){
        clearTimeout(timeoutReference);
      }
    })
  }

  // This function is used inside `getStream` function
  function stopStreamsAndAnimation() {
    // stop existing streams and canvas animation
    if (window.stream) {
      window.stream.getTracks().forEach(track => {
        track.stop();
      });
    }

    if(window.streamArr) {
      window.streamArr.forEach(stream => {
        stream.getTracks().forEach(track => {
          track.stop();
        });
      });
    }

    window.streamArr = [];

    if(stopAnim) {
      stopAnim();
    }

    if(document.pictureInPictureElement) {
      document.exitPictureInPicture();
    }

    canDrawInCanvas = false;
  }

  // This function is used inside `drawVideo` function
  function updateMediaSetting() {
    if (screenStream && cameraStream && audioStream) {
      resetButton.classList.remove("hidden");
      audioSource.classList.remove("hidden");

      if (selectCameraLayout && !entireScreen) {
        // show camera select layout
        selectCameraLayout.classList.remove("hidden");
        videoSource.classList.remove("hidden");
      } else {
        selectCameraLayout.classList.add("hidden");
        videoSource.classList.remove("hidden");
      }
    } else if (screenStream && cameraStream) {
      resetButton.classList.remove("hidden");
      audioSource.classList.add("hidden");

      if (selectCameraLayout && !entireScreen) {
        // show camera select layout
        selectCameraLayout.classList.remove("hidden");
        videoSource.classList.remove("hidden");
      } else {
        selectCameraLayout.classList.add("hidden");
        videoSource.classList.remove("hidden");
      }
    } else if (screenStream && audioStream) {
      resetButton.classList.remove("hidden");
      videoSource.classList.add("hidden");
      selectCameraLayout.classList.add("hidden");
      audioSource.classList.remove("hidden");
    } else {
    }
  }

  // This function is used inside `drawVideo` function
  function addListenersForPip() {
    if (camera_video.getAttribute('leaveListener') !== 'true') {
      camera_video.addEventListener('leavepictureinpicture', () => {
        camera_video.setAttribute('leaveListener', 'true');
        if(duringRecordPipBtnTarget.getAttribute('recordingStarted') !== 'true') {
          updatePreRecordPipText();
          updateDuringRecordPipText();
        }
      });
    }

    if (camera_video.getAttribute('enterListener') !== 'true') {
      camera_video.addEventListener('enterpictureinpicture', () => {
        camera_video.setAttribute('enterListener', 'true');
        if(duringRecordPipBtnTarget.getAttribute('recordingStarted') !== 'true') {
          updatePreRecordPipText();
          updateDuringRecordPipText();
        }
      });
    }

    if(preRecordPipBtnTarget.getAttribute('clickListener') !== 'true') {
      preRecordPipBtnTarget.addEventListener("click", (e) => {
        preRecordPipBtnTarget.setAttribute('clickListener', 'true');
        if (document.pictureInPictureElement) {
          pipOpen = false;
          document.exitPictureInPicture().then(() => resetCameraAndAudioStream()); // close PiP window
        } else {
          pipOpen = true;
          resetCameraAndAudioStream();
        }
      });
    }

    if(duringRecordPipBtnTarget.getAttribute('clickListener') !== 'true') {
      duringRecordPipBtnTarget.addEventListener("click", (e) => {
        duringRecordPipBtnTarget.setAttribute('clickListener', 'true');
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture().then(() => updateDuringRecordPipText()); // close PiP window
        } else {
          camera_video.requestPictureInPicture().then(() => updateDuringRecordPipText()); // open PiP window
        }
      });
    }
  }

  function addListenersForPipSafari() {
    if (camera_video.getAttribute('leaveListener') !== 'true') {
      camera_video.addEventListener('leavepictureinpicture', () => {
        camera_video.setAttribute('leaveListener', 'true');
        if(duringRecordPipBtnTarget.getAttribute('recordingStarted') !== 'true') {
          updatePreRecordPipText();
          updateDuringRecordPipText();
        }
        else {
          updateDuringRecordPipText();
        }
      });
    }

    if (camera_video.getAttribute('enterListener') !== 'true') {
      camera_video.addEventListener('enterpictureinpicture', () => {
        camera_video.setAttribute('enterListener', 'true');
        if(duringRecordPipBtnTarget.getAttribute('recordingStarted') !== 'true') {
          updatePreRecordPipText();
          updateDuringRecordPipText();
        }
      });
    }

    if(duringRecordPipBtnTarget.getAttribute('clickListener') !== 'true') {
      duringRecordPipBtnTarget.addEventListener("click", (e) => {
        duringRecordPipBtnTarget.setAttribute('clickListener', 'true');
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture().then(() => updateDuringRecordPipText()); // close PiP window
        } else {
          camera_video.requestPictureInPicture().then(() => {
            camera_video.play();
            camera_video.width=0;
            camera_video.height=0;
            camera_video.classList.remove("hidden");
            updatePreRecordPipText();
            updateDuringRecordPipText();
          }); // open PiP window
        }
      });
    }
  }

  // This function is used inside `drawVideo` function
  function getLayoutPosition(screenStream, cameraStream, canvas) {
    let camera_height, camera_width, apply_aspect_ratio;
    let screen_height = screenStream.getVideoTracks()[0].getSettings().height;
    let screen_width = screenStream.getVideoTracks()[0].getSettings().width;

    let camera_source_height = cameraStream.getVideoTracks()[0].getSettings().height;
    let camera_source_width = cameraStream.getVideoTracks()[0].getSettings().width;

    apply_aspect_ratio = !((screen_width === screen.width) && (screen_height === screen.height))

    canvas.width = screen_width;
    if(apply_aspect_ratio === true) {
      canvas.height = screen_width * (10 / 16); // calculating height of the canvas to match 16:10 aspect ratio
    } else {
      canvas.height = screen_height;
    }

    camera_width = parseInt((30 / 100) * camera_source_width);
    camera_height = parseInt((30 / 100) * camera_source_height);

    let camera_top, camera_left, transform_x, transform_y;
    // x - axis position camera_left
    // y - axis position camera_top
    // x - axis transformation scale transform_x
    // y - axis transformation scale transform_y

    var selected_layout = cameraLayoutSelect.querySelector('input[name="camera-layout"]:checked').value;

    if(selected_layout !== null && selected_layout.length > 0) localStorage.setItem("zipmessage-camera-layout", JSON.stringify(selected_layout));

    if(selected_layout === "bottom-right") {
      camera_left = screen_width - camera_width; // x axis
      camera_top = screen_height - camera_height; // y axis
    } else if(selected_layout === "bottom-left") {
      camera_left = 0; // x axis
      camera_top = screen_height - camera_height; // y axis
    } else if(selected_layout === "top-right") {
      camera_left = screen_width - camera_width; // x axis
      camera_top = 0; // y axis
    } else if(selected_layout === "top-left") {
      camera_left = 0; // x axis
      camera_top = 0; // y axis
    }

    // Ensure that if thin dimensions then full height is in view or if wide dimensions then full width is in view.
    const scale = Math.min(canvas.width / screen_width, canvas.height / screen_height);

    // transformation for scaling video.  These place the screen in the center of the canvas (makes black strips on the left/right or top/bottom).
    transform_x = (canvas.width - screen_width * scale) / 2;
    transform_y = (canvas.height - screen_height * scale) / 2;

    return {
      screenHeight: screen_height,
      screenWidth: screen_width,
      cameraWidth: camera_width,
      cameraHeight: camera_height,
      cameraTop: camera_top,
      cameraLeft: camera_left,
      selected_layout: selected_layout,
      transform_x: transform_x,
      transform_y: transform_y,
      scale: scale,
      apply_aspect_ratio: apply_aspect_ratio
    };
  }

  // This function is used inside `drawVideo` function
  function audioTimerLoop(callback, frequency) {
    // AudioContext time parameters are in seconds
    var freq = frequency / 1000;

    var aCtx = new AudioContext();
    // Chrome needs our oscillator node to be attached to the destination
    // So we create a silent Gain Node
    var silence = aCtx.createGain();
    silence.gain.value = 0;
    silence.connect(aCtx.destination);

    onOSCend();

    var stopped = false;
    function onOSCend() {
      var osc = aCtx.createOscillator();
      osc.onended = onOSCend;
      osc.connect(silence);
      osc.start(0);
      osc.stop(aCtx.currentTime + freq);
      callback(aCtx.currentTime);
      if (stopped) {
        osc.onended = function() {
          return;
        };
      }
    };
    // return a function to stop our loop
    return function() {
      stopped = true;
    };
  }

  // This function is used inside `drawVideo` function
  function draw(screen_stream, camera_stream, canvas, context, recordingArgs) {
    // This function will draw a frame with screen and camera in HTML canvas
    if(recordingStopped === true) {
      return;
    }

    context.clearRect(0, 0, recordingArgs.screenWidth, recordingArgs.screenHeight);

    if(recordingArgs.apply_aspect_ratio !== undefined && recordingArgs.apply_aspect_ratio === true) {
      // scale the image to fit in 16:10 aspect ratio
      context.setTransform(recordingArgs.scale, 0, 0, recordingArgs.scale, recordingArgs.transform_x, recordingArgs.transform_y);
    }

    // Draw screen frame
    context.drawImage(screen_stream, 0, 0, recordingArgs.screenWidth, recordingArgs.screenHeight);

    // Draw a Rectangle stoke
    context.lineWidth = 1;
    context.strokeStyle = "#FFFFFF";
    // context.shadowBlur = 5;
    // context.shadowColor = "black";
    context.strokeRect(recordingArgs.cameraLeft, recordingArgs.cameraTop, recordingArgs.cameraWidth + 1, recordingArgs.cameraHeight + 1);

    // Draw camera frame
    context.drawImage(camera_stream, recordingArgs.cameraLeft, recordingArgs.cameraTop, recordingArgs.cameraWidth, recordingArgs.cameraHeight);
  }

  // This function is used inside `drawVideo` function
  function getIndexOfOption(option, label) {
    return (option.text === label) ||
        (option.text.toLowerCase().indexOf('you') >= 0 && label.toLowerCase().indexOf('front') >= 0) ||
        (option.text.toLowerCase().indexOf('out') >= 0 && label.toLowerCase().indexOf('back') >= 0);
  }

  // ---HELPER FUNCTION ENDS---


  // ---Function that are used inside helper functions STARTS ---

  function resetCameraLayout() {
    cameraLayoutSelect.querySelectorAll("label").forEach(label => {
      label.classList.remove("active")
    })
  }

  function resetCameraAndAudioStream() {
    if(preRecordPipBtnTarget.dataset.cameraOn === "true") {
      preRecordPipBtnTarget.dataset.cameraOn = "false"
    } else {
      preRecordPipBtnTarget.dataset.cameraOn = "true"
    }

    if(stopAnim) {
      stopAnim(); // stop canvas
      stopAnim = undefined;
    }

    if (window.cameraStream && preRecordPipBtnTarget.dataset.cameraOn !== "true") {
      window.cameraStream.getTracks().forEach(track => {
        track.stop();
      }); // stop camera stream

      window.cameraStream = undefined;
    }

    if(activeMedia.includes("camera") && preRecordPipBtnTarget.dataset.cameraOn === "true") {
      startPip().then( () => getCameraStream()); // start dummy pip and get camera stream
    } else if(activeMedia.includes("mic")) {
      getAudioStream(); // get audio stream
    } else {
      drawVideo(context);
    }
  }

  function updatePreRecordPipText() {
    var cameraShownLabel = preRecordPipBtnTarget.querySelector(".cameraShownLabel");
    var cameraShownIcon = preRecordPipBtnTarget.querySelector(".cameraShownIcon");
    var cameraHiddenLabel = preRecordPipBtnTarget.querySelector(".cameraHiddenLabel");
    var cameraHiddenIcon = preRecordPipBtnTarget.querySelector(".cameraHiddenIcon");
    var plusCameraLabel = document.querySelector(".plusCameraLabel");


    if (document.pictureInPictureElement) {
      cameraShownLabel.classList.remove("hidden");
      cameraShownIcon.classList.remove("hidden");
      cameraHiddenLabel.classList.add("hidden");
      cameraHiddenIcon.classList.add("hidden");
      plusCameraLabel.classList.remove("hidden");
    } else {
      cameraShownLabel.classList.add("hidden");
      cameraShownIcon.classList.add("hidden");
      cameraHiddenLabel.classList.remove("hidden");
      cameraHiddenIcon.classList.remove("hidden");
      plusCameraLabel.classList.add("hidden");

      if (window.cameraStream && preRecordPipBtnTarget.dataset.cameraOn === "true" && pipOpen === true) {
        window.cameraStream.getTracks().forEach(track => {
          track.stop();
        }); // stop camera stream

        preRecordPipBtnTarget.dataset.cameraOn = "false";
        window.cameraStream = undefined;
        pipOpen = false;
      }
    }
  }

  function updateDuringRecordPipText() {
    var cameraShownLabel = duringRecordPipBtnTarget.querySelector(".cameraShownLabel");
    var cameraShownIcon = duringRecordPipBtnTarget.querySelector(".cameraShownIcon");
    var cameraHiddenLabel = duringRecordPipBtnTarget.querySelector(".cameraHiddenLabel");
    var cameraHiddenIcon = duringRecordPipBtnTarget.querySelector(".cameraHiddenIcon");

    if (document.pictureInPictureElement) {
      cameraShownLabel.classList.remove("hidden");
      cameraShownIcon.classList.remove("hidden");
      cameraHiddenLabel.classList.add("hidden");
      cameraHiddenIcon.classList.add("hidden");
    } else {
      cameraShownLabel.classList.add("hidden");
      cameraShownIcon.classList.add("hidden");
      cameraHiddenLabel.classList.remove("hidden");
      cameraHiddenIcon.classList.remove("hidden");
    }
  }

  // ---Function that are used inside helper functions ENDS ---

  function handleError(error) {
    console.error('Error: ', error);
    console.error('Error: ', error.name);

    this.mediaError(error)
    if(window.appsignal !== undefined) { var span = window.appsignal.createSpan();
      span.setParams({ account_id: accountId })
      span.setTags({
        tag: "Media error"
      }).setError({name: "Screen permission denied", message: error});
      window.appsignal.send(span);};
  }
}