import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
                    "selectableImage",
                    "clearSelectionLink"
                   ]

  static values = {
  }

  connect() {
    var currently_selected_image = this.element.querySelector(".selectable-image.selected");
    if (currently_selected_image) {
      this.clearSelectionLinkTarget.classList.remove("hidden");
    }
  }

  selectImage(event) {
    var currently_selected_image = this.element.querySelector(".selectable-image.selected");
    var clicked_image = event.target.closest(".selectable-image");
    if (currently_selected_image) {
      currently_selected_image.classList.remove("selected");
    }

    clicked_image.classList.add("selected");
    document.querySelector("#bgimage").setAttribute('crossOrigin', 'Anonymous');
    var controlsBtn = document.querySelector("a[data-message--creation-target='backgroundImage']");
    if (currently_selected_image === clicked_image) {
     this.clearSelection();
     if (controlsBtn) {
       controlsBtn.classList.remove("active");
     }
    }else {
      document.querySelector("#bgimage").src = clicked_image.querySelector("div:nth-child(2)").style.backgroundImage.slice(5, -2)+ "?not-from-cache-please";
      sessionStorage.setItem('background_image_id', clicked_image.dataset.backgroundImageId);
      this.clearSelectionLinkTarget.classList.remove("hidden");
      if (controlsBtn) {
        controlsBtn.classList.add("active");
      }
    }
  }

  clearSelection() {
    var clicked_image = document.querySelector(".selected");
    document.querySelector("#bgimage").src = document.querySelector("#nobackground").src;
    clicked_image.classList.remove("selected");
    sessionStorage.setItem('background_image_id', document.querySelector("#nobackground").dataset.imageId);
    this.clearSelectionLinkTarget.classList.add("hidden");
  }

  account_default(){
    setTimeout(()=> {
      var params = {
        background_image_id: (this.element.classList.contains("active") === true) ? this.element.dataset.backgroundImageId : " "
      };

      fetch(`/accounts/${accountId}/update_image`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(params),
        credentials: "same-origin"
      }).then(response => response.text())
        .then(data => {
          setTimeout(() =>{
            document.querySelector("#background-images-list").innerHTML =  data;
          }, 300);
        })

    }, 200);
  }

  account_all_default(){
    var user_id, account_id;
    setTimeout(()=> {
      if(this.element.classList.contains("active") === true)
      { user_id = ""
        account_id = accountId
      } else {
        user_id = userId
        account_id = ""
      }

     var params = {
       user_id: user_id,
       account_id: account_id
    };
      fetch(`/background/${this.element.dataset.backgroundImageId}/update_image`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(params),
        credentials: "same-origin"
      }).then(response => response.text())
        .then(data => {
          document.querySelector("#background-images-list").innerHTML =  data;
        })

    }, 200);

  }

  user_default(){
    setTimeout(()=> {
      var params = {
        background_image_id: (this.element.classList.contains("active") === true) ? this.element.dataset.backgroundImageId : " "
      };

      fetch(`/users/${userId}/update_image`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(params),
        credentials: "same-origin"
      }).then(response => response.text())
        .then(data => {
          setTimeout(() =>{
            document.querySelector("#background-images-list").innerHTML =  data;
          }, 200);
      })

    }, 200);
  }



}