// This manages Google Tag Manager's DataLayer.
// Use the actions in this controller to push data into the DataLayer, which is injested by GTM and can then be pushed to other services, such as Google Analytics 4, etc.

import { Controller } from 'stimulus'

export default class extends Controller {
  
  static targets = ["conversationPage"];

  static values = {
    userId: Number
  }

  connect() {
    
    // Custom page_view event
    window.dataLayer = window.dataLayer || []; 
    if (this.hasConversationPageTarget) {
      window.dataLayer.push({
        "event": "page_view",
        "conversation_page": true,
        "logged_in_user": this.hasUserIdValue
      });
    } else {
      window.dataLayer.push({
        "event": "page_view",
        "conversation_page": false,
        "logged_in_user": this.hasUserIdValue
      });
    }
    

    // GA4 Event - identify_user
    // Fires on every page load where current_user is defined
    if (this.hasUserIdValue) {
      window.dataLayer = window.dataLayer || []; 
      window.dataLayer.push({
        "event": "identify_user",
        "user_id": this.userIdValue
      });
    }


    // GA4 Event - respondent_registration
    // Fires when someone registers as a respondent user.
    if (window.location.href.indexOf("new_respondent=true") > -1) {
      window.dataLayer = window.dataLayer || []; 
      window.dataLayer.push({
        "event": "respondent_registration",
        "user_id": this.userIdValue
      });
    }


    // GA4 Event - sign_up (public)
    // Fires when a user signs up for a new free account via our public signup form
    // Does not fire when a respondent creates an account from within the app
    // Does not fire when a logged-in user creates an additional new account
    if (window.location.href.indexOf("new_account=true") > -1) {
      window.dataLayer = window.dataLayer || []; 
      window.dataLayer.push({
        "event": "sign_up",
        "user_id": this.userIdValue,
        "converted_from_respondent": false
      });
    }


    // GA4 Event - sign_up (converted from respondent)
    // Fires when a user signs up for a new free account via the /try_zipmessage page, which they access after they're logged-in as a respondent user.
    if (window.location.href.indexOf("new_account_via_resp=true") > -1) {
      window.dataLayer = window.dataLayer || []; 
      window.dataLayer.push({
        "event": "sign_up",
        "user_id": this.userIdValue,
        "converted_from_respondent": true
      });
    }


    // GA4 Event - activated_paid_subscription
    // Fires after user converts from free to paid
    if (window.location.href.indexOf("subscription_success=true") > -1) {
      window.dataLayer = window.dataLayer || []; 
      window.dataLayer.push({
        "event": "activated_paid_subscription",
        "user_id": this.userIdValue
      });
    }


    // GA4 Event - upgraded_subscription_plan
    // Fires after user converts from Basic plan to Premium plan
    if (window.location.href.indexOf("upgraded_plan=true") > -1) {
      window.dataLayer = window.dataLayer || []; 
      window.dataLayer.push({
        "event": "upgraded_subscription_plan",
        "user_id": this.userIdValue
      });
    }


    // GA4 Event - invited_teammember
    // Fires after user sends a team member invitation
    if (window.location.href.indexOf("send_invite_event=true") > -1) {
      window.dataLayer = window.dataLayer || []; 
      window.dataLayer.push({
        "event": "invited_teammember",
        "user_id": this.userIdValue
      });
    }

    

    
    // Google Tag Manager
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-PXC2VCW');

  }

}