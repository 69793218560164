// This controls all toggle_switch elements (views/shared/components/_toggle_switch.html.erb)


// Toggle Switches can be:
// - A single toggle switch with a hidden field value (true or false)
// - One of several checkboxes with a checked value of checked or not
// - A toggle switch without any form field.  It just does some other stuff (typically triggers some JS)
// - It appears like a toggle switch, but it functions like a link.  For example, a toggle switch that when clicked, points to upgrade view (using this toggle requires an upgrade)


// In some cases a toggle can be "linked" to other toggles.  For example, when changing one toggle should cause other toggle switches to change too.
// How to link toggles:
//  - On the toggle that should control other toggles:  Specify the ID(s) of other toggle_switch elements that should be linked to this toggle, in the linked_toggle_ids attribute, separated by a space. 
//  - Optional:  Set the link behavior:
//    - On the toggle that should control other toggles, specify the linked_behavior to be one of these strings:
//      - "make_false" (default, you don't need to set this but you can if you want) --> The linked toggles will turn false when this toggle turns false.
//      - "match" --> the linked toggles will turn false or true and match the current value of this toggle
//      - "change" --> the linked toggles will change to false or true, whatever the opposite of their current value was (but not necessarily match this toggle's value)
// See an example of linked toggles in _message_views_preferences.html.erb



import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ["fieldValue", 
                    "checkboxFieldValue", 
                    "toggleContainer"]

  static values = {
    activeBgColor: String,
    inactiveBgColor: String,
    active: Boolean,
    linkedToggles: String,
    linkedBehavior: String,
    disableLinkedWhenFalse: Boolean
  }

  connect() {
    if (this.hasFieldValueTarget) { // a non-checkbox field is present
      // If 'active' wasn't explicitely set via the local_variables, then set it based on the value of the field
      this.activeValue = this.fieldValueTarget.value == "true";
    } else if (this.hasCheckboxFieldValueTarget) {
      // If a checkbox is present, set this controller's 'active' value to match the checkbox's checked value
      this.activeValue = this.checkboxFieldValueTarget.checked;
    }

    this.toggleStyles();

    // Listen to changes in linked toggles if any
    if (this.linkedTogglesValue) {
      this.linkedTogglesValue.split(' ').forEach(toggleId => {
        const toggle = document.getElementById(toggleId);
        if (toggle) {
          toggle.addEventListener('change', () => {
            this.updateLinkedToggle(toggle);
          });
        }
      });
    }
  }

  toggle(event) {
    if (event.target.getAttribute('type') != 'checkbox') {
      event.preventDefault();
    }
    this.activeValue = !this.activeValue;
    this.toggleStyles();
    this.triggerEvent();

    // If the toggle has linked toggles, update them
    if (this.linkedTogglesValue) {
      this.linkedTogglesValue.split(' ').forEach(toggleId => {
        const toggle = document.getElementById(toggleId);
        if (toggle) {
          this.updateLinkedToggle(toggle);
          
          // If we set disable_linked_when_false to true, then fade out the linked toggles and make then unclickable when we turned them to false
          if (this.disableLinkedWhenFalseValue) {
            if (this.activeValue == true) {
              this.enableToggle(toggle);
            } else {
              this.disableToggle(toggle);
            }
          }
        }
      });
    }
  }

  updateLinkedToggle(toggle) {
    let toggleSwitchControllerInstance = this.application.getControllerForElementAndIdentifier(toggle, 'toggle-switch');

    if (toggleSwitchControllerInstance) {
      let newValue;

      switch (this.linkedBehaviorValue) {
        case "match":
          newValue = this.activeValue;
          break;
        case "change":
          newValue = !toggleSwitchControllerInstance.activeValue;
          break;
        case "make_false":
        default:
          if (!this.activeValue) {
            newValue = false;
          } else {
            newValue = toggleSwitchControllerInstance.activeValue;
          }
          break;
      }

      toggleSwitchControllerInstance.setValue(newValue);
    }
  }

  disableToggle(element) {
    if (!element.classList.contains('active')) {
      element.classList.add('opacity-40', 'cursor-default');
      element.setAttribute('data-action', '');
    }
  }

  enableToggle(element) {
    element.classList.remove('opacity-40', 'cursor-default');
    element.setAttribute('data-action', 'click->toggle-switch#toggle touch->toggle-switch#toggle');
  }


  toggleStyles() {
    let toggle_element = this.element;
    let toggleContainer = this.toggleContainerTarget;
    let activeBgColor = this.activeBgColorValue.split(" ");
    let inactiveBgColor = this.inactiveBgColorValue.split(" ");

    let state = this.activeValue ? "active" : "inactive";

    if (state === "active") {
      if (toggle_element) {
        toggle_element.classList.add("active");
      }
      if (toggleContainer) {
        toggleContainer.classList.add(...activeBgColor);
        toggleContainer.classList.remove(...inactiveBgColor);
      }
    } else {
      if (toggle_element) {
        toggle_element.classList.remove("active");
      }
      if (toggleContainer) {
        toggleContainer.classList.add(...inactiveBgColor); 
        toggleContainer.classList.remove(...activeBgColor);
      }
    }
  }

  triggerEvent() {
    if (this.hasFieldValueTarget) {
      this.fieldValueTarget.value = this.activeValue.toString();
      this.fieldValueTarget.dispatchEvent(new Event('change'));
    } else if (this.hasCheckboxFieldValueTarget) {
      this.checkboxFieldValueTarget.checked = this.activeValue;
      this.checkboxFieldValueTarget.dispatchEvent(new Event('change'));
    }
  }

  setValue(value) {
    this.activeValue = value;
    this.toggleStyles();
    this.triggerEvent();
  }
}
