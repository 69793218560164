import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "teamMembersAssignment",
    "teamMembersToggleSwitch",
    "appointmentTypeRedirectType",
    "appointmentTypeConfirmationContent",
    "appointmentTypeLocationPreference",
    "appointmentTypeLocation",
    "appointmentOfferSelectField",
  ];

  toggleTeamMembersAssignment() {
    if (this.togglesTeamMembersCount() >= 2) {
      this.teamMembersAssignmentTarget.classList.remove("hidden");
    } else {
      this.teamMembersAssignmentTarget.classList.add("hidden");
    }
  }

  togglesTeamMembersCount() {
    this.checkedCount = 0;
    this.teamMembersToggleSwitchTargets.forEach((toggle) => {
      if (toggle.classList.contains("active")) {
        this.checkedCount++;
      }
    });

    return this.checkedCount;
  }

  displayConfirmationContent() {
    if (this.appointmentTypeRedirectTypeTarget.value === "confirmation") {
      this.appointmentTypeConfirmationContentTarget.classList.remove("hidden");
    } else {
      this.appointmentTypeConfirmationContentTarget.classList.add("hidden");
    }
    var conversationContent = document.querySelector(
      ".appointment-type-conversation-content"
    );
    if (this.appointmentTypeRedirectTypeTarget.value === "conversation") {
      if (conversationContent) conversationContent.classList.remove("hidden");
    } else {
      if (conversationContent) conversationContent.classList.add("hidden");
    }

    var customUrlField = document.querySelector(
      ".appointment-type-custom-url-field"
    );
    if (this.appointmentTypeRedirectTypeTarget.value === "custom") {
      if (customUrlField) customUrlField.classList.remove("hidden");
    } else {
      if (customUrlField) customUrlField.classList.add("hidden");
    }
  }

  displayLocation() {
    if (this.appointmentTypeLocationPreferenceTarget.value == "custom") {
      this.appointmentTypeLocationTarget.classList.remove("hidden");
    } else {
      this.appointmentTypeLocationTarget.classList.add("hidden");
    }
  }

  refreshBookingContent(event) {
    var selectATimeButton = event.target.closest(".select-a-time-button");
    var appointmentTypeId;
    if (selectATimeButton) {
      appointmentTypeId = selectATimeButton.dataset.appointmentTypeId;
      var canBookAppointment = selectATimeButton.dataset.canBookAppointment;
      var messageId = selectATimeButton.dataset.messageId;
      var viewerEmail = selectATimeButton.dataset.viewerEmail;
    } else {
      // it must be a select.appointment-offer-select-field
      appointmentTypeId = event.target.value;
    }
    var appointmentOfferBlock =
      event.target.closest(".message-block.appointment-offer-block") ||
      event.target.closest(".message-block.edit-appointment-offer-block");
    if (appointmentOfferBlock)
      var messageBookingContentPartial = appointmentOfferBlock.querySelector(
        ".message-booking-content-partial"
      );
    if (appointmentTypeId) {
      if (messageBookingContentPartial) {
        messageBookingContentPartial.innerHTML = "";
        const spinner = document.createElement("i");
        spinner.className =
          "loading-icon fa-duotone fa-spinner-third animate-spin fa-3x inline-flex py-12";
        messageBookingContentPartial.innerHTML = spinner.outerHTML;
        messageBookingContentPartial.classList.add(
          "appointment-type-" + appointmentTypeId
        );
      }
      Rails.ajax({
        type: "get",
        url: `/appointments/types/${appointmentTypeId}/refresh_booking_content?can_book=${canBookAppointment}&message_id=${messageId}&viewer=${viewerEmail}`,
      });
    } else {
      if (messageBookingContentPartial) {
        messageBookingContentPartial.innerHTML = "";
      }
    }
  }

  setAppointmentTypeId() {
    if (this.hasAppointmentOfferSelectFieldTarget) {
      var appointmentTypeSelect = this.appointmentOfferSelectFieldTarget;
      var appointmentTypeId = appointmentTypeSelect.dataset.appointmentTypeId;
      if (appointmentTypeSelect.value) {
        appointmentTypeSelect.value = null;
      } else {
        appointmentTypeSelect.value = appointmentTypeId;
      }
      var optionToHide =
        appointmentTypeSelect.querySelector(`option[value='']`);
      optionToHide.hidden = true;
    }
  }
}
