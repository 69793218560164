import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['thumbnailContainer',
                    'thumbnailPresentation',
                    'thumbnailEditor',

                    'thumbnail', 
                    'thumbnailForm',
                    'thumbnailButton', 
                    ]

  static values = {
    context: String
  }

  connect() {
  }

  toggleThumbnailEditor() {
    // Toggles between showing the thumbnail (or empty thumbnail if none exists yet) and the thumbnail uploader form
    if (this.thumbnailPresentationTarget.classList.contains("hidden")) {
      this.thumbnailPresentationTarget.classList.remove("hidden");
      this.thumbnailEditorTarget.classList.add("hidden");  
    } else {
      this.thumbnailPresentationTarget.classList.add("hidden");
      this.thumbnailEditorTarget.classList.remove("hidden");  
    }
  }

  toggleThumbnail() {
    if (this.thumbnailTarget.classList.contains("hidden")) {
      this.thumbnailTarget.classList.remove("hidden");
      this.thumbnailFormTarget.classList.add("hidden");
    } else {
      this.thumbnailTarget.classList.add("hidden");
      this.thumbnailFormTarget.classList.remove("hidden");
    }
  }

  toggleThumbnailButton() {
    document.querySelector("form#conversation-thumbnail-form.in-email-composer").remove();
    if (this.thumbnailButtonTarget.classList.contains("hidden")) {
      this.thumbnailButtonTarget.classList.remove("hidden");
    } else {
      this.thumbnailButtonTarget.classList.add("hidden");
    }
  }
  

}