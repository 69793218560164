import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["embedCodeInput"]

  connect() {
  }

  // When user obtains the conversation embed code, they can toggle ?allow_replies off/on the src URL using this action
  toggleAllowReplies(event) {
    var input = this.embedCodeInputTarget;
    var input_value = input.value;
    var copy_element = this.element.querySelector("div[data-copy-string-value]");

    // If allow_replies is present, remove it.
    if (input_value.includes("allow_replies=true")) {
      if (input_value.includes("?allow_replies=true")) { // allow_replies is the only param
        input.value = input_value.replace("?allow_replies=true", ""); // replace in the hidden input
        copy_element.dataset.copyStringValue = input_value.replace("?allow_replies=true", ""); // replace the copy element data attr
      } else if (input_value.includes("&allow_replies=true")) { // allow_replies is present along with token param
        input.value = input_value.replace("&allow_replies=true", ""); // replace in the hidden input
        copy_element.dataset.copyStringValue = input_value.replace("&allow_replies=true", ""); // replace the copy element data attr
      }
    } else { // allow_replies is not present, so add it.
      if (input_value.includes("?token")) { // token is present
        var token_index = input_value.indexOf("token=") + 6;
        var token_value = input_value.substring(token_index, input_value.indexOf("'", token_index));
        input.value = input_value.replace(token_value, token_value+"&allow_replies=true"); // replace in the hidden input
        copy_element.dataset.copyStringValue = input_value.replace(token_value, token_value+"&allow_replies=true"); // replace the copy element data attr
      } else { // token isn't present
        var src_index = input_value.indexOf("src='") + 5;
        var src_value = input_value.substring(src_index, input_value.indexOf("'", src_index));
        input.value = input_value.replace(src_value, src_value+"?allow_replies=true"); // replace in the hidden input
        copy_element.dataset.copyStringValue = input_value.replace(src_value, src_value+"?allow_replies=true"); // replace the copy element data attr
      }
    }

  }

}